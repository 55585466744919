/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { Buffer } from 'buffer';
import 'react-toastify/dist/ReactToastify.css';
import { Link, navigate } from '@reach/router';
import { HotTable, HotColumn } from '@handsontable/react';
import {
  registerAllCellTypes,
  registerAllRenderers,
  registerAllEditors,
  registerAllValidators,
  registerAllPlugins,
  registerAllModules,
} from 'handsontable/registry';

import { Helmet } from 'react-helmet';
import { Button, Dimmer, Label, } from 'semantic-ui-react';
import Modal from 'react-modal';
import { mdiDeleteForever, mdiPlus, mdiFilter } from '@mdi/js';
import Icon from '@mdi/react';
import numbro from 'numbro';
import moment from 'moment';
import languages from 'numbro/dist/languages.min.js';
import {
  addorupdateValues,
  deleteRows,
  UpdateUsersTable,
  PasteNewUsers,
  insertNewRow,
} from '../../api/crud';
import UpdateRows from '../../api/MultiRowUpdates';
import DisplayMultiple from './components/Renderers/DisplayMultiple';
import MultipleEditor from './components/Renderers/MultipleEditor';
import TableLink from './components/Renderers/TableLink';
import MessageEditor from './components/Renderers/MessageEditor';
import MessageRender from './components/Renderers/MessageRender';
import AttachmentsRenderer from './components/Renderers/AttachmentsRenderer';
import SystemList from './components/Renderers/SystemList';
import ButtonRender from './components/Renderers/ButtonRender';
import Collaborator from './components/Renderers/Collaborator';
import { AccountContext } from '../../providers/AccountProvider';
import DisplayLookupValues from './components/Renderers/DisplayLookupValues';
import ColumnConfig from './components/ColumnConfig';
import {
  getUserTable,
  UpdateTableColumnWidthAPI,
  UpdateTableColumnOrderAPI,
  updateUserTabSettings,
  hideColumn,
  copyField,
  checkIfNestedField,
  canFieldBeDeleted,
  addFieldIndex
} from '../../api/tables';
import { saveBookMark, updateBookmarkContent } from '../../api/bookmarks';
import TopNavBar from './components/TopNavBar';
import BottomBar from './components/BottomBar/BottomBar';
import LookupLookupValues from './components/Renderers/LookupLookupValues';
import TextRender from './components/Renderers/TextRenderer';
import RFTEditor from './components/Renderers/RFTEditor';
import TextEditor from './components/Renderers/TextEditor';
import MaskEditor from './components/Renderers/MaskEditor';
import PhoneEditor from './components/Renderers/PhoneEditor';
import UserRender from './components/Renderers/UserRender';
import BlockUsersRenderer from './components/Renderers/BlockUsersRenderer';
import TableControls from './components/TabLists';
import PasteData from '../../api/Paste';
import FilterData from './components/FilterData';
import DateTimeRender from './components/Renderers/DateTimeRenderer';
import {
  getBlockAPI,
  updateBlockUserSettings,
  getUserBlocksSimplified,
  copyBlock,
} from '../../api/blocks';
import FilterTags from './components/FilterData/FilterTags';
import ActionBar from './components/ActionBar';
import ExportData from '../../api/export';
import ExportRequest from '../../api/exportRequest';
import ExportModal from './components/ExportModal';
import cloudrun from '../../clients/httpcloudrun';
import Spinner from '../../components/Spinner/Spinner';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import GBConfirm from '../../components/GBConfirm/GBConfirm';
import TriggerAction from './components/Renderers/Actions';
import { DeleteUser, getUsersAPI } from '../../api/users';
import { getZoneAPI } from '../../api/zone';
import { getUserForms } from '../../api/forms';
import CreateRecordUrl from '../../utils/CreateRecordUrl';
import DetailView from './components/Detailview/Detailview';
import MessagesView from './components/GridViews/ChatView/MessagesView';
import BlockEditor from '../BlocksList/components/BlockEditor';
import UserProfile from '../User/UserProfile';
import Bookmarks from './components/Bookmarks/Bookmarks';
import NewRecord from './components/DataList/NewRecord';
import FormContainer from './components/Forms/FormContainer';
import GBAddNewRecordButton from '../../components/GBAddNewRecordButton/GBAddNewRecordButton';
import FormWrapper from './components/Forms/FormWrapper';
import TimeSortButton from './components/common/timeSortButton';
import ColumnManager from './components/common/ColumnManager';
import ConfettiMessage  from './components/common/ConfettiMessage';
import AddUsersManager from './components/common/AddUsersManager';
import ShowNotifications from './components/notifications/showNotifications';
import DuplicateBlock from '../BlocksList/components/DuplicateBlock';
import QuickViewVirtual from './components/GridViews/QuickView/QuickViewVirtual';
import GroupView from './components/GridViews/GroupView/GroupView';
import ShareManager from './components/Shares/ShareManager';
import BookmarkBar from './components/Bookmarks/BookmarkBar';
import errorHandler from '../../utils/errorHandlerUtility';
import RecordIdentifierSearch from './components/common/recordIdentifierSearch';
import { plans } from '../Zone/plans';
import GridSearch from '../../utils/gridSearch';
import GBInput from '../../components/GBInput/GBInput';
import Global from '../../clients/global';


registerAllPlugins();
registerAllCellTypes();
Object.values(languages).forEach((l) => numbro.registerLanguage(l));

Modal.setAppElement(document.getElementById('root'));

toast.configure();

class DataGrid extends React.Component {
  static contextType = AccountContext;

  height = 55;
  x = 1;
  CheckLength = /^[A-Z]{1,10}$/;

  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.deleteRowsInput = React.createRef();
    this.state = {
      topBarHeight:30,
      settings: this.settings,
      blockid: 0,
      blocksettings: '',
      issystemblock: false,
      tmpHeader: '',
      tableinfo: {},
      userTabSettings: {}, // added 9-29-2020 to store all user specific tab settings (outside of col order/width)
      selectedColumnIndex: 0,
      tableIndex: 0,
      justtablename: '',
      schema: '',
      columns: [],
      modalIsOpen: false,
      columnconfigfield: '',
      x: 350,
      y: 350,
      modalWidth: 350,
      modalHeight: 400,
      isLoading: false,
      block: null,
      isDone: false,
      offset: 0,
      showError: false,
      showFilters: false,
      showNewRecord: false,
      showFormView: false,
      showBlockEditor: false,
      showEditField: false,
      recordCount: 0,
      filterCount: 0,
      viewingCount: 0,
      icon: '',
      tabname: 'Home',
      tablename: '',
      recordname: '',
      tableicon: '',
      filters: [],
      rowHeight: 33,
      isExpanded: true,
      showExportButton: false,
      exportedData: [],
      showHomePage: true,
      color: '',
      sortField: 'CustomID',
      sortOrder: 'asc',
      sortHeader: '',
      selectedCell: [],
      limit: 2000,
      gridWidth: window.innerWidth - 280,
      gridHeight: window.innerHeight,
      statement: '',
      information: '',
      lastBlockEdited: new Date(),
      uid: 0,
      zoneid: 0,
      displacementWidth: 265,
      showColumns: false,
      showBookmarks: false,
      refreshColumns: false,
      showDetailView: false,
      showUserProfile: false,
      showNotifications: false,
      showDuplicateBlock: false,
      showColumnManager: false,
      showAddUsers: false,
      hiddenColumnCount: 0,
      showManageWebForms: false,
      showShareManager: false,
      sharedRecordIds: null,
      selectedItem: 0,
      selectedRowIndex: 0,
      selected_id:0, //12-9-22 added keeping track of id (instead of just index) to support quick/group view logic around next/prev, etc
      hasMessages: false,
      role: 'user',
      viewMode: 'chat', // 'grid','chat' are 2 modes. determines if we show grid table, or the chat view for messaging.
      lastDeletedColumn: 0, // 1-7-2021 added so that if you are scrolled to right and delete column, we can set scroll of grid to this position(so user doesn't have to scroll to find their place again.)
      currentMaxID: 0, // 1-19-2021 added to table query to get max ID for entire table. This is used for when adding new rows, we can query/append rows greater than this ID.
      userBlocks: [], // 3-21-2021 we get all blocks user has access to. used for showing "new record" button.
      forms: [],
      formToken: '',
      screenLoading: true,
      useSecurity: false, // 11-19-2021 This indicates if the current user can view rows they can't edit. If set to true, on each cell selection,we check if they have access to edit the data. We can't use readonly has would incur to much processing to use cells function, which runs on every click/scroll
      isTableReadOnly: false,
      isPublicZoneBlock: false, // 5-2-222 this indicates if this is user browsing a public block who is not the zone owern.
      mathNumbers: null, //5-23-2022 adding to support calculated min,max,count,sum,avg for highlighted number cells.
      selectedCoords: null, // used for Share feature when selecting rows.
      showBookmarkBar: false,
      bookmarks: [],
      groupids: [], // 12-9-22 When in group view, when they click to view record, it passes back array of id's from that group which is updated in state. Used for next/prev logic.
      nextPrevBool:{}, // 12-15-22 Added to support being able to hide/show the next /prev icon in detail page including when using groups. This is object that holds {next:true,prev:false} format.
      duplicateField: null, //8-11-23 added to support when user choses to duplicate field. We record field selected here, so it can be used in ColumnConfig.
      currentBookMarkId:null, //8-28-23 added to support highlighting the selected bookmark. when fetchGraphdata is run for any reason, it will reset this back to null
      isReload:false, //2-2-24 We want to pass whether this is a reload of table (when user clicks relaod) to support new idea of removing cached calculations /refreshing on reload.
      showConfetti: false,
      ConfettiMsg:'',
      isSimpleView: false //12-4-24
    };
    this.id = 'hot';
    // this.id = this.state.tableIndex;
    this.hotTableComponent = React.createRef();
    this.inputRef = React.createRef();
  }

  async componentDidMount() {
    const { userInfo, userTokenResult, signOut } = this.context;

  
    // 12-7-22 We need to check if any zones have multifactor enabled 
    //and if user has not configured multi-factor and usres password provider, redirect
    //to blocklist page, which will have code that shows AddMultiFactor component.
    if (
      userInfo.zones.filter((el) => el.attributes &&  el.attributes.multiFactor && el.attributes.multiFactor).length > 0 &&
      userTokenResult.signInProvider === 'password' &&
      userInfo.attributes.multiFactor === undefined
    ) {
      navigate('/');
    }

    if (userTokenResult === null || userInfo.email.toLowerCase().includes('anonymous')) {
      await signOut();
    }

    if (errorHandler.report.length > 0) {
      errorHandler.setUser(userInfo.email);
    }

    const { blockID } = this.props;
    const schema = `schema_${Global.zoneid}`;

    // 3-2-2021 Added this userBlocksd lookup on block to store a users active blocks.
    // This is used , for now, to determine if they see the 'new record" button
    // on relational datalist (if they have access to linked block.)

    // 12-13-2021 adding setting the currentplan on userInfo, so it's easily accessible
    // to security logic. If plan==='Free', ignore security logic.
    const plan = userInfo.zones.filter((el) => el.id === parseInt(Global.zoneid))[0].plan;
    const userBlocks = await getUserBlocksSimplified();
    let blocks = [];
    userBlocks.map((el) => {
      if (el.role === 'Block builder') blocks.push({ blockid: el.blockid, name: el.name, role: 3 });
      if (el.role === 'Block user (General)')
        blocks.push({ blockid: el.blockid, name: el.name, role: 0 });
      if (el.role === 'Block user (L1 Security)')
        blocks.push({ blockid: el.blockid, name: el.name, role: 1 });
      if (el.role === 'Block user (L2 Security)')
        blocks.push({ blockid: el.blockid, name: el.name, role: 2 });
    });

    userInfo.blocks = blocks;
    userInfo.plan = plan;

    /*  5-22-2022
      Adding logic to deal with use-case of public blocks. If the user is browsing a public block,
      we will set his block role to general user to restrict what they can do, as well as set the 
      isPublicZoneBlock = true, which will also be used to turn off functionality not allowed in a public block,
      except for those users who are zone owners or zone builders of the public block.
    */
    const { REACT_APP_PUBLIC_ZONE_ID } = process.env;
    const currentZoneRole = userInfo.zones.filter((z) => z.id === parseInt(Global.zoneid))[0].role;
    let userRole = 0;
    let isPublicBlock = false;
    const usrIdx = userInfo.blocks.findIndex(el=>el.blockid===parseInt(blockID))

    if (
      parseInt(Global.zoneid) === parseInt(REACT_APP_PUBLIC_ZONE_ID) &&
      currentZoneRole.toLowerCase() === 'zone user'
    ) {
      userRole = 0;
      isPublicBlock = true;
    } else if (usrIdx !==-1) {
      userRole = userInfo.blocks[usrIdx].role;
    } else {
      userRole =0;
    }

    await this.GetBlocktabInfo(blockID, userInfo.id);
    this.setState({
      settings: this.settings,
      blockid: blockID,
      role: userRole,
      schema,
      uid: userInfo.id,
      zoneid: parseInt(Global.zoneid),
      screenLoading: false,
      isPublicZoneBlock: isPublicBlock,
    });

    // this.updateDimensions();
    window.addEventListener('resize', this.getHeight.bind(this));
  }

  searchActionOptions = [
    { key: 'lookup', text: 'Lookup a record', value: 'lookup', icon: 'search',placeholder:'Lookup a record',clear:true,toolTip:'Filter records by Record indentifier' },
    { key: 'scan', text: 'Scan page for term', value: 'scan', icon: 'find',placeholder:'Scan grid for term',clear:false,toolTip:'Scan/filter records by any matching term' },
  ];

  clearSearch =() =>{
    const {settings,  startingData} = this.state;
    settings.data =  startingData;
    this.setState({settings});
  }

  searchActions =  async (action, value) =>{
    let {settings,startingData,tableinfo} = this.state;
    if(action==='lookup') {
      const filters=[{field:"CustomID",uitype:32,operator:'contains',displayfield:'CustomID',header:'Record identifier', value:value}]
      await this.RunFilterQuery(filters,false)
    } else if(action==='scan') {
        if(startingData===undefined || startingData===null) {
          console.log('no starting data')
          startingData = structuredClone(settings.data)
        }
   
        const results = GridSearch(startingData,value,tableinfo)
        settings.data = results;
        this.setState({settings,filterCount:results.length,viewingCount:results.length,startingData})

    }

  }

  // 12-8-22 added to update group data to be used by next/prev function.
  // updateGroupData = (data) =>{
  //   this.setState({groupData:data})
  //   const result = deepSearch(data,'id', (k, v) => v === 4)
  //   console.log(result);
  // }

  

  formOptions = [
    { key: 'edit', icon: 'pencil alternate', text: 'Manage web forms', value: 'edit' },
    {
      key: '1',
      icon: 'external alternate',
      text: 'New contact form 1 that is reall long',
      value: '1',
    },
    { key: '22', icon: 'external alternate', text: 'New contact form 2', value: '22' },
  ];

  getFavIcon = async () => {
    const path = `M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z`;
    const url = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' style='background-color:red' viewBox='0 0 24 24'%3E%3Cpath d='${path}'/%3E%3C/svg%3E"`;
    return url;
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.getHeight.bind(this));
    // window.removeEventListener('wheel', this.scrollWheel.bind(this))
  }

  checkRowSecurity = (row) => {
    const { role, tableinfo, settings } = this.state;
    const { userInfo } = this.context;
    const userid = userInfo.id;
    // we either need to check if user created the row OR, if their role can edit shared records
    // whether they are in one of the collaborator fields in the row.
    const dataRow = settings.data[row];

    //if id is null, they are clcking on a new row, so can edit.
    if (dataRow === undefined || dataRow.id === null || dataRow.id === undefined) {
      return true;
    }

    let canEdit = false;
    if (tableinfo.security.editOthersRecord <= role) {
      const collabFields = tableinfo.columns.filter(
        (el) => el.uitype === 8 || (el.source && el.source.lookupuitype === 8),
      );
      collabFields.forEach((itm) => {
        if (dataRow[itm.data] !== null) {
          const idx = dataRow[itm.data].findIndex((el) => el.userid === userid);
          if (idx !== -1) canEdit = true;
        }
      });
    } else if(tableinfo.security.editMyRecord <= role) {
      if (
        (dataRow.addedby.userid !== undefined && dataRow.addedby.userid === userid) ||
        dataRow.addedby === userid
      )
        canEdit = true;
    }

    return canEdit;
  };

  // updateDimensions() {
  //   console.log(window.innerHeight);
  //      if(window.innerWidth < 500) {
  //     this.setState({ width: 450, height: 102 });
  //   } else {
  //     let update_width  = window.innerWidth-100;
  //     let update_height = Math.round(update_width/4.4);
  //     this.setState({ width: update_width, height: update_height });
  //   }
  // }

  settings = {
    data: [],
    licenseKey: 'non-commercial-and-evaluation',
    // contextMenu: this is set on line 2394, dynamically based on whetehr user has remove_row access.
    dropdownMenu: {
      callback: () => {},
      items: [],
    },
    autoRowSize: false,
    rowHeaders: true,
    colHeaders: true,
    wordWrap: false,
    fillHandle: true,
    // viewportRowRenderingOffset: 0,
    preventOverflow: 'horizontal',
    rowHeaderWidth: 25,
    // hiddenColumns: {
    //   columns: [0],
    //   indicators: false,
    // },
    // hiddenRows: {
    //   rows: [19,20,21,24,25,26,27,28,29],
    //   indicators: true,
    // },
    autoColumnSize: false,
    // minSpareRows: 1,
    manualColumnResize: true,
    manualColumnMove: true,
    fixedRowsTop: 0,
    // fixedRowsBottom: 1,
    copyPaste: { columnsLimit: 100, rowsLimit: 2000 },
    // rowHeights: 33,
    // height: 'auto',
    // persistentState: true,
    // manualRowResize: true,
    fixedColumnsLeft: 2,
    selectionMode: 'multiple',
    fillHandle: { autoInsertRow: false },
    // outsideClickDeselects: false,
    // manualColumnFreeze: true,
    afterChange: async (changes) => {
      // if (changes !== null) {
      //   await this.processChanges(changes);
      // }
    },
    beforeChange: (changes, source) => {
      const updates = [];

      //11-9-23 this handles case where user double clicks into an emptry cell, and then clicks
      //away. The grid tries to set it to emptry string, and we use code below to set it to null.
      changes.forEach(change => {
        const [row, prop, oldVal, newVal] = change;
        if (oldVal === null && newVal === '') {
          change[3] = null; // Set the new value to null instead of an empty string
        }
      })
  

      /* 8-31-22 
     In use-case that user is dragging cell to copy data, we can only allow complex fields to be
     copied vertically, into other cells of the same type. We need to block copying across fields. Example
     is lookup field as format of {id,data} . If they drag across to relational field, this needs to be blocked
     we do this by getting field type of selected cell, determining if value is array and if any of
     change cells have different field than source, if so, return false.
    */
     let sourceColInfo = null;
     let isSameColumn =false;
     let copySameFields=true;

      if (source === 'Autofill.fill') {
        const cell = this.hotTableComponent.current.hotInstance.getSelected();

        let sourceFields=[];

        // 3/1/23 we look at the selected fields and will compare with fields copied 
        // to. If there are fields in copy changes not in source, this means they are 
        // dragging horizontally and we will block.

        const startCol = cell[0][1];
        const endCol = cell[0][3];

        //12-6-24 adding logic to get array of hidden fields and only push sourcefields
        //in that are not hidden.
        const hiddenColumnsPlugin = this.hotTableComponent.current.hotInstance.getPlugin('hiddenColumns');
        const hiddenColumns= hiddenColumnsPlugin.getHiddenColumns()

        for(let x=startCol;x<=endCol;x++){
          if(!hiddenColumns.includes(x)) {
            sourceFields.push(this.hotTableComponent.current.hotInstance.colToProp(x));
          }
        }

        //here we filter the changes array to only "visible" change fields. 
        changes = changes.filter(itm=>sourceFields.includes(itm[1]))

        const field = this.hotTableComponent.current.hotInstance.colToProp(cell[0][1]);
        const colInfo = this.state.columns.filter((el) => el.data === field)[0];
        sourceColInfo = colInfo;
    
        // 3/1/23 Check to see if the selected cells
        copySameFields = sourceFields.findIndex(col=>!changes.map(e=>e[1]).includes(col)) ==-1

        // 1-19-23 Changing logic to ensure that all the changes from a copy/drag are either copying 
        //from same column or all other destination fields are of type text (2,3)

        isSameColumn = this.state.columns.findIndex(col=>changes.map(e=>e[1]).includes(col.data) && col.data !==colInfo.data) === -1
        const isAllTextFields = this.state.columns.findIndex(col=>changes.map(e=>e[1]).includes(col.data) && ![2,3].includes(col.uitype)) 

        if(!isSameColumn && isAllTextFields !==-1 && !copySameFields) {
          console.log('autofill blocked');
          this.ShowError('These values cannot be copied to the destination field types.')
          return false;
        }
      }

      // 7-8-2020 by default, we won't do grid reresh from DB..will let grid copy values as normal
      // if they have a relational field in update, we check to see if there are lookup fields. if yes, upgrade=true.


      let updateGrid = false;
      let validFields = true;
      let includesReverseSingleSelect = false;

      // 1-19-23 all auto-fill use the same UpdateMultipleRows logic, even if just
      // drag copying one cell to another.
      // const tempChanges = structuredClone(changes);
 
      if (source === 'Autofill.fill') {
        changes.forEach((itm) => {
      
          const colInfo = this.state.tableinfo.columns.filter((el) => el.data === itm[1])[0];
 
          //6-30-2022 added this check for when a multi-relational cell gets updated, we use the setData to update
          //lookup fields, which triggers a "change". We don't want to run any updates when lookup values get updated.
          if (parseInt(colInfo.uitype) === 18) {
            validFields = false;
          }

          if (colInfo.uitype === 1) {
            const checkLookupIndex = this.state.tableinfo.columns.findIndex(
              (el2) =>
                el2.uitype === 18 && el2.source.lookuptableid === colInfo.source.lookuptableid,
            );
            updateGrid = checkLookupIndex !== -1;

            // 12-16-2021 new logic that we don't allow them to drag/copy rows of a relational field
            // if the reverse side of relational field is single select (could improperly add values)
            if (colInfo.source.lookup === 'single') {
              validFields = false;
              includesReverseSingleSelect = true;
            }
          }

          // 12-7-2021 Remove fields which are hidden from being updated.
          if (colInfo.hide === undefined || !colInfo.hide) {
            
            // 1-19-23 we look at field type and for complex data tpes,we convert to text equivalent.
            if (this.state.tableinfo.dbname !== 'Users') {
              let newValue=itm[3];
    
              if(([1,6,7].includes(sourceColInfo.uitype) || sourceColInfo.uitype===18 && ![24,25,8,4].includes(sourceColInfo.source.lookupuitype)) && newValue !==null &&  !copySameFields){
                newValue = itm[3].map(el=>el.data).join('%|%')
                itm[3] = itm[3].map(el=>el.data).join('%|%')
              } else if(([24,25,8].includes(sourceColInfo.uitype) || sourceColInfo.uitype===18 && [24,25,8].includes(sourceColInfo.source.lookupuitype)) && newValue !==null && newValue!=='' && !copySameFields) {
                newValue = itm[3].map(usr=>usr.email).join('%|%')
                itm[3] = itm[3].map(usr=>usr.email).join('%|%')
              } else if(([4].includes(sourceColInfo.uitype) || sourceColInfo.uitype===18 && [4].includes(sourceColInfo.source.lookupuitype)) && newValue !==null && newValue!=='' && !copySameFields) {
                newValue = itm[3].map(usr=>usr.gcsurl).join('%|%')
                itm[3] = itm[3].map(usr=>usr.gcsurl).join('%|%')
              } 

              updates.push({
                id: this.state.settings.data[itm[0]].id,
                value: newValue,
                field: itm[1],
                row: itm[0],
              });
            } else {
              updates.push({
                userid: this.state.settings.data[itm[0]].userid,
                value: itm[3],
                field: itm[1],
                row: itm[0],
              });
            }
          } else {
            updateGrid = true; // if we have a hidden column in data, refresh grid so it has latest data.
          }
        });

        // 12-16-2021 new logic that we don't allow them to drag/copy rows of a relational field
        // if the reverse side of relational field is single select (could improperly add values)
        if (!validFields) {
          if (includesReverseSingleSelect) {
            this.ShowError('You are trying to copy a value which can only exist once in this tab.');
          }
          return false;
        }

        // check if columns updated contains a relational field AND if there are lookups associated with
        // this field. IF not, we don't need to refresh the grid, otherwise we do.
        this.UpdateMultipleRows(updates, updateGrid);
        if (updateGrid) return false;
      } else {
        // 6-30-2022 Implementing new logic for how to process relational changes. To support add/remove of individual items
        // we manually update the value and use setDataAtCell and pass the soruce="relational". This allows us to not get in infiite loop.

        if (source !== 'relational') {
          this.processChanges(changes);
        }
      }
    },
    beforeKeyDown: (event) => {

      //6-20-23 Added this simple check, as it seems that when detailview is showing,
      //the return false removes all the issues related to backspace, space bar, arrow keys, etc. 
      //the return false turns off the grid function keys all together. 
      if(this.state.showDetailView){
        return false
      }

      const cell = this.hotTableComponent.current.hotInstance.getSelected();

  
      // 8-17-2020. If field type is messaging, we need to disable the "Enter" and "tab" key
      // so that user can type message, hit enter without causing cell to save. Need a fix
      // for how to make tab key work so that it submits message to send. this will suppress
      // the tab key all together so it's not recoginzied.

      //3/9/23 changed logic to see if the selection included col=-1, which means they are
      //highlighting the row, and we don't want to process login.
      if (cell && cell[0][1] !==-1 && cell[0][0] !==-1) {
      
        const hasRowID =
          this.state.settings.data[parseInt(cell[0])].id !== null &&
          this.state.settings.data[parseInt(cell[0])].id !== undefined;

        if (!hasRowID) {
          event.stopImmediatePropagation();
        }

        // 1-16-23 adding keyboard shortcut of spacebar to view details page.
        if(event.code==='Space' && !this.state.showDetailView) {
          const editor = this.hotTableComponent.current.hotInstance.getActiveEditor()
          if(editor===undefined || (editor && editor.state==='STATE_VIRGIN' )){
            event.stopImmediatePropagation();
          
            this.showDetailView(true,null,this.state.settings.data[parseInt(cell[0])].id)
            this.hotTableComponent.current.hotInstance.selectCell(cell[0][0], 0);
            return false;
          }
        }
      

        const field = this.hotTableComponent.current.hotInstance.colToProp(cell[0][1]);
        const colInfo = this.state.columns.filter((el) => el.data === field)[0];
        if (colInfo.uitype === 12) {
          if (event.code === 'Enter' || event.keyCode === 13 || event.keyCode === 8) {
            // event.preventDefault();
            event.stopImmediatePropagation();
          }
        }

        if (
          cell[0][1] === 0 &&
          this.state.showDetailView &&
          (event.code === 'Enter' ||
            event.keyCode === 8 ||
            event.keyCode === 37 ||
            event.code === 'ArrowUp' ||
            event.code === 'ArrowDown')
        ) {
          event.preventDefault();

          // event.stopImmediatePropagation();
          //1-27-2022 working on collaborator comments. need enter/up/down arrow
          // to use. This change seems to work fine in just returning false. Need to keep testing to ensure
          // I didn't break something else.
        } else if (
          cell[0][1] === 0 &&
          (event.code === 'Enter' ||
            event.keyCode === 8 ||
            event.keyCode === 37 ||
            event.code === 'ArrowUp' ||
            event.code === 'ArrowDown')
        ) {
          event.stopImmediatePropagation();
        }
        //10-3-22 added to support tabbing on detail view and letting them edit field by field.
        if (this.state.showDetailView && event.keyCode === 9) {
          event.stopImmediatePropagation();
        }

        // stop scrolling right at end of table
        if (cell[0][1] === this.state.columns.length - 1 && event.keyCode === 39) {
          // event.preventDefault();
          event.stopImmediatePropagation();
        }

        //if up arrow and we are at 1st row, stop.
        if (cell[0][0] === 0 && event.keyCode === 38) {
          // event.preventDefault();
          event.stopImmediatePropagation();
        }

        if (
          this.state.settings.data !== null &&
          cell[0][0] === this.state.settings.data.length - 1 &&
          event.keyCode === 40
        ) {
          event.preventDefault();
          // event.stopImmediatePropagation();
        }
      }
    },
   
    afterOnCellMouseDown: (events, coords, TD) => {
      // this.hotTableComponent.current.hotInstance.updateSettings({ height: window.innerHeight - 110 })
      // console.log(coords.col)afterseelc
      if (coords.col === 0 && coords.row === -1) {
        return false;
      }

      // Specifically not calling with setState() as we don't want to cause a re-render
      // on every cell selected. We use this activeRow to determine where they are
      // in the reoordset so adding a new record from form inserts into row below ActiveRow.

      // I also set this on AfterSelectionEnd. This is becaues when the click next/prev
      // button on details page, we need that to reset the activeRow
      this.state.activeRow = coords.row;

      if (coords.col !== -1) {
        const columnInfo = this.state.columns[coords.col];
        const listfield = columnInfo.data;

        if (coords.row === -1) {
          const width = this.hotTableComponent.current.hotInstance.getColWidth(coords.col);
          let modalXposition;
          if (events.clientX + this.state.modalWidth > window.innerWidth) {
            modalXposition = window.innerWidth - this.state.modalWidth - 300;
          } else {
            modalXposition = events.clientX - width + 13;
          }
          this.state.x = modalXposition;
          this.state.y = events.clientY + 15;
        }

        // They have clicked to add new column
        if (listfield === 'id2' && coords.row === -1) {
          this.addfield(coords.col);
        }

        // 5-25-2020 : For the System Users table, we have "button" columns where a user clicks a button to take
        // an action such as toggling a user from active to inactive, sending out an invite email/Link, ec.
        // This is a generic way to put metadata "action" node in the column, and then we can take action
        // on a single click in rendered value. Handsontable does not seem to allow direct javascript functions
        // easily within a custom react render component, so this is proposed solution. It's setup
        // in such a sway, that any column can be made into an "Action" column and can trigger any subsequent logic.

        // 6-1-2020. Added new attribute called "params" to node in tableinfo. The idea is that for system defined
        // tables we will need to pass data specific to table/block to the action method. For example, When
        // rendering blockusers, we need to pass the blockid to the action UpdateBlockUserStatus to remove user from
        // block. this is stored in object ex. {blockid:9}. This param will be merged with any other passed params
        // to send to final GCF or method to execute.

       
        if (columnInfo.action !== undefined) {
          const { zoneInfo } = this.state;
          const data = this.hotTableComponent.current.hotInstance.getDataAtCell(
            coords.row,
            coords.col,
          );

          if (data !== null) {
            let id = '';

            // 6-3-2020 I removed params from node in customtable because Blockname is created with "new block".
            // for now, will set this via the name tabname.
            const params = `{"blockname": "${this.state.tabname}"}`;
            if (this.state.settings.data[coords.row]) {
              //9-24-23 I recentl updated getzoneusers to include dbname. (for global session logic)
              //this is a much more straight forward way to get current userid.
              // id = Object.entries(this.state.settings.data[coords.row])[0][1];
             id = this.state.settings.data[coords.row].userid
            }
            if (
              columnInfo.action === 'UpdateUserStatus' &&
              data === 'Inactive' &&
              zoneInfo.plan !== 'Free' &&
              zoneInfo.userCount >= zoneInfo.userlicenses
            ) {
              this.ShowError(
                <div>
                  The license limit is exceeded for this Zone. To add more users, update your plan
                  subscription to include more licenses. Learn more:{' '}
                  <a
                    style={{ color: 'white', textDecoration: 'underline' }}
                    href="https://www.graceblocks.com/support-article/graceblocks-plans"
                    target="_blank"
                  >
                    GraceBlocks plans
                  </a>
                  .
                </div>,
              );
              return false;
            }

            const executeTrigger = async () => {
              const link = await TriggerAction(
                columnInfo.action,
                data,
                id,
                this.state.uid,
                this.RefreshTable,
                params,
                this.state.blockid
              );
              // 3-24-2021 When users click to send an invite/resend an invite, we now copy
              // the generated link into their clipboard and show toast.
              if (link !== '') {
                var input = document.createElement('input');
                input.setAttribute('value', link);
                document.body.appendChild(input);
                input.select();
                var result = document.execCommand('copy');
                document.body.removeChild(input);
                this.ShowError(
                  <div style={{ marginRight: '10px' }}>
                    Link successfully copied to your clipboard. You can share this URL with the
                    invited user. This may be helpful if spam blockers are causing trouble with
                    invitation email delivery.
                  </div>,
                );
              }
            };
            executeTrigger();
          }
        }

        // Show detail view when clicking the view icon in 1st column
        let hasRowID =
          this.state.settings.data[coords.row] !== undefined &&
          this.state.settings.data[coords.row].id !== null &&
          this.state.settings.data[coords.row].id !== undefined;

        if (coords.col === 0 && hasRowID) {
          this.showDetailView(true, coords.row);
        }
      }

      if (coords.col === -1) {
        const { userInfo } = this.context;
        if (userInfo.savedRows === undefined) {
          userInfo.savedRows = [{ tableid: this.state.tableIndex, row: coords.row }];
        } else {
          userInfo.savedRows.push({ tableid: this.state.tableIndex, row: coords.row });
        }
      }
    },

    afterSelectionEnd: (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
      //silently set activerow to what they justg clicked on.

      var selected = this.hotTableComponent.current.hotInstance.getSelected();
      this.state.selectedCoords = { selected };
      this.state.activeRow = row;

      if (column !== -1) {
        //we use this to reset mathnumbers to null to clear values on bottom bar.
        if (this.state.mathNumbers !== null && row === row2 && column === column2) {
          this.setState({ mathNumbers: null });
        }

        //6-14-2022 to support new "add row", we will ensure that they cannot edit
        //the emptry spare row if there is no ID. This is more sure-fire way to make sure
        //we don't allow editing of empty, non-created row.

        // let hasRowID = true; commented out 4/11/23 . with no data, clicking to add new field was giving error.
        // if (row === row2) {
        //   hasRowID =
        //     this.state.settings.data[row].id !== null &&
        //     this.state.settings.data[row].id !== undefined;
        // }

        /************* 11-19-2021 Adding security logic ****** */

        // this.setState({
        //   mathNumbers: {count:0,sum:23,avg:7.5}
        // })
        // By default all users can edit rows
        // 12-2-2021 We set canEdit here so that render controls don't throw error if row is set to readonly.
        // we need to disable the single click to activate the editor in this situation.
        let canEdit = !this.state.useSecurity;

        const field = this.hotTableComponent.current.hotInstance.colToProp(column);

        const colInfo = this.state.columns.filter((el) => el.data === field)[0];
        const uitype = colInfo.uitype;
        let lookupuitype = '';
        if (colInfo.source) {
          lookupuitype = colInfo.source.lookupuitype; // 12-29-2020 adding lookup of attachments..so users can click to view file carousel.
        }

        // I also set this on AfterMouseDown. This is so when they click a new id/view detail
        // from timing perspective, we need this set onmousedown even. Also need this set
        // here so when they click next/prev, it reset activeRow.

        // 9-15-22 commented out as ctrl-copy no longer worked with new grid by destroying editor
        //so now all cells are in read mode by default...much better!
        // if (
        //   row === row2 &&
        //   column === column2 &&
        //   colInfo.readOnly === undefined &&
        //   hasRowID &&
        //   canEdit &&
        //   (uitype === 1 ||
        //     uitype === 4 ||
        //     uitype === 6 ||
        //     uitype === 7 ||
        //     uitype === 8 ||
        //     uitype === 26 ||
        //     uitype === 12 ||
        //     uitype === 30 ||
        //     lookupuitype === 4)
        // ) {
        //   if (!this.state.block.isMaxRowsExceeded && !this.state.isPublicZoneBlock) {
        //     this.hotTableComponent.current.hotInstance.getActiveEditor().beginEditing();
        //   }
        // }
      }
    },
    // afterSelection: (r, c, r2, c2, preventScrolling, selectionLayerLevel) => {
    //   // const celldata = this.hotTableComponent.current.hotInstance.getCellEditor(r,c);
    //   // console.log(celldata)
    // },
    afterGetColHeader: (col, TH) => {
      TH.style.color = '#757575';
      if (col === 0 && !this.state.tableinfo.showIDField) {
        TH.innerHTML = '';
      }
    },
    afterOnCellMouseOver: (events, coords, TD) => {
      if (coords.row === -1) {
        TD.style.color = this.state.color;
      } else if (
        coords.col === 0 &&
        coords.row <
          (this.state.filterCount <= this.state.limit
            ? this.state.filterCount
            : this.state.filterCount - 1)
      ) {
        TD.title = 'View record details';
        TD.style.color = this.state.color;
      } else if (
        coords.col === 0 &&
        coords.row ===
          (this.state.filterCount <= this.state.limit
            ? this.state.filterCount
            : this.state.filterCount - 1)
      ) {
        TD.title = 'Add new record';
        TD.style.color = this.state.color;
      }
    },
    afterOnCellMouseOut: (events, coords, TD) => {
      if (coords.row === -1) {
        TD.style.color = '#757575';
      }
    },
    beforeCopy: (data, coords) => {
      const { settings, tableinfo, role } = this.state;
      const tabledata = settings.data;

      if (tableinfo.security !== undefined && tableinfo.security.downloadRecords > role) {
        return false;
      }

      const colname = this.hotTableComponent.current.hotInstance.colToProp(coords[0].startCol);
      let copyString = '';

      for (let i = coords[0].startRow; i <= coords[0].endRow; i += 1) {
        for (let j = coords[0].startCol; j <= coords[0].endCol; j += 1) {
          const colname = this.hotTableComponent.current.hotInstance.colToProp(j);

          const idx = tableinfo.columns.findIndex(
            (itm) => itm.data === colname && (itm.hide === undefined || itm.hide == false),
          );
          if (idx !== -1) {
            const colInfo = tableinfo.columns[idx];
            // console.log(colInfo)

            if (colname !== 'id2') {
              let value = '';
              if (
                colInfo.uitype === 1 ||
                colInfo.uitype === 6 ||
                colInfo.uitype === 7 ||
                colInfo.uitype === 18 ||
                colInfo.uitype === 4 ||
                colInfo.uitype === 8 ||
                colInfo.uitype ===16
              ) {
                if (tabledata[i][colname] !== null) {
                  if (tabledata[i][colname].length > 1) {
                    if (
                      colInfo.uitype === 4 ||
                      (colInfo.source && colInfo.uitype === 18 && colInfo.source.lookupuitype === 4)
                    ) {
                      tabledata[i][colname].map((el) => (value += el.gcsurl + '|'));
                    } else if (
                      colInfo.uitype === 8 ||
                      (colInfo.source && colInfo.uitype === 18 && colInfo.source.lookupuitype === 8)
                    ) {
                      tabledata[i][colname].map(
                        (el) => (value += `${el.userid}|${el.firstname} ${el.lastname}|`),
                      );
                    } 
                    else if (
                      colInfo.uitype === 14 ||
                      (colInfo.source && colInfo.uitype === 18 && colInfo.source.lookupuitype === 14)
                    ) {
                      tabledata[i][colname].map(
                        (el) => (value += `******|`),
                      );
                    } 
                    else {
                      tabledata[i][colname].map((el) => (value += el.data + '|'));
                    }
                  } else {
                    if (
                      colInfo.uitype === 4 ||
                      (colInfo.source && colInfo.uitype === 18 && colInfo.source.lookupuitype === 4)
                    ) {
                      value = tabledata[i][colname][0].gcsurl;
                    } else if (
                      colInfo.uitype === 8 ||
                      (colInfo.source && colInfo.uitype === 18 && colInfo.source.lookupuitype === 8)
                    ) 
                    {
                      value = `${tabledata[i][colname][0].userid}|${tabledata[i][colname][0].firstname} ${tabledata[i][colname][0].lastname}`;
                    } 
                    else if (
                      colInfo.uitype === 14 ||
                      (colInfo.source && colInfo.uitype === 18 && colInfo.source.lookupuitype === 14)
                    ) 
                    {
                      value = `********`;
                    } 
                    else {
                      value = tabledata[i][colname][0].data;
                    }
                  }
                }

                // copyString+=(value !==null && value.includes('*!*')) ? value.slice(0,-3) : value
                copyString += value;
              } else if (colInfo.uitype === 22 || colInfo.uitype === 19 || colInfo.uitype === 20) {
                let tformat = colInfo.includeTime ? 'DD-MMM-YYYY hh:mm:ss a' : 'DD-MMM-YYYY';
                copyString +=
                  tabledata[i][colname] !== null
                    ? moment(tabledata[i][colname]).format(tformat)
                    : '';
              } else if (colInfo.uitype === 24 || colInfo.uitype === 25) {
                copyString += `${tabledata[i][colname].firstname} ${tabledata[i][colname].lastname}`;
              } else if (colInfo.uitype === 12) {
                copyString +=
                  tabledata[i][colname][0].message !== null ? tabledata[i][colname][0].message : '';
              } else if (colInfo.uitype === 9) {
                copyString += tabledata[i][colname] !== null ? `'${tabledata[i][colname]}` : '';
              } else if(colInfo.uitype===3) {
                copyString += tabledata[i][colname] !== null ? `"${tabledata[i][colname]}"` : '';
              } 
              //10-2-23 commented out, as we will allow them to copy html out of cell.
              // else if(colInfo.uitype===13 && tabledata[i][colname] !== null) {
              //   const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(tabledata[i][colname])));
              //   const txt = editorState.getCurrentContent().getPlainText('\u0001') ;
              //   copyString += tabledata[i][colname] !== null ? `"${txt}"` : '';
              // } 
              else if(colInfo.uitype ===14  && tabledata[i][colname] !== null) {
                copyString +='******';
              }
              else {
                copyString += tabledata[i][colname] !== null ? tabledata[i][colname] : '';
              }
              if (j < coords[0].endCol) {
                copyString += '\t';
              }
            }
          }
        }

        if (i < coords[0].endRow) {
          copyString += '\n';
        }
      }

      this.copyToClipboard(copyString);
      return false;
    },
    afterScrollVertically: async () => {
      const plugin = this.hotTableComponent.current.hotInstance.getPlugin('autoRowSize');
      this.hotTableComponent.current.hotInstance.destroyEditor(true);

      //5-23-23 if table is users or BlockUsers, just return false, so it doesn't reload items again.
      if(this.state.tableinfo.tablename === 'BlockUsers' || this.state.tableinfo.tablename === 'Users' ){
        return false;
      }

      // 3-23-2022 code to keep track of what row you are viewing, so that when you navigate
      // back to tab, we can keep you in same spot/row.
      const firstcolumn = plugin.getFirstVisibleRow();

      const { userInfo } = this.context;
      userInfo[`tbl_${this.state.tableIndex}_currentRow`] = firstcolumn;

      const row = plugin.getLastVisibleRow();
      if (
        row > this.state.settings.data.length - 100 &&
        this.state.settings.data.length < this.state.filterCount &&
        !this.state.isLoading
      ) {
        
        // 1-2-23 , removed "lastDeletedColumn: 0" from setState , so it maintains horizontal scroll when loading next batch of records, need to see if this breaks something else per comment below.
        this.setState({ isLoading: true }); // 1-12-2021 added lastdeletecolumn =0 so it removes any scroltoviewport if they had deleted a column.
        
        await this.CallGraphQuery(this.state.settings.data.length);
        this.setState({ isLoading: false });
        // this.setState({isLoading:false,modalIsOpen:false})
      }
    },
    afterScrollHorizontally: async () => {
      // this.hotTableComponent.current.hotInstance.deselectCell();
      // 8-3-2020 FIX. We can optimize this...right now, if you have tablelink cell highlighted,
      // wehn you scroll, it continually calls it. Should be able to check if editor exist
      // and only destroy then

      // 9-14-22 Remember the last viewed column, so on reload/refresh, it will go back there.
      const plugin = this.hotTableComponent.current.hotInstance.getPlugin('autoColumnSize');
      if (plugin !== null) {
        const firstcolumn = plugin.getFirstVisibleColumn();
        this.state.lastDeletedColumn = firstcolumn;
      }

      this.hotTableComponent.current.hotInstance.destroyEditor(true);
    },
    beforePaste: (data, coords) => {
      const { tableinfo, role, useSecurity, recordCount } = this.state;
      const { userInfo } = this.context;

      //Check if table has "Service" node. We will block pasting in this use-case.
      if(this.serviceCheck('maxRows')) {
        return false;
      }

      // 7-25-2022 adding check to make sure paste of data does not exceed, at minimum, total block record count.
      // This is basic check to stop excessive data import over block limit, but it would still let
      // them paste data into table if table data is below block limit, but would exceed block limit.

      const currentPlanLimit = plans.filter((el) => el.type === userInfo.plan)[0].records;
      if (data.length + recordCount > currentPlanLimit) {
        this.ShowError(
          'Total data would exceed current plan record limits. Please reduce size or upgrade your plan.',
        );
        return false;
      }

      // 12-1-2021 if user doesn't have editanyrecord priv, do not allow them to paste rows.
      if (tableinfo.security !== undefined && useSecurity) {
        return false;
      }

      // 5-27-24 if role does not have addRecords, don't allow paste.
      if(tableinfo.security !==undefined && tableinfo.security.addRecord > role) {
        return false;
      }

      if (data.length > 2000) {
        this.ShowError(
          'Please reduce the content you are trying to paste. The system only supports pasting up to 2,000 records in a single action.',
        );
        return false;
      }

      if (this.state.tableinfo.dbname === 'Users') {
        // if Users table, we can't let them copy over existing email addresses, only NEW email addresses
        // can be copy/pasted in.
        if (this.state.settings.data[coords[0].startRow].userid !== null) {
          this.ShowError(
            'You cannot use copy/paste to update existing email addresses. You can only paste new email addresses.',
          );
          return false;
        } else {
          this.PasteNewUsers(data);
        }
        return false;
      }
      // const {copiedData}=this.state;
      var fields = ['id'];

      // 8-12-2020 Instead of using coords(), I will get the selectedCell. This is because
      // when a user trys copy/paste when selection is a relation field for example, coords shows empty.
      // The getselected() works in all cases.
      const selectedCell = this.hotTableComponent.current.hotInstance.getSelected();

      const startColumn = selectedCell[0][1];
      const startRow = selectedCell[0][0];
      const endRow = selectedCell[0][2];
      let allValidFields = true;
      const invalidtypes = [12, 15, 16, 17, 18, 19, 20, 21, 24, 25, 26, 27, 30];

      // 5-19-2021 Hidden column updated logic. Since we have all columns on client(including hidden)
      // we first need to get the field where paste started. This is done here.
      // we then find the index of this field in the filtered columns where we remove hidden columns.
      // The looping over fields starts here.
      const startField = this.hotTableComponent.current.hotInstance.colToProp(startColumn);
      const startIndex = this.state.tableinfo.columns
        .filter((el) => el.hide === undefined || el.hide === false)
        .findIndex((el) => el.data === startField);

      //Filter columns to only visible. so we get correct order of actaul column order
      this.state.tableinfo.columns
        .filter((el) => el.hide === undefined || el.hide === false)
        .slice(startIndex, startIndex + data[0].length)
        .map((fld,i) => {
          fields.push(fld.data);

          //10-2-23 removed, as we can copy text and will work with tinymce editor.
          // if(fld.uitype===13) {
          // data.map(el=>{
          //   if(el[i]) {
          //     const blocksFromHTML = convertFromHTML(el[i]);
          //     const state = ContentState.createFromBlockArray(
          //       blocksFromHTML.contentBlocks,
          //       blocksFromHTML.entityMap,
          //     );
          //     const v = JSON.stringify(convertToRaw(state));
          //     el[i] =v
          //   }

          //  })
          // }

          if (invalidtypes.includes(fld.uitype)) {
            allValidFields = false;
          }
        });

      if (!allValidFields) {
        //can't paste data due to one of selected fields is invalid for pasting data.
        this.ShowError('You are atempting to paste into a field type that is not supported.');
        return false;
      }


      const updaterows = [];
      let updateitems = data;
  
      /* 3-2-2021 When pasting data, we need to look at how many rows are being copied.
      If multiple rows being copied, then we simply update this many rows starting at
      where selectedcell is positioned. This was default behavior. If single row copied,
      we instead look at highlighted rows for pasting and loop over copy data creating new
      array element for each highlighted pasted row.
      */
      const finalEndRow = data.length > 1 ? startRow + data.length : endRow + 1;

      let itemcount = 0;
      for (let i = startRow; i < finalEndRow; i += 1) {
        if (
          this.state.settings.data &&
          this.state.settings.data[i] &&
          this.state.settings.data[i].id !== undefined
        ) {
          if (data.length > 1) {
            updateitems[itemcount].unshift(this.state.settings.data[i].id);
          } else {
            const tempRow = [...data][0];
            const tempRow2 = [...tempRow];
            tempRow2.unshift(this.state.settings.data[i].id);
            updaterows.push(tempRow2);
          }
        } else {
          if (data.length > 1) {
            updateitems[itemcount].unshift(null);
          } else {
            const newRow = data;
            newRow.unshift(null);
            updaterows.push(newRow);
          }
        }
        itemcount += 1;
      }

      // 11-17-2021 Adding security for checking ability to addRecord for this table.
      // if user role doesn't have access, we remove any copied rows were Id is null so we don't create
      // new rows.

    

      if (tableinfo.security !== undefined && tableinfo.security.addRecord > role) {
        updateitems = updateitems.filter((el) => el[0] !== null);
      }

      this.PasteData(fields, updaterows.length > 0 ? updaterows : updateitems);
      return false;
    },
    beforeRemoveRow:  (index, amount, physicalRows, source) => {
      const { tableinfo, zoneid, activeRow,useSecurity } = this.state;
      const isSystemTable = tableinfo.isSystemTable;
      const tablename = tableinfo.tablename;
      var data = [...this.state.settings.data];

      // 6-22-2020 if they try to delete an empty row, return false
      if (data[index] === undefined) {
        return false;
      }

      if(useSecurity) {
        // this.ShowError('You do not have access to remove data.')
        // this.RefreshTable();
        return false;
      }

      this.confirmDeleteRows(index, physicalRows)
        return false;
    },
    beforeColumnMove: (columns, target) => {
    
      //If they siply click on header again, they aren't moving the column, therefore return false
      //this solves the issue where users miss the column icon and then force a refresh.
      if(columns[0]==target){
        return false;
      }
   

      // Don't let them move the "Add field" column or swap a column for "addifled column"
      const startcolumn = this.state.columns[columns[0]].data;

      if (
        startcolumn === 'id2' ||
        startcolumn === 'id' ||
        startcolumn === 'CustomID' ||
        target >= this.state.columns.length - 1 ||
        target === 0
      ) {
        this.ShowError('You cannot move this column or you cannot move it to this location');
        return false;
      }

      this.moveColumn(columns, target);

      return false;
    },
    afterColumnResize: (newSize, currentColumn, isdoubleClick) => {
      this.UpdateTableColumnWidth(
        this.hotTableComponent.current.hotInstance.colToProp(currentColumn),
        newSize,
      );
    },
  }; 

  /********END OF DEFINING HOTABLE SETTINGS****** */

  processChanges = (changes) => {
    const { zoneInfo, blockid } = this.state;

    changes.forEach(async ([row, prop, oldValue, newValue]) => {
      // check that value has changed, otherwise return
      if (oldValue === newValue || newValue === '') {
        return;
      }

      const index = this.state.tableinfo.columns.findIndex((x) => x.data === prop);
      const colinfo = this.state.tableinfo.columns[index];

      // 5-22-2020 . Special check if user is in "Users" table.
      // This has special logic to process separately from standard tables where user is either adding
      // new users to their zone, or updating existing user.
      if (this.state.tableinfo.tablename === 'Users') {
        let tmpid = '';
        if (this.state.settings.data[row] && this.state.settings.data[row].userid) {
          tmpid = this.state.settings.data[row].userid;
        }

        // 2/9/2021 if they are trying to add new user and have reached their license limit, show error.
        if (
          tmpid === '' &&
          zoneInfo.userCount >= zoneInfo.userlicenses &&
          zoneInfo.plan !== 'Free'
        ) {
          this.ShowError(
            <div>
              The license limit is exceeded for this Zone. To add more users, update your plan
              subscription to include more licenses. Learn more:{' '}
              <a
                style={{ color: 'white', textDecoration: 'underline' }}
                href="https://www.graceblocks.com/support-article/graceblocks-plans"
                target="_blank"
              >
                GraceBlocks plans
              </a>
              .
            </div>,
          );
          return false;
        }

        // don't allow a user role to be changed to zone owner unless they have a validated phone number.
        if (colinfo.data === 'role' && newValue === 'Zone owner') {
          let tmprow = '';
          if (this.state.settings.data[row] && this.state.settings.data[row].userid) {
            tmprow = this.state.settings.data[row].mobileverifydate;
            if (tmprow === null) {
              this.ShowError(
                <div>
                  Before this user can be a zone owner, they need to have a validated mobile phone
                  number linked to their profile. To proceed, have this user go to the "My profile"
                  page. Here they can update and validate the mobile phone number that is on file.
                </div>,
              );
              this.RefreshTable();
              return false;
            }
          }
        }

        // currentUserid,field,newvalue,CLICK,row,userid
        const result = await UpdateUsersTable(
          tmpid,
          colinfo.data,
          newValue,
          this.updateRowID,
          row,
          this.state.uid,
          this.state.zoneid,
          this.RefreshRow,
          true,
          colinfo.uitype,
          blockid,
        );
        if (result !== 'success' && result !== undefined) {
          this.ShowError(result);
        }
        return false;
      }

      const data = colinfo.data;
      const validator = colinfo.validator;

      // 5-18-2020, we need to set to 'public' schema to update system tables like blocks, users, etc
      // the crud operations will replace "public" with empty space...keepig public name so it's more obvious.
      const schema = this.state.tableinfo.isSystemTable ? 'public' : this.state.schema;

      // For fields that have defined validators,check value is valid/not
      // if not valid, simly return, no data will be updated. The custom renderer will show error state.
      if (validator !== undefined && newValue !== null && newValue !== '') {
        if (colinfo.uitype === 11 && !newValue.includes('http')) {
          newValue = `http://${newValue}`;
        }

        const test = RegExp(validator, 'i');
        const isValid = test.test(newValue);

        // 10-1-2020. don't allow any whitespace in email/url field
        if (newValue.includes(' ')) {
          return false;
        }

        if (!isValid) {
          return false;
        }
      }

      // 3-5-2021 Adding logic to check min/max values of numeric field
      if (
        colinfo.uitype === 23 &&
        newValue !== null &&
        (colinfo.minValue !== undefined || colinfo.maxValue !== undefined)
      ) {
        let minValid = true;
        let maxValid = true;

        //Test that value is real number.
        const test = RegExp('^-?\\d*(\\.\\d+)?$', 'i');
        const isValid = test.test(newValue);

        if (colinfo.minValue !== undefined) {
          minValid = newValue >= parseFloat(colinfo.minValue);
        }
        if (colinfo.maxValue !== undefined) {
          maxValid = newValue <= parseFloat(colinfo.maxValue);
        }

        if (!minValid || !maxValid || !isValid) {
          return false;
        }
      } else if (colinfo.uitype === 23 && newValue !== null) {
        //Test that value is real number.
        const test = RegExp('^-?\\d*(\\.\\d+)?$', 'i');
        const isValid = test.test(newValue);
        if (!isValid) {
          return false;
        }
      }

      // For relation updates, we update the row. however, we don't want to trigger any deletes
      // for lookup fields or for ID field
      if (colinfo.uitype === 18 || colinfo.data === 'id') {
        return;
      }

      let valueToSave = newValue;
      // eslint-disable-next-line prefer-const
      let colindex = 0;
      const maxlength = colinfo.maxlength;

      if (
        colinfo.uitype === 2 &&
        maxlength !== undefined &&
        newValue !== null &&
        newValue.toString().length > maxlength
      ) {
        // var cellMeta = $("#table").handsontable('getCellMeta', row, col);
        colindex = this.hotTableComponent.current.hotInstance.propToCol(prop);
        // const cellMeta =this.hotTableComponent.current.hotInstance.getCellMeta(row,colindex);
        valueToSave = newValue.substring(0, maxlength);
        this.hotTableComponent.current.hotInstance.setDataAtCell(row, colindex, valueToSave);

        this.ShowError(`Exceeded max value of ${maxlength} characters. Text has been truncated`);
      }

      let jointable = '';
      let lookuptable = '';
      let lookup = colinfo.lookup;
      // eslint-disable-next-line prefer-destructuring
      const jointype = colinfo.jointype;
      if (colinfo.source != null) {
        // eslint-disable-next-line no-const-assign
        lookuptable = colinfo.source.lookuptable;
        jointable = colinfo.source.jointable;
      }

      if (lookup === undefined) {
        lookup = 'text';
      }

      let tmpid = null;

      const checkRow = this.hotTableComponent.current.hotInstance.getDataAtCell(row, 0);
      if (checkRow !== null) {
        tmpid = checkRow;
      } else if (checkRow === null && valueToSave === '') {
        return false;
      }
      // 6-19-2020 ...with infite scroll, need to change how we check if id exists.

      const result = await addorupdateValues(
        tmpid !== null ? tmpid : '',
        this.state.justtablename,
        data,
        valueToSave,
        oldValue,
        this.updateRowID,
        row,
        lookup,
        jointype,
        lookuptable,
        jointable,
        schema,
        this.state.uid,
        colinfo.uitype,
        this.state.blockid,
        this.RefreshRow,
        this.state.tableinfo.isAdminBlock,
        colinfo,
        this.state.tableinfo,
      );

      /*  6-30-222 updated relational logic on how to add/remove relational items indididually
      versus the complete replacement strategy I had before. The reason is that the grid will only return up
      to 50 relation items to keep performance high. But when editing grid cell, it would do complete replacement
      even if the dB had hundred of linked items. I updated TableLink renderer to keep track of changed items, which
      is passed to this method. Below I manually update the relation values (Adding/removing). The CRUD 
      method will then call AddRemoveRelationalItems, which will update DB with those updates. 
      The setDataAtCell passes in "source" of relational, so that the applied updates do not trigger the inifinte loop.
      The beforeChange method will only call processChanges if source !=='relational'
      */
      if (colinfo.uitype === 1 && result === 'success') {
        if (valueToSave !== null && valueToSave !== '') {
          const DelItems = valueToSave.filter((itm) => itm.type === -1);
          if (DelItems.length > 0 && oldValue !== null) {
            const delIds = DelItems.map((el) => el.id);
            oldValue = oldValue.filter((el) => !delIds.includes(el.id));
          }

          const NewItems = valueToSave.filter((itm) => itm.type === 0 || itm.type === undefined);

          if (NewItems.length > 0 && oldValue !== null && colinfo.lookup==='multiple') {
            oldValue = oldValue.concat(NewItems.map((itm) => itm));
          } 
          else if (NewItems.length > 0 && (colinfo.lookup==='single' || oldValue===null)) {
            oldValue = NewItems.map((itm) => itm);
          }
        } else {
          oldValue = null;
        }
        // 8-3-222 if they remove the last item from grid, set value =null (instead of leaving as [])
        if (oldValue !== null && oldValue.length === 0) {
          oldValue = null;
        }
        colindex = this.hotTableComponent.current.hotInstance.propToCol(prop);
        this.hotTableComponent.current.hotInstance.setDataAtCell(
          row,
          colindex,
          oldValue,
          'relational',
        );
      }
      /* END OF NE RELATIONAL LOGIC */

      if (result !== 'success') {
        this.ShowError(result);
        // reset value to empty
        colindex = this.hotTableComponent.current.hotInstance.propToCol(prop);
        // const cellMeta =this.hotTableComponent.current.hotInstance.getCellMeta(row,colindex);
        valueToSave = '';
        this.hotTableComponent.current.hotInstance.setDataAtCell(row, colindex, valueToSave);
      }
    });
  };

  getHeight() {
    // return `500px`;
    const { showBookmarkBar,topBarHeight } = this.state;

    const calcHeight1 =155 + topBarHeight;
    const calcHeight2= 130 + topBarHeight;
    const currentHeight = showBookmarkBar ? window.innerHeight -calcHeight1 : window.innerHeight - calcHeight2;
   
    //commented out this code as it's a duplicated of the logic below. 
    // this.hotTableComponent.current &&
    //   this.hotTableComponent.current.hotInstance.updateSettings({
    //     height:
    //       this.state.filters.length === 0 ? window.innerHeight - 150 : window.innerHeight - 165,
    //     width: this.state.isExpanded ? window.innerWidth - 270 : window.innerWidth - 100,
    //   });

    const { isExpanded } = this.state;

    this.hotTableComponent.current &&
      this.hotTableComponent.current.hotInstance.updateSettings({
        height: currentHeight,
        width: isExpanded ? window.innerWidth - 270 : window.innerWidth - 85,
      });
    this.setState({ gridHeight: currentHeight });
  }

  moveColumn = async (columns, target) => {
    const newcolumns = this.arraymove(this.state.columns, columns[0], target);
    const tempsettings = this.state.settings;
    tempsettings.data = [];
    tempsettings.columns = [];

    let tinfo = this.state.tableinfo;
    tinfo.columns = newcolumns.slice(0, -1);
    // await updateTableInfoAPI(this.state.tableIndex, tinfo);
    await UpdateTableColumnOrderAPI(this.state.tableIndex, newcolumns.slice(0, -1));

    this.setState({ columns: [], showColumns: false, lastDeletedColumn: 0 }, async () => {
      await this.FetchGraphData(this.state.tableIndex);
    });
  };

  // This is used to close any open editors. This is called when scrolling grid horizontally or vertically.
  // We do this because editor does not move with scroll, so visually bad.
  cancelEditMode = () => {
    this.hotTableComponent.current.hotInstance.destroyEditor(true);
  };

  scrollControl() {}

  getRowHeight() {
    return this.state.rowHeight;
  }

  updateViewMode = async (mode) => {
    const { userTabSettings } = this.state;
    userTabSettings.gridView = mode;
    await this.updateUserTabSettingsLocal(userTabSettings);

    this.setState({
      userTabSettings: userTabSettings,
      showColumns: mode === 'grid' ? false : true,
      isLoading: mode === 'grid' ? true : false,
    });

   

    setTimeout(() => {
      this.setState({
        viewMode: mode,
      });
      if (mode === 'grid') {
         this.updateGridMode();
      }
    }, mode === 'grid' ?  100 : 0);

   
  };

  //9-15-22 Added this method which is called from updateviewmode when switching from another mode back to grid mode.
  //I've removed the need to re-run query, and this also maintains loaded data, so that if you are at 1500
  //rows and switch to quick view, data remains, same as if you switch back to grid view.
  // Mow will add logic which adds infinite scroll to quick view.

  updateGridMode = async () => {
    const { tableinfo, lastDeletedColumn, viewMode, role, settings,isLoading } = this.state;
    const { userInfo } = this.context;

    settings.dropdownMenu.items = this.ColumnActionItems();

    // HIDDEN COLUM LOGIC
    let hiddenColumns = [];
    if (tableinfo.isSystemTable !== undefined) {
      hiddenColumns = [0];
    }

    // 3-8-2021 loop over columns and set hiddencolumns array where hide:true
    tableinfo.columns.map((el, idx) => {
      if (el.hide === true) hiddenColumns.push(idx);
    });

    //set hidden column array
    settings.hiddenColumns = { columns: hiddenColumns };
    // END HIDDEN COLUM LOGIC

    settings.cells = (row, col) => {
      var cp = {};

      if (col === 0 && tableinfo.isSystemTable === undefined) {
        cp.className = tableinfo.showIDField ? 'firstcolumnID' : 'firstcolumn';
        cp.width = tableinfo.showIDField ? '40px' : '25px';
      }

      //convert last "Add field" column to light grey. NOT for system table.
      if (col === this.state.columns.length - 1 && tableinfo.isSystemTable === undefined) {
        cp.className = 'idcolumn';
      }

      return cp;
    };

    this.setState({
      settings: settings,
      showColumns: true, //determines if grid is rendered.
      refreshColumns: true, //determines if columns are set to
      //  zoneInfo:zoneInfo,
      isLoading: false,
    }); // put back


    this.getHeight();

    this.hotTableComponent.current &&
      (await this.hotTableComponent.current.hotInstance.updateSettings({
        // height:
        //   this.state.filters.length === 0 ? window.innerHeight - 155 : window.innerHeight - 170,
        rowHeights: this.state.rowHeight,
      }));

    if (lastDeletedColumn !== 0) {
      this.hotTableComponent.current &&
        this.hotTableComponent.current.hotInstance.scrollViewportTo(1, lastDeletedColumn);
    }

    // curerntrow logic
    let currentRow = `tbl_${this.state.tableIndex}_currentRow`;

    if (userInfo[currentRow] !== undefined && viewMode === 'grid') {
      this.hotTableComponent.current.hotInstance.scrollViewportTo(
        userInfo[currentRow],
        lastDeletedColumn,
      );
    }
  };

  // 1-12-2021 adding new sort by id on main grid. Added so that when adding new records for large
  // record sets we can revert sort order to bring newest items to top. Also will allow users
  // to sort by ID to see latest items added to grid.
  updateSortOrder = async (neworder) => {
    const { sortOrder, userTabSettings } = this.state;

    userTabSettings.sortField = 'id';
    // userTabSettings.sortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    userTabSettings.sortOrder=neworder;
    userTabSettings.sortHeader = 'id';

    //11-16-22 remove the multi-sort attribute.
    delete userTabSettings.sortMultipleFields;

    await this.updateUserTabSettingsLocal(userTabSettings);

    this.setState(
      {
        sortField: 'id',
        sortHeader: 'id',
        // sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
        sortOrder : neworder,
        isLoading: true,
        showColumns: false,
      },
      async () => await this.CallGraphQuery(this.state.offset),
    );
    this.AnimatedLoadingDelay();
  };

  updateUserTabSettingsLocal = async (updatedSettings) => {
    const { tableIndex } = this.state;
    await updateUserTabSettings(tableIndex, updatedSettings);
  };

  // Added this method to be passed to DetailView. When use changes window size, changes layout,
  // we need to update the settings. This updates DB and updates local variable so it stays in sync.
  updateSettingsFromDetailView = async (newSettings) => {
    await this.updateUserTabSettingsLocal(newSettings);
    // 12-2-2021 The reason for not using setState() is that this is called from detailview
    // and thefefore we don't need to rerender that page, just update the value. This is
    // way faster than using setState()
    this.state.userTabSettings = newSettings;
  };

  GetBlocktabInfo = async (blockid, userid) => {
    const blockinfo = await getBlockAPI(blockid, userid);

    // 3-2-2021 We retrieve /store all blocks this user has access to.
    // this is used on relational lookups to determine whether to show/not show "new record"
    // button based on if user has been grnated access to that block.

    const block = blockinfo[0];
    block.id = blockid; //update logic expects "id" instead of "blockid". this is from older code. This block info is used by blockeditor component to update blocks
    let userRole = 0;
    const blocksettings = blockinfo[0].BlockUsers[0].settings;

    this.setState({
      block,
      issystemblock: blockinfo[0].issystemblock,
      tabname: blockinfo[0].name,
      icon: blockinfo[0].icon,
      sort: blockinfo[0].sort,
      color: blockinfo[0].color,
      statement: blockinfo[0].statement,
      information: blockinfo[0].information,
      // role: userRole,
      blocksettings: blockinfo[0].BlockUsers[0].settings,
      isExpanded:
        blocksettings !== null && blocksettings.blockExpanded !== undefined
          ? blocksettings.blockExpanded
          : true,
    });

    // Change page icon to the block icon. Title is changed in render() below using <Helmet>
    const favico = document.getElementById('favicon');
    const color = encodeURIComponent(blockinfo[0].color);
    const svgpath = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' style='background-color:${color}' viewBox='0 0 24 24'%3E%3Cpath d='${blockinfo[0].icon}'/%3E%3C/svg%3E`;
    favico.href = svgpath;
  };

  UpdateRowHeight = async (height) => {
    const { userTabSettings, viewMode } = this.state;

    if (viewMode === 'grid') {
      this.hotTableComponent.current.hotInstance.updateSettings({ rowHeights: height });
    }

    let heightNumber = 1;
    if (height === 33) heightNumber = 1;
    if (height === 60) heightNumber = 2;
    if (height === 120) heightNumber = 3;

    userTabSettings.rowHeight = heightNumber;
    await this.updateUserTabSettingsLocal(userTabSettings);
    this.setState({ rowHeight: height, userTabSettings: userTabSettings });
  };

  updateModalHeight = (height) => {
    this.setState({ modalHeight: height });
  };

  hideDetailView = () => {
    this.setState({ showDetailView: false });
  };

  toggleUserProfile = () => {
    const { showUserProfile } = this.state;
    this.setState({ showUserProfile: !showUserProfile });
  };

  toggleShowBookmarkBar = async () => {
    const { showBookmarkBar, tableIndex, userTabSettings,topBarHeight } = this.state;

    userTabSettings.showBookmarkBar = !showBookmarkBar;
    await updateUserTabSettings(tableIndex, userTabSettings);

    // const currentHeight = !showBookmarkBar ? window.innerHeight - 185 : window.innerHeight - 160;
    const calcHeight1 =155 + topBarHeight;
    const calcHeight2= 130 + topBarHeight;
    const currentHeight = !showBookmarkBar ? window.innerHeight -calcHeight1 : window.innerHeight - calcHeight2;

    // this.hotTableComponent.current && this.hotTableComponent.current.hotInstance.updateSettings({ height: this.state.filters.length===0 ? window.innerHeight -150 : window.innerHeight-165, width: this.state.isExpanded ? window.innerWidth-270 : window.innerWidth-100 });
    const { isExpanded } = this.state;

    this.hotTableComponent.current &&
      this.hotTableComponent.current.hotInstance.updateSettings({
        height: currentHeight,
        width: isExpanded ? window.innerWidth - 270 : window.innerWidth - 85,
      });

    this.setState({ gridHeight: currentHeight, showBookmarkBar: !showBookmarkBar });
  };

  toggleNotifications = () => {
    const { showNotifications } = this.state;
    this.setState({ showNotifications: !showNotifications });
  };

  ShowConfettiModal = (msg) =>{
    this.setState({
      showConfetti:true,
      ConfettiMsg: msg,
      isLoading: false,
      modalIsOpen: true,
      x: (window.innerWidth/2)-400,
      y: 120,
      modalHeight: 'calc(100vh - 200px)',
      // modalWidth: 'calc(100vw - 500px)',
      modalWidth:'850px'
    });

  }

  toggleDuplicateBlock = () => {
    const { showDuplicateBlock, block } = this.state;
    if (block.isMaxRowsExceeded) {
      this.ShowError(
        <div>
          The copy Block function is disabled because this Zone is over the plan limits.{' '}
          <a
            style={{ color: 'white', textDecoration: 'underline' }}
            href="https://www.graceblocks.com/support-article/graceblocks-plans"
            target="_newWindow"
          >
            Learn more
          </a>
          .
        </div>,
      );
    } else {
      this.setState({ showDuplicateBlock: !showDuplicateBlock });
    }
  };

  /* 9-15-2020 Added this method as, in detail view, the user can change the order of the fields on display,
  which updates the tableinfo. I update the DB at this time AND the local tableinfo used in component, but
  we also need to update here, so when they click next/prev, ..we are sending the tableinfo each time.
  */
  updateTableInfo = (tableinfo) => {
    this.setState({ tableinfo });
  };

  /* SERVICE CHECK 5-30-24 Check if table has service level restrictions for max rows in table */
  serviceCheck = (type,field) =>{
    const { tableinfo, recordCount } = this.state;
    const { userInfo } = this.context;

    //Type is either maxRows or requiredFields as of 5/31. Those are the 2 things we chedc

    /*5-30-24 Added the concept of a "Service" node to tableinfo. Right now, I would manually add this for any
    type of table that is used in a "Service" Block, which means a preconfigured table with fields that are 
    used to drive a specific service.  First one is Google Alerts service, but we could add others over time.
    It most likely will have a maxRows setting, which indicated the allows records for a given service plan 
    and this could be extenced to other settings for future service offerings.
    */

     if(tableinfo.Service !==undefined) {
      const servicePlanData = tableinfo.Service[userInfo.plan];

      // 5-30-24If recordcount >= maxRows (standard service level metric I am expecting) , don't allow.
      if(type==='maxRows' && servicePlanData?.maxRows && recordCount >= servicePlanData?.maxRows) {
        this.ShowError(`Your current plan only allows for ${servicePlanData.maxRows} records in this table.Upgrade your plan to enable addditional records! `)
        return true;
      } else if(type==='requiredFields') {
        if(tableinfo.Service?.requiredFields.includes(field)) {
          this.ShowError(`This field cannot be modified or deleted because it is used by a GraceBlocks service.`)
          return true;
        }
        return  false;
      }
    } else {
      return false 
    }
    /**END SERVICE CHECK */
  }

  insertNewTableRow = async () => {
    const { tableIndex, tableinfo, role, blockid, activeRow, viewingCount,isLoading,recordCount } = this.state;
    const { userInfo } = this.context;

    // 1-16-23 if it's still processing previous click to add row, just return.
    if(isLoading){
      return false
    }

    if(this.serviceCheck('maxRows')) {
      return false;
    }

    this.setState({isLoading:true})

    const plugin = this.hotTableComponent.current.hotInstance.getPlugin('autoRowSize');
    const lastRow = plugin.getLastVisibleRow();

    //Only allow users who have ability to add new rows.
    if (tableinfo.security !== undefined && role < tableinfo.security.addRecord) {
      return false;
    }

    const newRow = await insertNewRow(tableIndex, tableinfo, blockid, role);
    const tempsettings = { ...this.state.settings };
    if (activeRow === undefined) {
      tempsettings.data.splice(lastRow, 0, newRow[0]);
    } else {
      tempsettings.data.splice(activeRow + 1, 0, newRow[0]);
    }

    tempsettings.cells = (row, col) => {
      var cp = {};
      if (col === 0 && tableinfo.isSystemTable === undefined) {
        cp.className = tableinfo.showIDField ? 'firstcolumnID' : 'firstcolumn';
        cp.width = tableinfo.showIDField ? '40px' : '25px';
      }
      if (col === this.state.columns.length - 1 && tableinfo.isSystemTable === undefined) {
        cp.className = 'idcolumn';
      }
      return cp;
    };

    this.setState({
      recordCount: this.state.recordCount + 1,
      filterCount: this.state.filterCount + 1,
      viewingCount: this.state.viewingCount + 1,
      currentMaxID: newRow[0].id,
      activeRow: activeRow ===undefined ? undefined : activeRow+1,
      settings: tempsettings,
      isLoading:false
    });
    this.hotTableComponent.current.hotInstance.deselectCell();
  };

  showDetailView = async (view, currentIndex, id,groupids=null) => {
    /* Moved logic to click to view Details into mouse-down event on 1st column. */
    
    // 12-9-22 added "groupids" attribute to support when viewing records from group view. Here
    //we get array of all id's in the group, so these can be used by next/prev logic to correctly
    //navigate to next/prev record
    const { useSecurity, viewMode, tableinfo, settings, isPublicZoneBlock,filterCount } = this.state;

    if (currentIndex === null) {
      currentIndex = settings.data.findIndex((el) => el.id === id);
    }
    
    //12-15-22, adding logic to store whether next/prev button should appear based on index.
    //first we base it on currentindex, but if coming from groups, we use groupids to determin if there is next/prev path.
    let nextPrevBool={prev:currentIndex-1>=0,next:currentIndex+1<filterCount}
    if(groupids !==null){
      const groupIdx = groupids.findIndex(el=>el===id);
      nextPrevBool={prev:groupIdx-1>=0,next:groupIdx+1<groupids.length}
    }


    // 11-19-2021 added canEdit param.
    // 5-2-2022 added check on isPublicZoneBlock. if true, don't allow editing.
    let canEdit = true;
    if (isPublicZoneBlock) {
      canEdit = false;
    } else if (useSecurity) {
      canEdit = this.checkRowSecurity(currentIndex);
    }

    if (tableinfo.tablename !== 'Users' && tableinfo.tablename !== 'BlockUsers') {
      if ((currentIndex !== undefined && settings.data[currentIndex].id !== null) || id !== undefined) {
        let selectedItem = {};
        if (id !== undefined) { //from quickview
          selectedItem = settings.data.filter((itm) => itm.id === id)[0];

        } else {
          selectedItem = settings.data[currentIndex];
        }
        selectedItem.canEdit = canEdit; // 11-19-2021 attach canEdit field to datarecord.

        this.setState({
          showDetailView: true,
          displacementWidth: 300,
          selectedItem,
          selectedRowIndex: currentIndex,
          activeRow: currentIndex, // 12-15-2021 need this for quickview to change active row so next/prev buttons can work
          groupids,
          nextPrevBool,
          selected_id: id
        });

        // we disable detcting click outside so detailview stays open when editing record/etc.
        /// it is reenabled when closing detail view.
        if (viewMode === 'grid') {
          this.hotTableComponent.current.hotInstance.updateSettings({
            outsideClickDeselects: false,
          });
        }

      }
    }

    // 6-23-2020 When in detail view, we don't want to lose selection focus, so that when user clicks
    // next/prev, the selection box stays around the item. However, when they close the details view, we want
    // to set this to true, because otherwise, when editing a field type, like the option select list for example,
    // if item remained selected, it confuses javascript when hitting enter and updates underlying option instead of editor control.
    // This dynamically changes it based on whether we are in detail view or not.

    if (!view && viewMode === 'grid') {
      this.hotTableComponent.current.hotInstance.updateSettings({ outsideClickDeselects: true });
    }
  };

  showBlockEditorHandler = () => {
    const { showBlockEditor } = this.state;
    this.setState({ showBlockEditor: !showBlockEditor });
  };

  // 8-18-2020, just added the block editor control to page. If they update block info, it updates be and will then pass back
  // to this method, which can update state.
  updateBlockInfo = (block) => {
    this.setState({
      block,
      tabname: block.name,
      color: block.color,
      statement: block.statement,
      information: block.information,
      lastBlockEdited: new Date(),
      icon: block.icon,
    });
  };

  UpdateTableColumnWidth = async (field, newSize) => {
    const { tableIndex, userTabSettings } = this.state;
    // //  update width of column in DB
    await UpdateTableColumnWidthAPI(tableIndex, field, newSize);

    // //  update local column width, so cell renderes' get their width updated dynamically.
    const rowIndex = this.state.tableinfo.columns.findIndex((el) => el.data === field);
    if (rowIndex !== -1) {
      const tmpColumns = [...this.state.columns];
      tmpColumns[rowIndex].width = newSize;
      this.setState({ columns: tmpColumns });

      // if (userTabSettings.groups === undefined) {
      //   this.setState({ columns: tmpColumns });
      // } else {
      //   this.setState({ columns: tmpColumns, isLoading: true }, () =>
      //     this.CallGraphQuery(this.state.offset),
      //   );
      //   this.AnimatedLoadingDelay(false);
      // }
    }
  };

  PasteNewUsers = async (data) => {
    const { blockid } = this.state;
    this.setState({ isLoading: true, isDone: false, modalIsOpen: true });
    await PasteNewUsers(data, this.state.zoneid, this.RefreshTable, this.state.uid, blockid);
    await this.AnimatedLoadingDelay(false);
  };

  PasteData = async (fields, data) => {
    // 2-2-2021 Check to see if any fields in paste are NOT supported fields such as lookup fields
    // if any are, show Toast message and return
    const { tableinfo, role, currentMaxID } = this.state;

    // User is not builder and paste would add columns
    if (data[0].length > fields.length && role !== 3) {
      this.ShowError(
        <div>
          Oops, this action is not supported. To add columns while pasting, you need to have the
          builder role for this Block. Read more:{' '}
          <a
            style={{ color: 'white', textDecoration: 'underline' }}
            href="https://graceblocks.zendesk.com/knowledge/articles/360056120692/en-us?brand_id=360003603191"
            target="_blank"
          >
            Copy & Paste Tips
          </a>
        </div>,
      );
      return false;
    }

    //If notifications is enabled for this table and they are pasting over 50 rows, provide following toast message.
    var enableNotify =
      tableinfo.columns.findIndex(
        (itm) =>
          (itm.uitype === 8 && itm.enableNotify == true) ||
          (itm.source != undefined && itm.source.lookupuitype == 8 && itm.enableNotify === true),
      ) !== -1;
    if (enableNotify && data.length > 50) {
      toast.info(
        <div style={{ margin: '10px' }}>
          Notifications to collaborators will not be generated for this paste action because more
          than 50 records have been updated. Please notify any affected collaborators manually about
          relevant changes you have just applied.
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 30000,
        },
      );
    }

    this.setState({ isLoading: true, isDone: false, modalIsOpen: true });

    const result = await PasteData(
      this.state.tableIndex,
      fields,
      data,
      this.state.tableinfo,
      this.RefreshRows,
      this.FetchGraphData,
      this.state.uid,
      this.state.blockid,
    );

    if (result !== 'success') {
      if (result === 'BadPasteData') {
        this.ShowError(
          <div style={{ margin: '10px' }}>
            Oops, let's try that again.If pasting from Excel, make sure you choose "FORMAT" -
            "Autofit Columns Width". Please read the help article related to this action:{' '}
            <a
              style={{ color: 'white', textDecoration: 'underline' }}
              href="https://www.graceblocks.com/support-article/faq-copy-and-paste-tips"
              target="_blank"
            >
              Copy and paste tips
            </a>
            .
          </div>,
        );
      } else {
        this.ShowError(result);
      }
    }
    await this.AnimatedLoadingDelay(false);
  };

  updateRowID = async (row, id, refreshRow = false, lookuptable = null) => {
    //  if this is called, this means a new row was added, so we refresh grid.
    // 6-21-2022 added "lookuptable" param to deal with use-case where user has selected/updated a relational
    //value. In this use-case, we only need to update lookup fields associated with this lookuptable.
    //trying to maximize performance as much as possible in these situations.

    // 12-8-2021
    if (id === undefined) return false;

    // 6-22-2020 Moved logic from tablelink to refresh a relational row to here, so that
    // it support when they create a new row by selecting a relation field, which requires the ID of new
    //row in order to refresh rest of lookup fields.

    if (refreshRow) {
      // this.hotTableComponent.current.hotInstance.setDataAtCell(row, 0, id);
      // this.setState({isLoading:true });
      // this.setState({ recordCount: this.state.recordCount + 1,filterCount: this.state.filterCount+ 1,isLoading:true });
      await this.RefreshRow(id, 'id', row, lookuptable);
    } else {
      // this.hotTableComponent.current.hotInstance.setDataAtCell(row, 0, id);
      // this.setState({ recordCount: this.state.recordCount + 1,filterCount: this.state.filterCount+ 1 });
    }
  };

  // this is used from TableLink to get data rowID used to query for relation items.
  GetRowData = (row) => {
    // Check table to see if any columns are "lookup" columns. If they exist, we will want to
    // call the RefreshRow method to retrieve latest lookup values based on relation fields selected.

    const id = this.hotTableComponent.current.hotInstance.getDataAtCell(row, 0);
    if (id === undefined || id === null) {
      return null;
    }

    const checkLookup = this.state.tableinfo.columns.findIndex((col) => col.uitype === 18);
    const value = {
      dataID: id,
      tablename: this.state.tablename,
      tableRecordName: this.state.tableinfo.recordname,
      recordname:
        this.state.settings.data[row] !== undefined ? this.state.settings.data[row].CustomID : '',
      hasLookups: checkLookup !== -1,
      data: this.state.settings.data[row],
    };

    return value;
  };


  confirmDeleteRows =(index, physicalRows) =>{
    const singleValue={index,physicalRows}
 
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title={'Delete row(s) forever?'}
            action={this.deleteRowsLocal}
            actionValue={singleValue}
            buttonLabel="OK"
            message='You have chosen to delete the selected row(s)?'
            showInput={true}
            divRef={this.deleteRowsInput}
            confirmAction="Delete"
            confirmWord='delete'
            height="300px"
            onClose={onClose}
          />
        );
      },
    });
 
}

deleteRowsLocal = async (singleValue) => {

      if (this.deleteRowsInput.current.value !== 'delete') {
        return false;
      }

      const { tableinfo, zoneid, activeRow,useSecurity } = this.state;
      const isSystemTable = tableinfo.isSystemTable;
      const tablename = tableinfo.tablename;
      var data = [...this.state.settings.data];

        const delIds = [];
        singleValue.physicalRows.forEach((itm) => {
          if (data[itm].id !== null) {
            delIds.push(data[itm].id);
          }
        });

        if (tablename === 'Users') {
          const userid = data[singleValue.index].userid;
          const result = await  DeleteUser(userid, zoneid);
  
          if (result !== 'success' && result !== undefined) {
            // Only error returned would be due to trying to remove Active/Inactive user.
            this.ShowError(
              <div style={{ marginRight: '5px' }}>
                Remove row is not supported for Active/Inactive users, use filters instead.{' '}
                <a
                  style={{ color: 'white', textDecoration: 'underline' }}
                  href="https://www.graceblocks.com/support-article/managing-zone-users"
                  target="_blank"
                >
                  Learn more
                </a>
              </div>,
            );
            return false;
          } else {
            this.RefreshTable();
          }
        } else {
    
            await deleteRows(
              delIds,
              this.state.tableIndex,
              this.state.schema,
              isSystemTable,
              tablename,
            );

            // 3-4-2021 update recordCount and FilterCount removing delete row count
            // 12-10-2021 adding activeRow:undefined, so that if they click to add new record, it resets
            // and will properly add record to bottom of recordset.

            const tempsettings = { ...this.state.settings };
            tempsettings.cells = (row, col) => {
              var cp = {};
              if (col === 0 && tableinfo.isSystemTable === undefined) {
                cp.className = tableinfo.showIDField ? 'firstcolumnID' : 'firstcolumn';
                cp.width = tableinfo.showIDField ? '40px' : '25px';
              }
              if (col === this.state.columns.length - 1 && tableinfo.isSystemTable === undefined) {
                cp.className = 'idcolumn';
              }
              return cp;
            };

            tempsettings.data = tempsettings.data.filter((el) => !delIds.includes(el.id));

            this.setState({
              recordCount: this.state.recordCount - delIds.length,
              filterCount: this.state.filterCount - delIds.length,
              viewingCount: this.state.viewingCount - delIds.length,
              activeRow: undefined,
              settings: tempsettings,
            });

            this.hotTableComponent.current.hotInstance.deselectCell();
      }
  }

  confirmAction = (colindex) => {
  
    const { color, columns } = this.state;
    let colname=this.hotTableComponent.current.hotInstance.colToProp(colindex[0][1]);
    const header = columns.filter((el) => el.data === colname)[0].header;

    const message = (
      <div>
        You have selected to delete the field: {header}.<p />
        Are you sure you want to delete this field? Any data associated with the field will be
        abolished.
      </div>
    );

    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title="Delete field"
            action={this.delCol}
            actionValue={colindex}
            onClose={onClose}
            showCancelButton={true}
            confirmAction="delete"
            confirmWord="delete"
            divRef={this.input}
            buttonLabel="OK"
            message={message}
            showInput={true}
            height="350px"
          />
        );
      },
    });
  };

  delCol = async (colindex) => {
    const { tableinfo, useSecurity, tableIndex } = this.state;
    const { userInfo } = this.context;

    // They must type delete in confirm delete prompt.
    if (this.input.current.value !== 'delete') {
      return;
    }

    console.log(colindex);

    const plugin = this.hotTableComponent.current.hotInstance.getPlugin('autoColumnSize');
    const firstcolumn = plugin.getFirstVisibleColumn();

    //2-6-25 changed index to 500 from 100. i think i initially put limit at 100, but 
    //that is no longer the case.
    if (colindex[0][1] !== 500) { 
      const colname = this.hotTableComponent.current.hotInstance.colToProp(colindex[0][1]);
      const tempcolumns = this.state.columns;

      //5-31-24 Check if field is used as a "service field" (Google Alerts being first one)
      if(this.serviceCheck('requiredFields',colname)) {
          return false;
        }

      // 8-4-22 if they are on pro/enterprise plan, we need to check if field is used in nested relational
      // We don't allow this.

      const nestedFields = await canFieldBeDeleted(colname, tableIndex,userInfo.blocks);

      let isWorkFlowField=false;
      if (nestedFields.length > 0) {
        
          //if the field is used in workflow, show block/workflow
          let fields =''
          if(nestedFields.findIndex(itm=>itm.type==='workflow') !==-1) {
            isWorkFlowField=true;
            fields = nestedFields.map((el) => (
              <div>
               {el.blockName} &gt; ({el.header})
               </div>
              ));
          } else {
            fields = nestedFields.map((el) => (
              <div>
               {el.blockName} &gt; {el.name}: ({el.header})
               </div>
              ));
          }

        toast.info(
          <div style={{ marginRight: '10px' }}>
            This field is refererenced in the following {isWorkFlowField ? 'Block > Workflow' : 'Block > tab: (field) locations:'} 
            <p/>
            <p>{fields}</p>
            <p/>
            You must remove these fields prior to removing this field. 
          </div>,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: false,
          },
        );
        return false;
      }
      
      const rowIndex = tempcolumns.findIndex((el) => el.data === colname);

      if (rowIndex !== -1) {
        const colInfo = tempcolumns[rowIndex];
        const listfield = colInfo.data;

        // 8-13-2021 Adding logic so that a message thread field cannot be deleted. The reason is that when it's
        // created it has a defined SMS number associated with it. We don't want to allow users to delete/re-add
        // the message field with different numbers as this causes down stream issues. Instead, they need
        // to delete the tab and start again.
        if (colInfo.uitype === 12) {
          this.ShowError(
            <div>
              The message thread field cannot be deleted.{' '}
              <a
                style={{ color: 'white', textDecoration: 'underline' }}
                href="https://www.graceblocks.com/support-article/field-type-messaging-thread"
                target="_newwindow"
              >
                Click here
              </a>{' '}
              for more info.
            </div>,
          );
          return false;
        }

        // 6-30-2020 We are building customID view by defined columns in concact.
        // If the field to be deleted has concat attribute, we need to update
        // the view by changing concact order of remaining elements. That logic is in server api
        if (colInfo.concat !== undefined) {
          // in this call, we pass the field which is to be removed, so that logic can exlude this
          // field from recalculating new view from existing fields.
          const payload = { tableid: this.state.tableIndex, tableinfo, field: listfield };
          await cloudrun.post('/updateCustomIdView', { payload });

          // 12-2-2021 Show toast to let them know RID has chnaged.
          toast.info(
            <div style={{ margin: '10px' }}>
              🔔Good job building! IMPORTANT NOTE: With this more recent change, you have altered
              the "record identifier" for this tab. As a result, you will want to update how you
              will refer to records in this tab.{' '}
              <a
                style={{ color: 'white', textDecoration: 'underline' }}
                href="https://www.graceblocks.com/support-article/managing-tabs"
                target="_newWindow"
              >
                Please see this help article{' '}
              </a>
              (in particular, review Modifying tabs, step 2B) to learn more.
            </div>,
            {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 30000,
            },
          );
        }

        this.setState({
          lastDeletedColumn: firstcolumn, // 1-7-2021 we remember the colum deleted so we can set horizontal scroll to this position.
          isLoading: true,
          isDone: false,
          modalIsOpen: false, // 12-2-2021 changed this to false, as the edit column modal was showing for a second after deleting column. Not sure why we'd want it to stay open?
          columns: [],
          showColumns: false,
        });
        const payload = { tableid: this.state.tableIndex, fieldname: listfield };
        const result = await cloudrun.post('/removeField', { payload });

        // IF we could successfully remove physical column, remove it from tableinfo/reload.
        if (result === 'success') {
          await this.FetchGraphData(this.state.tableIndex);
        } else {
          if (result.error === 'nestedField') {
            let fields = result.fields.map((el) => (
              <ul>
                {el.name}: {el.header}
              </ul>
            ));
            const msg = (
              <div style={{ marginRight: '10px' }}>
                This field is used in a relational nested fields. It can not be deleted until you
                remove it from the nested fields in the following relational field(s):
                <p>{fields}</p>
              </div>
            );
            this.ShowError(msg);
          } else {
            this.ShowError(result);
          }
        }
      }
      // this.setState({isLoading:false,modalIsOpen:false})
      this.AnimatedLoadingDelay(false);
    } else {
      this.ShowError('You cannot delete the Record column, it has special powers.');
    }
  };

  // 3-8-2021
  updatehiddenColumns = async (selectedCell, isHideColumn, field) => {
    const { viewMode } = this.state;
    let colname = '';

    if (viewMode === 'grid' && selectedCell[0][1] === 0) {
      return false;
    }

    const { tableIndex, tableinfo, settings } = this.state;

    if (viewMode === 'grid') {
      colname = this.hotTableComponent.current.hotInstance.colToProp(selectedCell[0][1]);
    } else {
      colname = field;
    }

    this.setState({ modalIsOpen: true, isLoading: true, isDone: false });

    if (!tableinfo.userDefinedColumns) {
      //need to create user version copy of fields.
      await UpdateTableColumnOrderAPI(this.state.tableIndex, tableinfo.columns);
      tableinfo.userDefinedColumns = true;
    }

    // update the database
    const result = await hideColumn(tableIndex, colname, isHideColumn);

    let firstcolumn = 0;
    if (viewMode == 'grid') {
      const plugin = this.hotTableComponent.current.hotInstance.getPlugin('autoColumnSize');
      firstcolumn = plugin.getFirstVisibleColumn();
    }

    if (isHideColumn) {
      tableinfo.hasHiddenColumns = true;
      tableinfo.columns.filter((itm) => itm.data === colname)[0].hide = true;
    }

    // IF we could successfully hide physical column in db, remove it from tableinfo/reload.

    if (result === 'success') {
      this.setState(
        {
          settings,
          lastDeletedColumn: firstcolumn,
          // isLoading:true,
          // isDone:false,
          tableinfo: tableinfo,
          modalIsOpen: false,
          columns: [],
          showColumns: false,
        },
        async () => {
          if (isHideColumn) {
            tableinfo.hasHiddenColumns = true;
            await this.FetchGraphData(this.state.tableIndex, tableinfo);
          } else {
            await this.FetchGraphData(this.state.tableIndex);
          }
          this.AnimatedLoadingDelay(false);
        },
      );
    } else {
      this.ShowError(result);
    }
  };

  setSortField = async (colindex, sortOrder, field, header) => {
    const { userTabSettings, viewMode, tableinfo } = this.state;
    let colname = '';
    let colHeader = '';

    // 12 -15-2021 adding support to sort from quickview. In that mode, will pass null for colindex and pass field name directly.
    if (viewMode === 'grid') {
      colname = this.hotTableComponent.current.hotInstance.colToProp(colindex[0][1]);
    } else {
      colname = field;
    }

    const colInfo = this.state.columns.filter((el) => el.data === colname)[0];

    // 8-12-2021 Changed sort restrictions based on new query format. Only single value standard
    // fields can be sorted. No relational/lookup/message or singe/multi select as of right now.
    // Hopefully will have new sort solution shortly.
    const singlevaluefields = [2, 3, 5, 9, 10, 11, 17, 19, 20, 22, 23, 24, 25];

    if (
      (colInfo.source && colInfo.uitype === 18 && colInfo.source.lookup === 'multiple') ||
      (colInfo.lookup === 'multiple' &&
        (['all', 'unique'].includes(colInfo.specialfunction) || !colInfo.specialfunction)) ||
      colInfo.uitype === 12 || colInfo.uitype==13
    ) {
      toast.info(
        'Sorting on multiple select, message or RTF field types is not supported. If sorting is desired, discuss with the Builder for this Block to see if this field type can be altered to be single-select instead.',
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 20000,
        },
      );
      return false;
    } else if (colInfo.source && ['all', 'unique'].includes(colInfo.specialfunction)) {
      const baselookup = tableinfo.columns.filter((el) => el.data === colInfo.source.basefield)[0]
        .lookup;
      if (baselookup === 'multiple') {
        toast.info(
          <div style={{ margin: '10px' }}>
            Sorting on lookup fields associated with a multi-select relational field is not
            supported. If sorting is desired, discuss with the Builder for this Block to see if this
            field type can be altered to be single-select instead.
          </div>,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 20000,
          },
        );
        return false;
      }
    }

    //8-12-22 adding block on addeby/updatedby and collaborotor fields, as we don't have user data to sort on.
    //in future we may implement sorting AFTER retrieving data and sorting on what is returned (top x rows.)
    if (
      [8, 24, 25].includes(colInfo.uitype) ||
      (colInfo.source && [8, 24, 25].includes(colInfo.source.lookupuitype))
    ) {
      toast.info(
        <div style={{ margin: '10px' }}>
          Sorting on user or collaborator fields is not yet supported.
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 20000,
        },
      );
      return false;
    }

    if (viewMode === 'grid') {
      const col = parseInt(colindex[0][1]);
      userTabSettings.sortHeader = this.state.columns[col].header;
      colHeader = this.state.columns[col].header;
    } else {
      userTabSettings.sortHeader = header;
      colHeader = header;
    }

    userTabSettings.sortField = colname === 'id' ? 'custom.fullname' : colname;
    userTabSettings.sortOrder = sortOrder;

    //11-16-22 remove the multi-sort attribute.
    delete userTabSettings.sortMultipleFields;

    await this.updateUserTabSettingsLocal(userTabSettings);
    this.setState(
      {
        sortField: colname === 'id' ? 'custom.fullname' : colname,
        sortOrder,
        showColumns: false,
        sortHeader: colHeader,
        // lastDeletedColumn: 0, 6/6/23 not sure why I was resetting this to 0. on sort,we want to maintain scroll position.
        isLoading: true,
      },
      () => this.CallGraphQuery(this.state.offset),
    );
    this.AnimatedLoadingDelay(false);
  };

  FilterData = (colindex, field) => {
    const { filters, viewMode } = this.state;
    let currentFilters;
    let colname = '';

    if (filters === '') {
      currentFilters = [];
    } else {
      currentFilters = filters;
    }

    if ((viewMode === 'grid' && colindex[0][1] !== 0) || field !== undefined) {
      if (viewMode === 'grid') {
        colname = this.hotTableComponent.current.hotInstance.colToProp(colindex[0][1]);
      } else {
        colname = field;
      }

      const rowindex = currentFilters.findIndex((el) => el.field === colname);
      if (rowindex === -1) {
        currentFilters.push({ field: colname });
      }

      this.setState({
        filters: currentFilters,
        showFilters: true,
        modalIsOpen: true,
        modalWidth: 450,
        x: window.innerWidth - 510,
        y: 120,
        modalHeight: 600,
      });
    }
  };

  ShowFilterModal = () => {
    this.setState({
      showFilters: true,
      isLoading: false,
      modalIsOpen: true,
      modalWidth: 450,
      x: window.innerWidth - 510,
      y: 120,
      modalHeight: 500,
    });
  };

  ShowNewRecordModal = () => {
    const { forms, zoneid } = this.state;
    const { userInfo } = this.context;

    //Check if table has "Service" node. We will block pasting in this use-case.
    if(this.serviceCheck('maxRows')) {
      return false;
    }

    const dbname = userInfo.zones.filter(itm=>itm.id===parseInt(Global.zoneid))[0].database;

    // 4-21-2021 When user clicks "add new record", we determine if there is a "default" Form
    // that's been created. If yes, we launch that. Otherwise show the default system addnewRecord modal
    const idx = forms.findIndex((el) => el.isdefault);
    if (idx !== -1) {
      const c = Buffer.from(`${zoneid}|${dbname}|${forms[idx].value}`);

      let base64data = c.toString('base64');
      this.setState({
        showFormView: true,
        formToken: base64data,
        isLoading: false,
        modalIsOpen: true,
        x: (window.innerWidth/2)-400,
        y: 120,
        modalHeight: 'calc(100vh - 200px)',
        // modalWidth: 'calc(100vw - 500px)',
        modalWidth:'800px'
      });
    } else {
      // Show default system addNew Record
      this.setState({
        showNewRecord: true,
        isLoading: false,
        modalIsOpen: true,
        x: 350,
        y: 120,
        modalHeight: 'calc(100vh - 200px)',
        modalWidth: 'calc(100vw - 500px)',
      });
    }
  };

  ShowManageWebFormsModal = (value,searchParams=null) => {
    if (value === 'edit') {
      this.setState({
        showManageWebForms: true,
        isLoading: false,
        modalIsOpen: true,
        x: 100,
        y: 60,
        modalHeight: 'calc(100vh - 60px)',
        modalWidth: 'calc(100vw - 150px)',
      });
    } else {
      const { zoneid,viewMode } = this.state;
      const { userInfo } = this.context;
      const dbname = userInfo.zones.filter(itm=>itm.id===parseInt(Global.zoneid))[0].database;

      let base64data = '';
      if(searchParams==null) {
        const c = Buffer.from(`${zoneid}|${dbname}|${value}`);
          base64data = c.toString('base64');
      } else {
        base64data =searchParams.x;

        //11-2-23 Added to support updating of a form. We don't want to lose row selection.
        // here we turn off detecting clicks outside grid, so it remains selected. When form is completed
        // we re-enable is.
        if (viewMode === 'grid') {
          this.hotTableComponent.current.hotInstance.updateSettings({
            outsideClickDeselects: false,
          });
        }
       
      }

      this.setState({
        showFormView: true,
        formToken: base64data,
        isLoading: false,
        modalIsOpen: true,
        x: (window.innerWidth/2) -400,
        y: 120,
        modalHeight: 'calc(100vh - 200px)',
        // modalWidth: 'calc(100vw - 500px)',
        modalWidth:'800px',
        searchParams
      });

      // window.open(`${REACT_APP_SITE_URL}/form?x=${base64data}`);
    }
  };

  RunFilterQuery = async (filters,keepModalOpen=true) => {
    // Save last filter query in tabsettings for user.

    try {
      const { userTabSettings } = this.state;

      //remove any "empty filters" from list.
      // 1-10-2022 we do a more complete cleanup of filters in the buildquery.js api
      // where we check all field types and sure value is not ''.
      const cleanFilters = filters.filter((itm) => itm.operator !== undefined);

      userTabSettings.filters = cleanFilters;
      await this.updateUserTabSettingsLocal(userTabSettings);

      this.setState(
        { showColumns: false, filters: cleanFilters, currentBookMarkId:null,startingData:null },
        async () => await this.CallGraphQuery(this.state.offset),
      );

      // 11/23/24 commented this out to fix issue where you clcik to run query , then click to close
      // filters and it pops up modal again. runs correctly Without this.
      // this.AnimatedLoadingDelay(keepModalOpen);
    } catch (error) {
      console.log('an error occurred');
    }
  };

  ReloadTable = async (tableid, name, icon, recordname) => {
    const { role, viewMode,settings } = this.state;
    const { userInfo } = this.context;
    
    settings.data=[];
    if (tableid !== 'home') {
      this.setState(
        {
          lastDeletedColumn: 0,
          currentMaxID: 0,
          showColumns: false,
          columns: [],
          refreshColumns: true,
          filters: [],
          recordCount: 0,
          offset: 0,
          activeRow: undefined,
          // tabledata:[],
          tableIndex: tableid,
          tablename: name,
          recordname: recordname,
          tableicon: icon,
          showHomePage: false,
          showDetailView: false,
          sortField: 'id',
          sortHeader: 'id',
          sortOrder: 'asc',
          rowHeight: role === 3 ? 33 : 60,
          viewMode: role === 3 ? 'grid' : 'quick',
          hasMessages: false,
          mathNumbers: null,
          settings,
          startingData: null
        },
        async () => {
          await this.FetchGraphData(tableid);
        },
      );
    } else {
      this.setState({ showHomePage: true });
    }
  };

  // used after configuring complex(new tables,relationships) column that requires columns to be reset to [] and reload from scratch.
  RefreshTable = async () => {
    const { viewMode } = this.state;
    let firstcolumn = 0;

    if (viewMode === 'grid') {
      const plugin =
        this.hotTableComponent.current &&
        this.hotTableComponent.current.hotInstance.getPlugin('autoColumnSize');
      if (plugin !== null) {
        firstcolumn = plugin.getFirstVisibleColumn();
      }
    }

    this.setState(
      {
        columns: [],
        lastDeletedColumn: firstcolumn,
        showColumns: false,
        refreshColumns: true,
        showHomePage: false,
        modalIsOpen: false,
      },
      async () => {
        await this.FetchGraphData(this.state.tableIndex);
      },
    );
  };

  delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  AnimatedLoadingDelay = (modalIsOpen) => {
    this.setState({ isDone: true });
    setTimeout(() => {
      this.setState({ isLoading: false, modalIsOpen });
    }, 1000);
  };

  RefreshRow = async (id, field = 'id', rowIndex, lookuptable) => {
    const {
      selectedItem,
      tableinfo,
      tableIndex,
      blockid,
      sortField,
      sortOrder,
      role,
      settings,
      viewMode,
    } = this.state;

    // 12-8-2021
    if (id === undefined || id.length === 0) return false;

    const filters = [
      {
        field: field,
        type: 'table', // 2 possible values ("table", "relation") indicates if id is relational lookup filter or "table" to search by id on table.
        displayfield: field,
        uitype: 100, // used if buildsqlfilter to apply filter on just this id.
        value: id,
        maxID: 0,
      },
    ];

    let payload = {
      tableinfo: tableinfo,
      tableid: tableIndex,
      blockid: blockid,
      limit: 1,
      offset: 0,
      filters: filters,
      sortfield: sortField,
      sortorder: sortOrder,
      role: role,
      callingComponent:'RefreshRow'
    };

    let tabledata = '';
    let recordcount = 0;
    let shapedData = '';
    let newsettings = { ...settings };

    if (tableinfo.tablename === 'Users') {
      payload.dbfunction = 'getZoneUsers';
      payload.useCentral = true;
      tabledata = await cloudrun.post('/executeQueryAPI', { payload });
      recordcount = tabledata[0].count;
      shapedData = tabledata[0].array;
    } else if (tableinfo.tablename === 'BlockUsers') {
      payload.blockid = blockid;
      tabledata = await cloudrun.post('/getBlockUsersTable', { payload });
      // console.log(tabledata)
      recordcount = tabledata.length;
      shapedData = tabledata;
    } else {
      tabledata = await cloudrun.post('/ExecuteTableQuery', { payload });
      shapedData = tabledata.rows;
      recordcount = parseInt(tabledata.count);
    }

    if (newsettings.data === null) {
      newsettings.data = shapedData;
    } else {
      newsettings.data[rowIndex] = shapedData[0];
    }

    /*  // 4-28-23 adding logic to that formula fields are 
      refreshed. If a relational field is updated, it will pass the lookuptable value
      for updates to single value fields, we will pass "formula" as the lookuptable name.
    */
    let changes = [];
    if (lookuptable !== null) {
      if(lookuptable!=='formula') { 
      tableinfo.columns
        .filter((col) => (col.uitype === 18 && col.source && col.source.lookuptable === lookuptable) || col.uitype===16)
        .map((col) => {
          changes.push([rowIndex, col.data, shapedData[0][col.data]]);
        });
      } else if (lookuptable==='formula') {
        tableinfo.columns
        .filter((col) => col.uitype === 16)
        .map((col) => {
          changes.push([rowIndex, col.data, shapedData[0][col.data]]);
        });
      }


      if (viewMode === 'grid') {
        this.hotTableComponent?.current?.hotInstance?.setDataAtRowProp(changes);
      }
    }


    // this.setState({ settings: newsettings,isLoading:false });

    //6-3-2022 added logic to reset the canEdit attribute on row once it's been updated.
    const updatedRow = shapedData[0];
    updatedRow.canEdit = selectedItem.canEdit;
    return updatedRow;
  };

  //11-18-24 Added this method to support new "refreshRecord" in detailview. If they are in root record
  //we will have it update grid row as well.
  refreshRowData = (rowIndex, newRow) =>{
    const { settings } = this.state;
    let newsettings = { ...settings };
    newsettings.data[rowIndex] = newRow;
  }

  // Used when dragging column contents to copy data and For Pasting data. Method will refresh the ids passed;
  // or if new rows are created, will append new rows to existing grid data.
  // if maxID=0, this indicates all rows existed and we just update the rows.
  // if maxID >0, this provides the max ID of recordset and we should query/append any new rows greater than this ide to grid.
  // 6-15-2022 changing logic such that "ids" is expected to always be an array, even if having single value
  // this will make logic internal easier to standardize on.
  RefreshRows = async (ids, appendRows = false, callingComponent = 'paste') => {
    let {
      currentMaxID,
      recordCount,
      role,
      viewMode,
      tableinfo,
      filterCount,
      viewingCount,
    } = this.state;

    const field = 'id';
    let newRecordCount = recordCount;
    let newFilterCount = filterCount;
    let newViewingCount = viewingCount;

    const filters = [
      {
        field: 'id',
        type: 'table', // 2 possible values ("table", "relation") indicates if id is relational lookup filter or "table" to search by id on table.
        displayfield: 'id',
        value: ids.join(),
        uitype: 100,
        maxID: appendRows ? currentMaxID : 0,
        appendRows: appendRows,
      },
    ];

    let payload = {
      tableinfo: this.state.tableinfo,
      tableid: this.state.tableIndex,
      blockid: this.state.blockid,
      filters: filters,
      limit: 2000,
      offset: 0,
      sortfield: this.state.sortField,
      sortorder: this.state.sortOrder,
      role: role,
    };

    // try{
    let tabledata = '';
    let recordcount = 0;
    let shapedData = '';
    let newsettings = { ...this.state.settings };
    if (this.state.tableinfo.tablename === 'Users') {
      payload.dbfunction = 'getZoneUsers';
      payload.useCentral = true;
      tabledata = await cloudrun.post('/executeQueryAPI', { payload });
      recordcount = tabledata[0].count;
      shapedData = tabledata[0].array;
    } else if (this.state.tableinfo.tablename === 'BlockUsers') {
      payload.blockid = this.state.blockid;
      tabledata = await cloudrun.post('/getBlockUsersTable', { payload });
      // console.log(tabledata)
      recordcount = tabledata.length;
      shapedData = tabledata;
    } else {
      tabledata = await cloudrun.post('/ExecuteTableQuery', { payload });
      shapedData = tabledata.rows;
      recordcount = parseInt(tabledata.count);
    }

    //If appendRows=false indicates update existing rows
    let newMaxID = currentMaxID;

    if (!appendRows) {
      shapedData.forEach((el) => {
        const rowindex = newsettings.data.findIndex((itm) => itm.id === el.id);
        if (rowindex !== -1) {
          newsettings.data[rowindex] = el;
        }
      });
    } else if (appendRows && newsettings.data === null) {
      newsettings.data = shapedData;
      newMaxID = shapedData.length;
    } else if (appendRows) {
      const ar = this.state.activeRow;
      if (ar !== undefined && callingComponent === 'addNewRecord') {
        newsettings.data.splice(ar + (ar === 0 ? 0 : 1), 0, shapedData[0]);
        if (viewMode === 'grid') {
          this.hotTableComponent.current.hotInstance.selectCell(ar + (ar === 0 ? 0 : 1), 1);
        }
      } else {
        newsettings.data = newsettings.data.concat(shapedData);
      }
      newMaxID = shapedData[shapedData.length - 1]['id'];
      if (callingComponent === 'paste') {
        newRecordCount = newRecordCount + shapedData.length;
        newFilterCount = newFilterCount + shapedData.length;
        newViewingCount = newViewingCount + shapedData.length;
      } else {
        newRecordCount = newRecordCount + 1;
        newFilterCount = newFilterCount + 1;
        newViewingCount = newViewingCount + 1;
      }

      newsettings.cells = (row, col) => {
        var cp = {};
        if (col === 0 && tableinfo.isSystemTable === undefined) {
          cp.className = tableinfo.showIDField ? 'firstcolumnID' : 'firstcolumn';
          cp.width = tableinfo.showIDField ? '40px' : '25px';
        }
        if (col === this.state.columns.length - 1 && tableinfo.isSystemTable === undefined) {
          cp.className = 'idcolumn';
        }
        // if(col ===0 && row===newsettings.data.length-1) {
        //   cp.className='lastrow';
        // }
        // if(row === newsettings.data.length-1) {
        //   cp.readOnly = true;
        // }
        return cp;
      };
    }

    this.setState({
      settings: newsettings,
      currentMaxID: newMaxID,
      filterCount: newFilterCount,
      recordCount: newRecordCount,
      viewingCount: newViewingCount,
    });
  };

  FetchGraphData = async (tableid, tableinfo, bookmarkid = 0, attributeFields) => {
    var result;
    let tbl = null;
    let tabsettings = {};
    let bookmarks = [];
    let { role, viewMode } = this.state;
    const { userInfo } = this.context;

    if (tableinfo !== undefined && tableinfo !== '' && bookmarkid === 0) {
      result = tableinfo;
      tabsettings = this.state.userTabSettings;
      bookmarks = this.state.bookmarks;
    } else {
      // 11-24-2020 added bookmarkid param so from same API we can load user table or bookmarked table.
      //11-15-2021 added role for security logic & list of users' blocks. Blocks is required to potentially remove relaional fields they should not see.
      //9-13-2023 added the "true" for new updateUserSettings. This ensures, when user clicks bookmark, their personal settings are changed to bookmarks.
      //in all other places where we use getUserTable, we will set this to false.
      tbl = await getUserTable(tableid, bookmarkid, role, userInfo.blocks,true);
      bookmarks = tbl?.bookmarks;
      if(role < tbl.tableinfo.security?.viewSpreadSheet) {
        bookmarks = tbl?.bookmarks.filter(itm=>itm.tabsettings.gridView !=='grid');
      }
      
      if (tbl.tabsettings !== undefined && tbl.tabsettings !==null) {
        tabsettings = tbl.tabsettings;
      }
      result = tbl.tableinfo;
    }

    //6-15-2022 set showBookmarBar if it's been set, otherwise use default logic.
    let showBar = false;
    if (tabsettings.showBookmarkBar !== undefined) {
      showBar = tabsettings.showBookmarkBar;
    } else if (bookmarks.length > 1 || (bookmarks.length === 1 && !bookmarks[0].isdefault)) {
      showBar = true;
    }

    // console.log(result)
    // 9-29-2020 if tabsettings, check rowHeight.If exists, update rowheight for this table
    let rHeight = viewMode === 'grid' ? 33 : 60;
    let gridView = viewMode; // user can change user preference of "grid" or "chat" viewMode
    let filters = []; // we save last filter per user per tab.
    let userSortField = '';
    let userSortOrder = '';

    if (tabsettings !== undefined) {
      if (tabsettings.rowHeight === 1) rHeight = 33;
      if (tabsettings.rowHeight === 2) rHeight = 60;
      if (tabsettings.rowHeight === 3) rHeight = 120;
      if (tabsettings.gridView !== undefined) {
        // 2-8-2022 was having issue with commented statement below. I can't recall why, if gridView is set, we can't just
        // use it...so I've made that change here. but should keep an eye out for scenarios where it doesn't work.
        gridView = tabsettings.gridView;
        // gridView = result.security===undefined || result.security.viewSpreadSheet <= role || tabsettings.gridView !=='grid' ? tabsettings.gridView : 'quick';
      }
      if (tabsettings.filters !== undefined && tabsettings.filters.length > 0) {
        filters = tabsettings.filters;
      }
      if (tabsettings.sortField !== undefined) {
        userSortField = tabsettings.sortField;
        userSortOrder = tabsettings.sortOrder;
      }
    }

    // 8-7-2020 check if table has message column and set state vartiable
    // this is used by action bar to show/hide view grid options.
    // 3-29-23 Update to check if field is private and user has access to the field.
    //6-15-23 Updated to also check email field and enableEmailMessages is true. 
    // Messages now includes both text and email messages.

    const canViewPrivate =result.security ==undefined || result.security.viewPrivate ==undefined || result.security.viewPrivate <= role;
    const messageIndex = result.columns.findIndex((el) => (el.uitype === 12 || (el.uitype===10 && el.enableEmailMessages)) && (el.isPrivate===undefined || canViewPrivate));
    const hiddenColCount = result.columns.filter((el) => el.hide === true).length;

    // this.setState({ tableinfo: result });
    if (result) {
      const jtablename = result.dbname;
      let finalcolumns = [];

      // if system table, hide this column as we don't allow them to add columsn to system tables.
      // Also exclude for "user" roles.
      if (result.isSystemTable === undefined && role === 3) {
        finalcolumns = result.columns.concat({
          data: 'id2',
          header: '<i class="large plus square icon"></i> Add field',
          displayfield: 'id2',
          readOnly: true,
          width: 120,
        });
      } else {
        // finalcolumns =result.columns
        finalcolumns = result.columns.concat({
          data: 'id2',
          header: '',
          displayfield: 'id2',
          readOnly: true,
          width: 1,
        });
      }

      const tblfields = finalcolumns;

      // 6-9-2020 Add recordname, icon to tableinfo, so can be passed in 1 object to detailview.
      result.recordname = this.state.recordname;
      result.icon = this.state.tableicon;

      // 5-13-2021 Added new param (attributeFields) that can be passed in to FetchQiery, which should
      // be additional fields that need to be updated in state. In trying to minimize the number of state updates
      // that can be passed in and then updated at same time.

      // The first place this is used is on the "addfield" logic. Here we get new Config field and lastDeletedColumn
      // and pass those in. this saves an state update and also ensure the last updated column can be used
      // to scroll the viewport correctly.

      // ************************ Start of attributeFields update  **************/

      const currentFields = {
        justtablename: jtablename,
        hasMessages: messageIndex !== -1,
        // hiddenColumnCount: hiddenColCount,
        sortField:
          result.defaultSort !== undefined
            ? result.defaultSort
            : userSortField !== ''
            ? userSortField
            : result.columns[0].data,
        sortOrder: userSortOrder !== '' ? userSortOrder : 'asc',
        sortHeader: userSortField !== '' ? tabsettings.sortHeader : 'id',
        columns: tblfields,
        tableinfo: result,
        filters: filters,
        offset: 0,
        rowHeight: rHeight,
        limit: result.limit !==undefined ? parseInt(result.limit) : 2000,
        viewMode: gridView,
        userTabSettings: tabsettings !== undefined ? tabsettings : {},
        bookmarks: bookmarks,
        showBookmarkBar: showBar,
        currentBookMarkId: bookmarkid===0 ? null : bookmarkid
      };

      let finalFields = {};
      if (attributeFields !== undefined) {
        finalFields = { ...currentFields, ...attributeFields };
      } else {
        finalFields = { ...currentFields };
      }

      this.setState(finalFields, async () => {
        await this.CallGraphQuery(this.state.offset);
      });

      // ************************ END of attibute field update  **************/
    }
  };


  CallGraphQuery = async (offset, scrollToEnd) => {

    const {
      tableinfo,
      tableIndex,
      sortField,
      sortOrder,
      filters,
      lastDeletedColumn,
      viewMode,
      role,
      limit,
      userTabSettings,
      isReload,
      blockid
    } = this.state;
    const { userInfo } = this.context;
   
    
    let tempLimit = userTabSettings.groups !==undefined && viewMode==='quick' ? 10000 : limit;
 
    let payload = {
      tableinfo: tableinfo,
      tableid: tableIndex,
      blockid: blockid,
      limit: tempLimit,
      offset: offset,
      filters: filters,
      sortfield: sortField,
      sortorder: sortOrder,
      sortMultipleFields:
        userTabSettings.sortMultipleFields !== undefined
          ? userTabSettings.sortMultipleFields
          : null,
      role: role,
      isReload: isReload,
      requiredFields: userTabSettings.groups // we pass gropu fields, which always  need to be indluded, even if hidden
    };

    // Separating out queries to tables, Users and Blocks. Going forward anytime we will use Grid to display
    // other system data, we will call specific query.
    let tabledata = {};
    let recordcount = 0;
    let totalRecords = 0;
    let maxID = 0;
    let shapedData = [];
    let zoneInfo = ''; // Added 2-7-2021 to use for Logic related to "Users" table and stopping new users from being activated/added if they were to exceed plan limit.
    

    if (tableinfo.tablename === 'Users') {
      payload.dbfunction = 'getZoneUsers';
      payload.useCentral = true;
      tabledata = await cloudrun.post('/getUsers', { payload });
      // tabledata = await getUsersAPI();

      recordcount = tabledata[0].count;
      totalRecords = tabledata[0].count;
      shapedData = tabledata[0].array;

      // 2-9-2021 We also need to grab # of userlicenses for this zone, to be able to
      // block new users from being activated / or added if it was to exceed their license limits.
      zoneInfo = await getZoneAPI(parseInt(Global.zoneid));
    } else if (tableinfo.tablename === 'BlockUsers') {
      payload.blockid = this.state.blockid;
      tabledata = await cloudrun.post('/getBlockUsersTable', { payload });
      recordcount = tabledata.length;
      totalRecords = tabledata.length;
      shapedData = tabledata;
    } else {
      tabledata = await cloudrun.post('/ExecuteTableQuery', { payload });
  
      //5-25-23 Adding check on whether data is returned and if not, show error toast.
      if(tabledata.rows !==undefined) {
        shapedData = tabledata.rows;
        recordcount = parseInt(tabledata.count);
        maxID = parseInt(tabledata.maxID); // 1-19-2021 adding maxID for table so that when we add new rows, we can query/append rows > maxID
        totalRecords = parseInt(tabledata.totalRecords);
        
      } else 
      {
        shapedData =[];
        recordcount=0;
        maxID=0
        totalRecords=0;
        const errormessage=`Tab error:${tabledata}.`
        let generalMessage='This can happen for a few reasons. If possible, edit the field causing the error to resolve the issue, or contact your administrator or GraceBlocks support for help. The data in this tab will load once this error is resolved.'
        if(tabledata.includes('timeout')) {
          generalMessage='The requested records are taking too long to load. Please use the filter option in the top right corner to apply additional criteria to limit the records being requested.'
        }
      

        toast.error(<div style={{margin:'10px'}}>{errormessage}<p><br/>{generalMessage}</p></div>, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose:false
        });
      }
    }

    // if is null, set to emptry array so we only see 1 min row. if null, shows 5
    if (shapedData === null) {
      shapedData = [];
    }
    
    //12-23-25 We've implemented logic to only return first 25 columns. Therefore, to keep ui
    //in sycn with data, we find columns that are marked visible, but aren't in dataset and the
    //mark them as hidden. Need to update UI for hidden columns separately.
    let visibleColumns=[];
    let trueHiddenCount=0;
    if(shapedData[0] !==undefined) {
      visibleColumns =Object.keys(shapedData[0]);
      tableinfo.columns.map(col=>{
        if(!visibleColumns.includes(col.data) && (col.hide===undefined ||col.hide===false)) {
          col.hide=true;
          trueHiddenCount =trueHiddenCount +1;
        } else if(col.hide===true) {
          trueHiddenCount =trueHiddenCount +1;
        }
      });
    } else {
      trueHiddenCount = tableinfo.columns.filter(itm=>itm.hide===true).length;
    }


    const newsettings = this.state.settings;
    newsettings.dropdownMenu.items = this.ColumnActionItems();

    // HIDDEN COLUM LOGIC
    let hiddenColumns = [];
    if (tableinfo.isSystemTable !== undefined) {
      hiddenColumns = [0];
    }

    // 3-8-2021 loop over columns and set hiddencolumns array where hide:true
    tableinfo.columns.map((el, idx) => {
      if (el.hide === true) hiddenColumns.push(idx);
    });

    //set hidden column array
    newsettings.hiddenColumns = { columns: hiddenColumns };
    // END HIDDEN COLUM LOGIC

    newsettings.cells = (row, col) => {
      var cp = {};

      if (col === 0 && tableinfo.isSystemTable === undefined) {
        cp.className = tableinfo.showIDField ? 'firstcolumnID' : 'firstcolumn';
        cp.width = tableinfo.showIDField ? '50px' : '25px';
      }

      //convert last "Add field" column to light grey. NOT for system table.
      if ((col === this.state.columns.length - 1 && tableinfo.isSystemTable === undefined)) {
        cp.className = 'idcolumn';
      }

      return cp;
    };

    // let totalset = [];
    if (offset > 0) {
      newsettings.data = newsettings.data.concat(shapedData);
    } else {
      newsettings.data = shapedData;
    }

    //12-3-24 I now return a "isSimpleView" which is true/false based on whther query fully ran within timeframe
    //or if it hit limit and re-ran with simplified fields. This is used to make grid read only in cases
    //where we are not returning full data. 
    newsettings.readOnly = tabledata.isSimpleView;

    if (recordcount > 99 && recordcount < 999) {
      newsettings.rowHeaderWidth = 33;
    } else if (recordcount > 999 && recordcount < 10000) {
      newsettings.rowHeaderWidth = 40;
    }

    // check if security is enabled and if the usre can delete rows.
    newsettings.contextMenu = {
      items:
        tableinfo.security === undefined || tableinfo.security.deleteRecords <= role
          ? {
              remove_row: {
                name: 'Delete row(s)', // Set custom text for predefined option
              },
              math: {
                name: 'Show calculations',
                callback: this.ShowCalculations,
              },
              record: {
                name: 'Copy record link',
                callback: this.Create_Record_URL
              }
            }
          : {
              math: {
                name: 'Show calculations',
                callback: this.ShowCalculations,
              },
            },
    };

    const tmpmodal = !!(this.state.showError || this.state.showFilters || this.state.modalIsOpen);

    //Load Forms for this table
    const formResult = await getUserForms(tableIndex);
    if (role === 3) {
      formResult.unshift({
        key: 'edit',
        icon: 'pencil alternate',
        text: 'Manage web forms',
        value: 'edit',
      });
    }

    //Check to see if security needs to be enabled for this table.
    //This means use-case where user has access to rows which they cannot edit.
    //11-15-23 adding check for editMyRecords
    const useSecurity =
      tableinfo.security !== undefined &&
      ((tableinfo.security.allRecords < tableinfo.security.editAnyRecord &&
        tableinfo.security.allRecords === 0 &&
        role === 0) ||
        (tableinfo.security.allRecords < tableinfo.security.editAnyRecord &&
          tableinfo.security.allRecords === 1 &&
          role === 1) ||
        (tableinfo.security.allRecords < tableinfo.security.editAnyRecord &&
          tableinfo.security.allRecords === 0 &&
          tableinfo.security.editAnyRecord === 2 &&
          role === 1) ||
        (tableinfo.security.myRecords < tableinfo.security.editOthersRecord &&
          tableinfo.security.myRecords === 0 &&
          role === 0) ||
        (tableinfo.security.myRecords < tableinfo.security.editOthersRecord &&
          tableinfo.security.myRecords === 0 &&
          role === 1 &&
          tableinfo.security.editOthersRecord === 2) ||
        (tableinfo.security.myRecords < tableinfo.security.editOthersRecord &&
          tableinfo.security.myRecords === 1 &&
          role === 1)) ||
          tableinfo.security?.editMyRecord > role
          ;


    this.setState({
      tableinfo,
      hiddenColumnCount:trueHiddenCount,
      settings: newsettings,
      forms: formResult,
      modalIsOpen: tmpmodal,
      recordCount: totalRecords,
      currentMaxID: isNaN(maxID) ? 0 : maxID,
      filterCount: recordcount,
      viewingCount: offset === 0 ? Math.min(recordcount, tempLimit) : Math.min(offset + limit, recordcount),
      // viewingCount: filters.length > 0 ? recordcount : offset === 0 ? Math.min(recordcount, tempLimit) : Math.min(offset + limit, recordcount),
      filters,
      showColumns: true, //determines if grid is rendered.
      refreshColumns: true, //determines if columns are set to
      zoneInfo: zoneInfo,
      useSecurity: useSecurity,
      isLoading: false,
      isReload: false,
      isSimpleView: tabledata.isSimpleView
    }); // put back

    // await this.AnimatedLoadingDelay()
    if (offset === 0) {
      this.getHeight();

      this.hotTableComponent.current &&
        (await this.hotTableComponent.current.hotInstance.updateSettings({
          // height:
          //   this.state.filters.length === 0 ? window.innerHeight - 155 : window.innerHeight - 170,
          rowHeights: this.state.rowHeight,
        }));
    }

    if (lastDeletedColumn !== 0) {
      this.hotTableComponent.current &&
        this.hotTableComponent.current.hotInstance.scrollViewportTo(null, lastDeletedColumn);
    }

    // curerntrow logic
    let currentRow = `tbl_${this.state.tableIndex}_currentRow`;

    if (scrollToEnd !== undefined && viewMode === 'grid') {
      this.hotTableComponent.current.hotInstance.scrollViewportTo(scrollToEnd, 0);
    } else if (userInfo[currentRow] !== undefined && viewMode === 'grid') {
      this.hotTableComponent.current.hotInstance.scrollViewportTo(
        userInfo[currentRow],
        lastDeletedColumn,
      );
    }
  };

  // this is used to create list of drop down menu options on columns.
  // For system tables, we remove the abiliy to edit the fields, insert new fields, delete,etc
  // this will also be user a general "user" so they don't have ability to modify tables.
  ColumnActionItems = () => {
    const { tableinfo, role } = this.state;

    const items = [
      {
        key: 'customize',
        name: '<i class="edit icon"></i> Edit field properties',
        callback: async () => {
          const data = this.hotTableComponent.current.hotInstance.getSelected();
          this.ConfigureColumn(data);
        },
        //  disabled: false
      },
      {
        key: 'filter',
        name: '<i class="filter icon"></i> Apply search filter',
        callback: async () => {
          // console.log(this.hotTableComponent.current.getDataAtCol(this.hotTableComponent.current.getSelected()[1]))
          const data = await this.hotTableComponent.current.hotInstance.getSelected();
          this.FilterData(data);
        },
      },
      {
        key: 'sortup',
        name: '<i class="sort amount down icon"></i> Sort ascending',
        callback: async () => {
          const data = await this.hotTableComponent.current.hotInstance.getSelected();
          this.setSortField(data, 'asc');
        },
      },
      {
        key: 'sortdown',
        name: '<i class="sort amount up icon"></i> Sort descending',
        callback: async () => {
          const data = await this.hotTableComponent.current.hotInstance.getSelected();
          this.setSortField(data, 'desc');
        },
      },
      {
        key: 'hidecolumn',
        name: '<i class="eye slash icon"></i> Hide column',
        callback: async () => {
          const data = await this.hotTableComponent.current.hotInstance.getSelected();
          this.updatehiddenColumns(data, true);
        },
      },
      {
        key: 'showcolumns',
        name: '<i class="eye icon"></i>Show hidden columns',
        callback: async () => {
          const data = await this.hotTableComponent.current.hotInstance.getSelected();
          this.updatehiddenColumns(data, false);
        },
      },
      {
        key: 'duplicate',
        name: '<i class="copy icon"></i> Duplicate field',
        callback: async () => {
          const data = this.hotTableComponent.current.hotInstance.getSelected();
          // 8-11-23 Changingn logic so that field selected to duplicate is set as duplicateField
          // in state. I added new param to pass to addField. This will save the dup field
          // and the columnconfig will read/use this data to fetch field settings to propopulate
          // fields.
          this.copyField(data);
          // this.addfield('', data, 'right', true);  8/12/23 reverting back to original, as I didn;t fully implement new logic.
        },
        //  disabled: false
      },
      {
        key: 'fieldleft',
        name: '<i class="arrow left icon"></i> Insert field to left',
        callback: async () => {
          const data = await this.hotTableComponent.current.hotInstance.getSelected();
          this.addfield('', data, 'left');
        },
      },
      {
        key: 'fieldright',
        name: '<i class="arrow right icon"></i> Insert field to right',
        callback: async () => {
          const data = await this.hotTableComponent.current.hotInstance.getSelected();
          this.addfield('', data, 'right');
        },
      },
      {
        key: 'delete',
        name: '<i class="trash alternate icon"></i> Delete field',
        callback: async () => {
          const data = await this.hotTableComponent.current.hotInstance.getSelected();
          const colname = this.hotTableComponent.current.hotInstance.colToProp(data[0][1]);
          const response = this.VerifyColumnCanBeDeleted(colname, data[0][1]);
          if (response === 'success') {
            this.confirmAction(data);
          } else {
            this.ShowError(response);
          }
          // const data = await this.hotTableComponent.current.hotInstance.getSelected();
          // this.addFieldIndexLocal(data);
        },
      },
      {
        key: 'addindex',
        name: '<i class="cogs icon"></i> Add field index',
        callback: async () => {
          const data = await this.hotTableComponent.current.hotInstance.getSelected();
          this.addFieldIndexLocal(data);
        },
      },
    ];

    if (tableinfo.isSystemTable || role !== 3) {
      return !tableinfo.hasHiddenColumns ? items.slice(1, 5) : items.slice(1, 6);
      // return tempItems.slice(1,6)
    } else {
      const tempItems = [];
      items.map((el) => {
        if (el.key !== 'showcolumns' || (el.key === 'showcolumns' && tableinfo.hasHiddenColumns)) {
          tempItems.push(el);
        }
      });
      return tempItems;
    }
  };

  VerifyColumnCanBeDeleted = (field, colIndex = 0) => {
    // if(field==='field_1') return "The first column has special powers, and as such it can not be deleted."
    if (colIndex === 0) {
      return 'You cannot delete the System ID field';
    }
    const nodeindex = this.state.tableinfo.columns.findIndex((itm) => itm.phonefield === field || itm.altPhoneField===field);
    if (nodeindex !== -1)
      return 'This field is a designated phone field in the messaging thread in this tab.  You must update the messaging thread with a new phone field before you can remove this one.';
    return 'success';
  };

  Create_Record_URL = () => {
    const {zoneid,blockid,tableIndex,settings,selectedRowIndex} = this.state;
    var selected = this.hotTableComponent.current.hotInstance.getSelected();
    
    const id = settings.data[parseInt(selected[0,0])].id
    CreateRecordUrl(zoneid,blockid,tableIndex,id,this.ShowError);
  }

  NextPrevHandler = async (direction) => {
    const { viewMode, selectedRowIndex,groupids,settings,selected_id,filterCount } = this.state;
  
    let nextPrevBool ={};

    let currentRowIndex=selectedRowIndex;
    if(viewMode==='quick'){
      currentRowIndex = settings.data.findIndex(el=>el.id===selected_id);
    }

    // 11-19-2021 adding canEdit field, to be used to determine if record can be edited by user
    let canEdit = true;
    let selectedItem =null;
    let next_selected_id =null;

    //get next/prev index of record to view. This index is used to get selectedItem & checksecurity.
    if (direction === 'next') {
      if (currentRowIndex + 1 < filterCount) {
        currentRowIndex = currentRowIndex + 1;
        nextPrevBool={prev:currentRowIndex-1>=0,next:currentRowIndex+1<filterCount}
      }
    } else {
      if (currentRowIndex > 0) {
         currentRowIndex = currentRowIndex - 1;
         nextPrevBool={prev:currentRowIndex-1>=0,next:currentRowIndex+1<filterCount}
      }
    }
    
    next_selected_id = settings.data[currentRowIndex].id; // used for quickview, no groups.

    
    if(viewMode==='quick' && groupids) {
      const groupIdx = groupids.findIndex(el=>el===selected_id)
      if(direction==='next') {
        if(groupIdx+1 < groupids.length){
          next_selected_id = groupids[groupIdx+1]
          nextPrevBool={prev:groupIdx>=0,next:groupIdx+2<groupids.length}
        } else {
          return false
        }
      } else if(direction==='prev') {
        if(groupIdx > 0) {
          next_selected_id = groupids[groupIdx-1]
          nextPrevBool={prev:groupIdx-2>=0,next:groupIdx<groupids.length}
        } else {
          return false
        }
      }
      currentRowIndex = settings.data.findIndex(el=>el.id===next_selected_id)
    }

    // console.log(nextPrevBool)

    selectedItem=this.state.settings.data[currentRowIndex];
    if (this.state.useSecurity) {
      canEdit = this.checkRowSecurity(currentRowIndex);
    }
    selectedItem.canEdit=canEdit;

    this.setState({
      selectedItem,
      selectedRowIndex: currentRowIndex,
      selected_id: next_selected_id,
      nextPrevBool
    });

    // 12-15-2021 only update the grid if we are in grid mode. Chat view doesn't have this need
    // and quick view will need separate call to highlight the row.
    if (viewMode === 'grid') {
      this.hotTableComponent.current.hotInstance.selectCell(currentRowIndex, 0);
    }

  };

  ExportTableData = async () => {
    const { filterCount,role,tableinfo,tablename,tabname } = this.state;
    this.setState({ isLoading: true, showColumns: false });

    if (filterCount > 10000) {
      this.ShowError(
        'Apply one or more filters to reduce the recordset. No more than than 10,000 records can be downloaded in a single transaction.',
      );
      return false;
    }

    const canUnmask = tableinfo.security===undefined || tableinfo.security.unmask ===undefined || tableinfo.security.unmask < role;

    const result = await ExportRequest(
      this.state.tableinfo,
      this.state.tableIndex,
      tablename,
      tabname,
      this.state.filters,
      this.state.offset,
      this.state.blockid,
      canUnmask,
      role
    );

    this.setState({
      exportedData: result,
      showExportButton: true,
      modalIsOpen: true,
      y: window.innerHeight / 6,
      x: window.innerWidth / 2.5,
      modalWidth: 300,
      modalHeight: 300,
      isLoading: false,
      showColumns: true,
    });

    this.getHeight();

    this.hotTableComponent.current &&
      (await this.hotTableComponent.current.hotInstance.updateSettings({
        // height:
        //   this.state.filters.length === 0 ? window.innerHeight - 155 : window.innerHeight - 170,
        rowHeights: this.state.rowHeight,
      }));
  };

  toggleBookMarks = () => {
    const { showBookmarks } = this.state;
    this.setState({
      showBookmarks: !showBookmarks,
      modalIsOpen: true,
      modalWidth: 900,
      modalHeight: 600,
      y: window.innerHeight / 6,
      x: window.innerWidth - 950,
    });
  };

  toggleColumnManager = () => {
    const { showColumnManager } = this.state;
    this.setState({
      showColumnManager: !showColumnManager,
      modalIsOpen: true,
      modalWidth: 950,
      modalHeight: 600, //not used, modal sets to full height
      y: window.innerHeight / 6,
      x: window.innerWidth / 4,
    });
  };

  toggleAddUsers = () => {
    const { showAddUsers } = this.state;
    this.setState({
      showAddUsers: !showAddUsers,
      modalIsOpen: true,
      modalWidth: 600,
      modalHeight: 600,
      y: window.innerHeight / 6,
      x: window.innerWidth / 4,
    });
  };

  toggleShareManager = () => {
    const { showShareManager, view, selectedCoords, settings } = this.state;

    const newids = [];
    // 5-26-23 added the check that the first selected cell (selectedCoords.selected[0][0]) !=-1
    // which happens when you sort a column, etc. In that case, just show bookmarks to use for share.
    if (
      selectedCoords != null &&
      selectedCoords !== undefined &&
      (
        selectedCoords.selected.length > 1 ||
        selectedCoords.selected[0][0] !== selectedCoords.selected[0][2]
      )
      && selectedCoords.selected[0][0] !==-1
    ) {
      for (let x = 0; x < selectedCoords.selected.length; x += 1) {
        const item = selectedCoords.selected[x];
        const first = item[0] < item[2] ? item[0] : item[2];
        const second = item[0] < item[2] ? item[2] : item[0];
        for (let y = first; y <= second; y += 1) {
          newids.push(settings.data[y].id);
        }
      }
    }

    this.setState({
      showShareManager: !showShareManager,
      modalIsOpen: true,
      modalWidth: 510,
      modalHeight: 600,
      y: window.innerHeight / 6,
      x: window.innerWidth - 850,
      sharedRecordIds: newids.length > 0 ? newids : null,
    });
  };

  //6-10-2022 removing the right click action of sharing records, as I was able to incorporate
  //this into the main selection process by saving selection to state without using state update, which keeps
  //performance really good.

  // shareMultipleRecords =() =>{
  //   var selected =this.hotTableComponent.current.hotInstance.getSelected();

  //   const ids=[];

  //   for (let x = 0; x < selected.length; x += 1) {
  //     const item = selected[x];
  //     let arrayofNumbers= this.hotTableComponent.current.hotInstance.getData(...item);
  //     arrayofNumbers.map(row =>{
  //       ids.push(row[0])
  //     })
  //   }

  //   this.toggleShareManager(ids);
  //   this.hotTableComponent.current.hotInstance.deselectCell();

  // }

  loadnewData = (resultdata) => {
    // this.hotTableComponent.loadData([['new', 'data']]);
    this.hotTableComponent.current.hotInstance.loadData(resultdata);
  };

  UpdateMultipleRows = async (updates, updateGrid) => {
    const { schema, tableIndex, tableinfo, uid } = this.state;
    await UpdateRows(
      schema,
      tableIndex,
      updates,
      tableinfo,
      uid,
      updateGrid,
      this.RefreshRows,
      this.updateRowID,
    );
  };

  //added 5-23-2022 to calcuatlee min,max,count,sum,avg of highlighted number fields.
  ShowCalculations = async () => {
    const { tableinfo } = this.state;

    var selected = this.hotTableComponent.current.hotInstance.getSelected();
    let allValidColumns = true;
    const data = [];
    const columns = [];
    //Check all selection columns and check if they are a number or a lookup of a number. if not, show message,
    //otherwise add to column array, which is used to determine if we should apply a column format to the number.

    selected.map((s) => {
      for (let i = s[1]; i <= s[3]; i++) {
        var colname = this.hotTableComponent.current.hotInstance.colToProp(i);

        let idx = tableinfo.columns.findIndex((el) => el.data === colname);
        if (idx !== -1) {
          if (
            tableinfo.columns[idx].uitype === 23 ||   tableinfo.columns[idx].fieldType==='numeric' ||
            (tableinfo.columns[idx].uitype === 18 &&
              tableinfo.columns[idx].source.lookupuitype === 23)
          ) {
            columns.push(tableinfo.columns[idx]);
          } else {
            allValidColumns = false;
          }
        }
      }
    });


    if (!allValidColumns) {
      this.ShowError('This function only works on numeric fields.');
      return false;
    } else {
      let newtotal = 0;
      let newUniqueNumbers = [];

      for (let x = 0; x < selected.length; x += 1) {
        const item = selected[x];
        let arrayofNumbers = this.hotTableComponent.current.hotInstance.getData(...item);
    
        arrayofNumbers.map((row) => {
          row
            .filter((el) => el !== null)
            .map((val) => {
              if (Array.isArray(val)) {
                const addednumber = val.map((el) => el.data).reduce((a, b) => a+b, 0);
                newtotal += addednumber;
                newUniqueNumbers.push(addednumber);
              } else {
                newtotal += parseFloat(val);
                newUniqueNumbers.push(parseFloat(val));
              }
            });
        });
      }
     

      // 8-1-2022 adding Median calculation.
      const medianCalc = (numbers) => {
        const sorted = Array.from(numbers).sort((a, b) => a - b);
        const middle = Math.floor(sorted.length / 2);

        if (sorted.length % 2 === 0) {
          return (sorted[middle - 1] + sorted[middle]) / 2;
        }

        return sorted[middle];
      };

      //if there is only one column, this means we should use the number formatting from that column,
      //otherwise, we just show standard number output.
      let avg = (newtotal / newUniqueNumbers.length).toFixed(2);
      let minNumber = Math.min(...newUniqueNumbers).toFixed(2);
      let maxNumber = Math.max(...newUniqueNumbers).toFixed(2);
      newtotal = newtotal.toFixed(2);
      let median = medianCalc(newUniqueNumbers).toFixed(2);

      if (columns.length === 1 && columns[0].numericFormat !== undefined) {
        const col = columns[0];
        numbro.setLanguage(col.numericFormat.culture);
        avg = numbro(avg).format(col.numericFormat.pattern);
        newtotal = numbro(newtotal).format(col.numericFormat.pattern);
        minNumber = numbro(minNumber).format(col.numericFormat.pattern);
        maxNumber = numbro(maxNumber).format(col.numericFormat.pattern);
        median = numbro(median).format(col.numericFormat.pattern);
      }

      const numbers = {
        count: newUniqueNumbers.length,
        total: newtotal,
        avg: avg,
        minNumber,
        maxNumber,
        median,
      };
      this.setState({ mathNumbers: numbers });
    }
  };

  copyField = async (data) => {
    const { tableIndex, tableinfo, blockid, tablename } = this.state;
    // if(data[0][1]===0) {
    //   return false;
    // }
    var colname = this.hotTableComponent.current.hotInstance.colToProp(data[0][1]);
    const idx = tableinfo.columns.findIndex((el) => el.data === colname);
    if (idx !== -1) {
      //check it's not a system field, you cannot duplicate system fields, counter, message, id or self-join fields
      const col = tableinfo.columns[idx];
      if (
        (![17, 19, 20, 24, 25, 12].includes(col.uitype) && col.data !== 'id') ||
        (col.uitype === 1 && col.source && col.source.lookuptable !== tableinfo.dbname)
      ) {
        const metaData = tableinfo.columns[idx];
        await copyField(blockid, tableIndex, metaData, tablename);
        await this.RefreshTable();
      } else {
        this.ShowError('You cannot duplicate system fields or the message field.');
      }
    }
  };

  // 12-4-24 added to allow users to add indexes to specific columns.
  addFieldIndexLocal = async (data) =>{
    const { tableIndex, tableinfo, blockid, tablename } = this.state;
    var colname = this.hotTableComponent.current.hotInstance.colToProp(data[0][1]);
    const idx = tableinfo.columns.findIndex((el) => el.data === colname);
    const col = tableinfo.columns[idx];

    if([2,3,5,9,10,11,13,14,,19,20,22,23,24,25].includes(col.uitype)) {
      const result = await addFieldIndex(tableIndex,colname);
      if(result==='success') {
        toast.info(<div style={{margin:'10px'}}>Index successfully added!</div>, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        });
      } else {
        toast.info(<div style={{margin:'10px'}}>Index Could not be created. </div>, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 5000,
        });
      }
    } else {
      toast.info(<div style={{margin:'10px'}}>A field index is not supported for this field type but can improve query speed when applied to text, RTF, numeric, date, counter, phone, email, and URL fields.</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
      return false;
    }
  }

  ConfigureColumn = async (data) => {
    // var colfield = this.state.columns[data[0][1]].data;
    // var header = this.state.columns[data[0][1]].header;
    if (data===undefined || data[0][1] === 0) {
      return false;
    }
    var colname = this.hotTableComponent.current.hotInstance.colToProp(data[0][1]);

    if(this.serviceCheck('requiredFields',colname)) {
      return false;
    }

    let colfield = '';
    let header = '';

    var rowIndex = this.state.columns.findIndex((el) => el.data === colname);
    if (rowIndex !== -1) {
      colfield = this.state.columns[rowIndex].data;
      header = this.state.columns[rowIndex].header;
    }
    /*  8-11-23 added duplicateField:null. This is to support new duplicateField
    logic which when chosen, will update duplicateField to the selected field. Therefore
    when just configuring or adding a new field, we need to set to null, as this value
    will be passed into ColumnConfig. If it's present, the columnconfig will use the settings
    to preconfigure the field properties. 
    */
    this.setState({
      columnconfigfield: colfield,
      showEditField: true,
      tmpHeader: header,
      modalIsOpen: true,
      isLoading: false,
      selectedColumnIndex: data[0][1],
      modalHeight: 600,
      modalWidth: 500,
      duplicateField: null  
    });
    await this.hotTableComponent.current.hotInstance.destroyEditor(true);
    // this.hotTableComponent.current.hotInstance.clear();
    await this.hotTableComponent.current.hotInstance.deselectCell();
  };

  RenameColumn = async (colfield, colheader, uitype) => {
    //  update local cache header.
    const copyofdata = [...this.state.columns];
    const rowIndex = copyofdata.findIndex((el) => el.data === colfield);
    if (rowIndex !== -1) {
      copyofdata[rowIndex].header = colheader;
      this.setState({ columns: copyofdata }, () => this.closeModalandUpdate());
    }
  };

  closeModalandUpdate = async () => {
    this.closeModal();
    this.FetchGraphData(this.state.tableIndex);
  };

  // added 5-14-2021 to use with Columnmanager. Must refresh table after column changes.
  closeModalandRefreshTable = async () => {
    this.closeModal();
    await this.RefreshTable();
  };

  // 1-12-2021 Currently used for the "Add new record" modal. In this case, it adds records and we
  // want to refresh the grid. The 3rd param on callgraphquery indicates to scroll viewport to last row.

  closeModalandRefresh = async (id, isNew = true) => {
    const { recordCount, sortOrder, viewMode,searchParams,settings } = this.state;
    const updateBaseRow = searchParams?.updateBaseRow !==undefined;

    let finalId =id;
    let finalIsNew = isNew;

    if(viewMode==='grid' && updateBaseRow) {
      var selected = this.hotTableComponent.current.hotInstance.getSelected();
      const id = settings.data[parseInt(selected[0,0])].id
      finalId = id;
      finalIsNew=false;
    }

    this.setState(
      {
        isLoading: true,
        showNewRecord: false,
        showFormView: false,
        sortOrder: recordCount < 501 && sortOrder === 'asc' ? 'asc' : 'desc',
        searchParams:null //11-2-23 when completing form, remove searchparam. This is used for updating of forms.
      },
      async () => {
        //  if(updateRow) {
          await this.RefreshRows([finalId], finalIsNew, 'addNewRecord'); // changed on 4-21-2021 to use refresh instead of rerunning full table query. Better experience, but will put record at end of list.
        //  }

        //11-2-23 Added to support updating of a form. We don't want to lose row selection.
        // when they click to update a form, we turn off this setting. upon completion, here, we want to re-enable
        if (viewMode === 'grid') {
          this.hotTableComponent.current.hotInstance.updateSettings({
            outsideClickDeselects: true,
          });
        }
      },
    );

    this.AnimatedLoadingDelay();
  };


  // 6-15-2022 For new Bookmark bar feature, if user opens up bookmarks and makes changes
  //upon closing, we want to refresh bookmarks bar with updated bookmarks.
  closeBookMarksAndRefresh = async (items) => {
    this.setState({
      modalIsOpen: false,
      showError: false,
      showExportButton: false,
      modalWidth: 350,
      modalHeight: 400,
      showFilters: false,
      showBookmarks: false,
      showNewRecord: false,
      showEditField: false,
      showManageWebForms: false,
      showColumnManager: false,
      showAddUsers: false,
      showShareManager: false,
      sharedRecordIds: null,
      showFormView: false,
      bookmarks: items,
    });
  };

  addfield = async (column, celllocation, direction,isDuplicateField=false) => {
    if (celllocation !== undefined && celllocation[0][1] === 0 && direction === 'left') {
      return false;
    }

    // if isDuplicateField is true, this indicates they selected "duplicate field" option.
    //here we add new field, but store field selcted so it's field properties can be prefilled
    //in on new column config editor.
    let tempDupfield=null;
    if(isDuplicateField) {
      tempDupfield = this.hotTableComponent.current.hotInstance.colToProp(celllocation[0][1]);
    }

    const plugin = this.hotTableComponent.current.hotInstance.getPlugin('autoColumnSize');
    const firstcolumn = plugin.getFirstVisibleColumn();

    this.setState({
      isLoading: true,
      isDone: false,
      showEditField: true,
      modalIsOpen: true,
      modalWidth: 500,
      modalHeight: 600,
      columns: [],
      showColumns: false,
      duplicateField: tempDupfield
    });


    const { tableinfo } = this.state;
    const payload = { tableid: this.state.tableIndex };
    let newfield = await cloudrun.post('/addDirectField', { payload });
    let colposition = 0;
    

    tableinfo.columns.push({
      data: newfield,
      displayfield: newfield,
      header: newfield,
      uitype: 2,
      width: 125,
      renderer: 'TextRender',
    });
    let newcolindex = 0;

    // Celllocation indicates action was insert col left/right.
    if (celllocation !== undefined) {
      const colindex = celllocation[0][1];
      newcolindex = direction === 'left' ? colindex : colindex + 1;
      colposition = newcolindex;
    } else {
      colposition = column + 1;
    }

    // Get Updated tableinfo, re-order column to proper index /save.
    // const tbl1 = await getTableAPI(this.state.tableIndex);

    /* 1-28-2021. The /addfield server logic appends node on back-end. We want to change logic
        to use current users table definition instead of loading raw metadata again. 
        
        Here we will manually append new node to current table info. We will then save this to 
        current users TableUsers columnsettings so that the table should retain the same column layout and formatting.
      */

    // const updatedTable = tbl1.tableinfo;
    const updatedTable = tableinfo;
    let newColumns = '';
    const rowindex = updatedTable.columns.findIndex((el) => el.data === newfield);
    if (rowindex !== -1) {
      if (celllocation !== undefined) {
        newColumns = this.arraymove(updatedTable.columns, rowindex, newcolindex);
        updatedTable.columns = newColumns;
      } else {
        newColumns = tableinfo.columns;
      }

      await UpdateTableColumnOrderAPI(this.state.tableIndex, newColumns);
      // await updateTableInfoAPI(this.state.tableIndex, updatedTable);

      const updateAttribues = {
        selectedColumnIndex: colposition,
        lastDeletedColumn: firstcolumn,
        columnconfigfield: newfield,
        tmpHeader: newfield[0].toUpperCase() + newfield.substring(1),
      };

      await this.FetchGraphData(this.state.tableIndex, updatedTable, null, updateAttribues);
    }
    await this.AnimatedLoadingDelay(true);
  };

  arraymove = (arr, fromIndex, toIndex) => {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  };

  ShowError = (error) => {
    toast.info(<div style={{margin:'10px'}}>{error}</div>, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
    });
  };

  TitleDisplay = (header, callout, width) => {
    // return `<div>${header}</div>`;
    return `<span title="${callout}"><i class="info circle icon"></i>${this.GetHeaderText(
      header,
      width - 15,
    )}</span>`;
  };

  copyToClipboard = (content) => {
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  GetHeaderText = (header, width) => {
    const newheader = header === 'System ID' ? 'ID' : header;
    if (newheader.length > Math.ceil(width / 8)) {
      return `<span title="${newheader}">${newheader.slice(0, Math.ceil(width / 8) - 4)}...</span>`;
    } else {
      return newheader;
    }
  };

  createColumns = () => {
    const {
      columns,
      color,
      tableIndex,
      refreshColumns,
      block,
      zoneid,
      role,
      tableinfo,
      useSecurity,
      isPublicZoneBlock,
      showBookmarkBar,
    } = this.state;
    // console.log(columns) Check why this is firing twice per load.
    let tempcolumns = [];

    if (this.hotTableComponent.current !== null) {
      this.hotTableComponent.current.columnSettings = [];

      this.plugling = this.hotTableComponent.current.hotInstance.getPlugin('manualColumnResize');
      this.state.columns.forEach((el, i) => {
        this.plugling.clearManualSize(i);
      });
    }

    if (refreshColumns) {
      if (this.state.showColumns) {
        return columns.map((el) => (
          <HotColumn
            key={el.data}
            title={
              el.callout
                ? this.TitleDisplay(el.header, el.callout, el.width)
                : el.data !== 'id2'
                ? this.GetHeaderText(el.header, el.width)
                : el.header
            }
            header={el.header}
            data={el.data}
            isVisible={false}
            displayfield={el.displayfield}
            type={el.type}
            numericFormat={el.numericFormat}
            source={el.source}
            querytxt={el.querytxt}
            useSecurity={el.useSecurity}
            uitype={el.uitype}
            selectmode={el.lookup}
            readOnly={
              block.isMaxRowsExceeded ||
              this.state.settings.readOnly===true ||
              isPublicZoneBlock ||
              (el.uitype === 18 && el.source && el.source.lookupuitype !== 4) ||
              el.uitype===16 || el.data==='email' || el.data==='Users' ||
              useSecurity
                ? true
                : el.readOnly
            }
            dateFormatEdit={el.dateFormat}
            includeTime={el.includeTime}
            enableColors={el.enableColors}
            correctFormat
            className={el.className}
            width={el.width}
          >
            {el.renderer === 'DisplayMultiple' ? (
              <DisplayMultiple
                hot-renderer
                uitype={el.uitype}
                field={el.displayfield}
                specialfunction={el.specialfunction}
                cellHeight={this.state.rowHeight}
                cellWidth={el.width}
                dateFormat={el.dateFormat}
                enableColors={el.enableColors}
                UTC={el.UTC}
                numericFormat={el.numericFormat}
              />
            ) : null}
            {el.renderer === 'UserRender' ? (
              <UserRender
                hot-renderer
                uitype={el.uitype}
                field={el.displayfield}
                cellHeight={this.state.rowHeight}
                cellWidth={el.width}
              />
            ) : null}
            {/* {el.editor === 'UserEditor' ? (
              <UserEditor
                hot-editor
                uitype={el.uitype}
                blockid={this.state.blockid}
                field={el.displayfield}
                cellHeight={this.state.rowHeight}
                cellWidth={el.width}
                filterType={el.filterType}
              />
            ) : null} */}
            {el.renderandedit === 'Collaborator' ? (
              <Collaborator
                hot-editor
                hot-renderer
                uitype={el.uitype}
                color={color}
                blockid={this.state.blockid}
                GetRowData={this.GetRowData}
                field={el.displayfield}
                userType={el.userType}
                definedUsers={el.definedUsers}
                cellHeight={this.state.rowHeight}
                cellWidth={el.width}
                filterType={el.filterType}
              />
            ) : null}
            {el.renderandedit === 'TableLink' ? (
              <TableLink
                hot-editor
                hot-renderer
                GetRowData={this.GetRowData}
                color={color}
                lookuptable={el.source.lookuptable}
                role={role}
                bookmarkid={el.bookmarkName}
                useBookmark={el.useBookmark}
                lookupfield={el.source.lookupfield}
                blockid={el.source.blockid}
                selectmode={el.lookup}
                isSubTable={el.isSubTable}
                cellHeight={this.state.rowHeight}
                cellWidth={el.width}
                tableid={this.state.tableIndex}
              />
            ) : null}
            {el.renderer === 'AttachmentsRenderer' ? (
              <AttachmentsRenderer
                hot-editor
                hot-renderer
                GetRowData={this.GetRowData}
                allowCategories={el.allowCategories}
                lookuptableid={
                  el.jointype === 'relational-lookup'
                    ? el.source.lookuptableid
                    : el.jointype === 'relational-lookup-lookup'
                    ? el.source.grouptable.replace('tbl_', '')
                    : null
                }
                lookupfield={
                  el.jointype === 'relational-lookup'
                    ? el.source.lookupfield
                    : el.jointype === 'relational-lookup-lookup'
                    ? el.source.finallookupfield
                    : null
                }
                zoneid={zoneid}
                color={color}
                cellHeight={this.state.rowHeight}
                cellWidth={el.width}
                tableid={this.state.tableIndex}
                // RefreshRow={this.RefreshRow}
              />
            ) : null}
            {el.renderandedit === 'BlockUsersRenderer' ? (
              <BlockUsersRenderer
                hot-editor
                hot-renderer
                GetRowData={this.GetRowData}
                color={color}
                cellHeight={this.state.rowHeight}
                cellWidth={el.width}
                RefreshRow={this.RefreshRow}
              />
            ) : null}
            {el.renderer === 'MessageRender' ? (
              <MessageRender
                hot-renderer
                GetRowData={this.GetRowData}
                color={color}
                cellHeight={this.state.rowHeight}
                cellWidth={el.width}
                tableid={this.state.tableIndex}
                RefreshRow={this.RefreshRow}
              />
            ) : null}
            {el.editor === 'MessageEditor' ? (
              <MessageEditor
                hot-editor
                GetRowData={this.GetRowData}
                color={color}
                role={role}
                tableinfo={this.state.tableinfo}
                cellHeight={this.state.rowHeight}
                cellWidth={el.width}
                tableid={this.state.tableIndex}
                RefreshRow={this.RefreshRow}
              />
            ) : null}
            {el.renderandedit === 'SystemList' ? (
              <SystemList
                hot-editor
                hot-renderer
                GetRowData={this.GetRowData}
                color={color}
                selectmode={el.lookup}
                cellHeight={this.state.rowHeight}
                cellWidth={el.width}
                tableid={this.state.tableIndex}
                RefreshRow={this.RefreshRow}
              />
            ) : null}
            {el.renderandedit === 'LookupValues' ? (
              <DisplayLookupValues
                hot-renderer
                specialfunction={el.specialfunction}
                dateFormat={el.dateFormat}
                UTC={el.UTC}
                color={color}
                numericFormat={el.numericFormat}
                cellHeight={this.state.rowHeight}
                cellWidth={el.width}
                uitype={el.uitype===16 ? el.uitype : el.source.lookupuitype}
                fieldType={el.fieldType}
                useButtonDisplay={el.useButtonDisplay}
                buttonLabel={el.buttonLabel}
                callingComponent="grid"
                showForm={this.ShowManageWebFormsModal}
              />
            ) : null}
            {el.editor === 'MultipleEditor' ? (
              <MultipleEditor
                hot-editor
                updatedOn ={el.updatedOn}
                // source={el.source}
                cellHeight={this.state.rowHeight}
                cellWidth={el.width}
                color={color}
                enableColors={el.enableColors}
                tableid={this.state.tableIndex}
                GetRowData={this.GetRowData}
                Cancel={this.cancelEditMode}
              />
            ) : null}
            {el.renderandedit === 'LookupLookupValues' ? (
              <LookupLookupValues
                hot-editor
                hot-renderer
                lookuptable={`${el.source.baselookupfield}_lookup`}
                lookupfield={el.source.finallookupfield}
                specialfunction={el.specialfunction}
                cellHeight={this.state.rowHeight}
                cellWidth={el.width}
              />
            ) : null}
            {el.renderer === 'TextRender' ? (
              <TextRender
                hot-renderer
                field={el.data}
                uitype={el.uitype}
                colInfo={el}
                color={color}
                numericFormat={el.numericFormat}
                minValue={el.minValue}
                maxValue={el.maxValue}
                cellHeight={this.state.rowHeight}
                rule={el.validator}
                cellWidth={el.width}
                maskType={el.maskType}
                maskCharacters={el.maskCharacters}
                uniformLength ={el.uniformLength}
              />
            ) : null}
            {el.renderer ==='RFTEditor' ? (
              <RFTEditor
              hot-editor
              hot-renderer
              GetRowData={this.GetRowData}
              field={el.data}
              uitype={el.uitype}
              colInfo={el}
              cellHeight={this.state.rowHeight}
              rule={el.validator}
              cellWidth={el.width}
              color={color}
            />
            ): null}
            {el.renderer === 'IDRender' ? (
              <IDRender
                hot-renderer
                field={el.data}
                cellHeight={this.state.rowHeight}
                cellWidth={el.width}
                color={color}
              />
            ) : null}
            {el.renderer === 'ButtonRender' ? (
              <ButtonRender
                hot-renderer
                systemid={el.systemid}
                params={el.params}
                field={el.data}
                color={color}
                uitype={el.uitype}
                cellHeight={this.state.rowHeight}
                rule={el.validator}
                cellWidth={el.width}
              />
            ) : null}
            {el.editor === 'TextEditor' ? (
              <TextEditor hot-editor cellHeight={this.state.rowHeight} cellWidth={el.width} />
            ) : null}
            {el.editor === 'PhoneEditor' ? (
              <PhoneEditor hot-editor cellHeight={this.state.rowHeight} cellWidth={el.width} />
            ) : null}
            {el.editor ==='MaskEditor' ? (
              <MaskEditor hot-editor cellHeight={this.state.rowHeight} cellWidth={el.width} canView={tableinfo.security === undefined || tableinfo.security.unmask===undefined || tableinfo.security.unmask < role}  />
            ): null}

            {el.renderer === 'DateTimeRender' ? (
              <DateTimeRender
                hot-renderer
                hot-editor
                dateFormat={el.dateFormat}
                utc={el.UTC}
                color={color}
                cellHeight={this.state.rowHeight}
                cellWidth={el.width}
              />
            ) : null}
          </HotColumn>
        ));
      } else {
        return null;
      }
    }
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
  };

  closeModalTest = () => {
    this.setState({ modalIsOpen: false, showFilters: false });
  };

  closeModal = () => {

    const {viewMode} = this.state;

    this.setState({
      modalIsOpen: false,
      showError: false,
      showExportButton: false,
      modalWidth: 350,
      modalHeight: 400,
      showFilters: false,
      showBookmarks: false,
      showConfetti:false,
      ConfettiMsg:'',
      showNewRecord: false,
      showEditField: false,
      showManageWebForms: false,
      showColumnManager: false,
      showAddUsers: false,
      showShareManager: false,
      sharedRecordIds: null,
      showFormView: false,
      selectedCoords: null,
      searchParams:null
    });

      // 11-2-23 We wnat to ensure that it detects clicks outside grid after closing a record.
      // specifically, when we click to update a form, we disable this so row selection remains. 
      //if they hit cancel, we need to ensure we re-enable it
      if (viewMode === 'grid' && this.hotTableComponent.current !==null) {
        this.hotTableComponent.current.hotInstance.updateSettings({
          outsideClickDeselects: true,
        });
      }

  };

  Paging = (newoffset) => {
    this.setState({ offset: newoffset });
    this.CallGraphQuery(newoffset);
    // this.FetchGraphData(this.state.tableIndex)
  };

  Refresh = async () => {
    this.setState({
      showColumns: false,
      isLoading: true,
      isReload:true
    }, ()=>this.CallGraphQuery(this.state.offset))
    
  };

  RemoveFilter = async (newfilter) => {
    const { userTabSettings, tableIndex, filters } = this.state;
    var tmparray = filters.filter((filter) => filter.field !== newfilter.field);

    // Save last filter query in tabsettings for user.
    userTabSettings.filters = tmparray;
    await updateUserTabSettings(tableIndex, userTabSettings, 'update');

    this.setState({ filters: tmparray, showColumns: false, isLoading: true,startingData:null }, () =>
      this.CallGraphQuery(this.state.offset),
    );

    if (this.hotTableComponent.current !== null) {
      this.hotTableComponent.current.hotInstance.updateSettings({
        height: window.innerHeight - 147,
      });
    }
  };

  RemoveSort = async () => {
    const { userTabSettings, tableIndex } = this.state;

    let sortField = 'id';
    if (this.state.tablename === 'Users') {
      sortField = 'userid';
    }

    delete userTabSettings.sortField;
    delete userTabSettings.sortOrder;
    delete userTabSettings.sortHeader;
    delete userTabSettings.sortMultipleFields;

    await updateUserTabSettings(tableIndex, userTabSettings, 'update');

    this.setState({ sortField, sortOrder: 'asc', sortHeader: sortField }, () => {
      this.CallGraphQuery(this.state.offset);
    });
  };

  loadBookmark = async (bookmarkid, tabsettings) => {
    const { tableIndex, viewMode, role, tableinfo, userTabSettings } = this.state;
    const { userInfo } = this.context;

    // 12-30-2021 add check to bookmark to see if it's got any filter fields which are
    // locked/hidden from the user. If the field is "locked", show error message.
    const hasLockedFields = tableinfo.columns.findIndex((el) => el.locked !== undefined) !== -1;
    let allowBookmark = true;
    if (tabsettings.filters !== undefined && tabsettings.filters.length > 0 && hasLockedFields) {
      tableinfo.columns
        .filter((el) => el.locked !== undefined)
        .map((col) => {
          const idx = tabsettings.filters.findIndex((itm) => itm.field === col.data);
          if (idx !== -1) {
            allowBookmark = false;
          }
        });
    }
    if (!allowBookmark) {
      toast.info(
        <div style={{ margin: '10px' }}>
          🔒 Security conflict: This bookmark includes filters on fields you are not authorized to
          access. For this reason, it can not be used.
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 15000,
        },
      );
      return false;
    }

    /******** END CHECK BOOKMARK SECURITY ********************* */

    // if(viewMode!=='gridx') { //1-3-2022 commented out check, we always want to load latest when loading bookmark.
    this.setState(
      {
        showColumns: false,
        isLoading: true,
        columns: [],
        filters: [],
        refreshColumns: true,
      },
      async () => {
        await this.FetchGraphData(tableIndex, '', bookmarkid);
      },
    );
    // }
    // else {
    //   const tbl = await getUserTable(tableIndex, bookmarkid,role,userInfo.blocks);
    //   this.setState({userTabSettings: tbl.tabsettings,filters: tbl.tabsettings.filters,isLoading:true}, async () => {
    //     await this.CallGraphQuery(this.state.limit, this.state.offset);
    //   })
    // }

    this.closeModal();
  };

  saveBookMarkLocal = async (name) => {
    const { tableinfo, userTabSettings, tableIndex, viewMode, rowHeight } = this.state;
    
    let columns = [];
    tableinfo.columns.forEach((itm) => {
      columns.push({ data: itm.data, width: itm.width, hide: itm.hide });
    });
    userTabSettings.gridView = viewMode;
    userTabSettings.rowHeight = rowHeight === 33 ? 1 : rowHeight === 60 ? 2 : 3;

    const bmid = await saveBookMark(name, tableIndex, userTabSettings, columns);
  };

  updateBookMarkOrder = async (items) => {
    const { userTabSettings, tableIndex } = this.state;
    userTabSettings.bookmarks = items;
    await updateUserTabSettings(tableIndex, userTabSettings);
    this.setState({ userTabSettings });
  };

  // 10-5-22 new Method added to support updating the filters/column settings for an existing bookmark.
  // let's users update existing bookmarks.
  updateBookmarkContentLocal = async (bookmarkid, name) => {
    const { tableinfo, userTabSettings, viewMode, rowHeight } = this.state;

    let columns = [];
    tableinfo.columns.forEach((itm) => {
      columns.push({ data: itm.data, width: itm.width, hide: itm.hide });
    });
    userTabSettings.gridView = viewMode;
    userTabSettings.rowHeight = rowHeight === 33 ? 1 : rowHeight === 60 ? 2 : 3;

    await updateBookmarkContent(bookmarkid, name, userTabSettings, columns);
  };

  ToggleExpandView = async () => {
    let { isExpanded, blocksettings, blockid } = this.state;
    if (blocksettings !== null) {
      blocksettings.blockExpanded = !isExpanded;
    } else {
      blocksettings = { blockExpanded: !isExpanded };
    }
    await updateBlockUserSettings(blockid, blocksettings);

    this.setState(
      {
        isExpanded: !isExpanded,
      },
      () => this.getHeight(),
    );
    // this.getHeight()
  };

  ModalRenderControl = () => {
    const {
      isLoading,
      showError,
      showFilters,
      showNewRecord,
      showFormView,
      showManageWebForms,
      showColumnManager,
      showAddUsers,
      showShareManager,
      sharedRecordIds,
      showBookmarks,
      showConfetti,
      ConfettiMsg,
      showExportButton,
      tableinfo,
      filters,
      exportedData,
      tabname,
      tablename,
      tmpHeader,
      tableIndex,
      columnconfigfield,
      selectedColumnIndex,
      userTabSettings,
      color,
      role,
      isDone,
      blockid,
      formToken,
      isPublicZoneBlock,
      viewMode,
      statement,
      showBookmarkBar,
      showEditField,
      duplicateField,
      searchParams
    } = this.state;

    const { userInfo } = this.context;
    const userid = userInfo.id;

    if (isLoading) {
      return <Spinner isLoading={isLoading} isDone={isDone} color={color} />;
    }
    if (showError) {
      return (
        <div>
          <Label>An error has occurred</Label>
          <Button onClick={this.closeModal}>Close</Button>
        </div>
      );
    }
    if(showConfetti) {
      return <ConfettiMessage message={ConfettiMsg} close={this.closeModal} color={color} />
    }

    if (showFilters) {
      return (
        <FilterData
          callingComponent="grid"
          title="Filter records"
          saveText="Apply search filter"
          icon={mdiFilter}
          role={role}
          tableinfo={tableinfo}
          tableid={tableIndex}
          close={this.closeModalTest}
          FilterData={this.RunFilterQuery}
          filters={filters}
          color={color}
          blockid={blockid} // added 5-20-2020 as UserFilter for BlockUser(uitype==26) requires blockid
        />
      );
    }
    if (showNewRecord) {
      return (
        <NewRecord
          tableinfo={tableinfo}
          blockid={blockid}
          role={role}
          tableid={tableIndex}
          sourcetableid={0}
          close={this.closeModal}
          executeAndClose={this.closeModalandRefresh}
          color={color}
          callingComponent="grid" //other option right now is "datalist" - controls if header bar is colored.
        />
      );
    }
    if (showFormView) {
      return (
        <FormWrapper
          token={formToken}
          searchParams={searchParams}
          close={this.closeModal}
          executeAndClose={this.closeModalandRefresh}
          color={color}
          addedby={userid}
          role={role}
        />
      );
    }
    if (showManageWebForms) {
      return (
        <FormContainer
          tableinfo={tableinfo}
          tableid={tableIndex}
          blockid={blockid}
          close={this.closeModal}
          color={color}
          tablename={tablename}
        />
      );
    }
    if (showExportButton) {
      return (
        <ExportModal
          exportdata={exportedData}
          close={this.closeModal}
          color={color}
          name={tablename}
          blockname={tabname}
        />
      );
    }
    if (showBookmarks) {
      return (
        <Bookmarks
          role={role}
          spreadSheetPriv ={tableinfo.security?.viewSpreadSheet}
          updateBookMarkOrder={this.updateBookMarkOrder}
          loadBookmark={this.loadBookmark}
          userTabSettings={userTabSettings}
          tableid={tableIndex}
          color={color}
          close={this.closeModal}
          closeAndRefresh={this.closeBookMarksAndRefresh}
          saveBookMark={this.saveBookMarkLocal}
          updateBookmarkContent={this.updateBookmarkContentLocal}
          showBookmarkBar={showBookmarkBar}
          isPublicZoneBlock={isPublicZoneBlock}
          toggleShowBookmarkBar={this.toggleShowBookmarkBar}
        />
      );
    }
    if (showShareManager) {
      return (
        <ShareManager
          role={role}
          tablename={tablename}
          tableinfo={tableinfo}
          tableid={tableIndex}
          color={color}
          close={this.closeModal}
          sharedRecordIds={sharedRecordIds}
          statement={statement}
        />
      );
    }
    if (showColumnManager) {
      return (
        <ColumnManager
          tablename={tablename}
          role={role}
          blockid={blockid}
          tableinfo={tableinfo}
          tableid={tableIndex}
          color={color}
          close={this.closeModal}
          userTabSettings={userTabSettings}
          closeModalandRefreshTable={this.closeModalandRefreshTable}
          userInfo={userInfo}
        />
      );
    }
    if (showAddUsers) {
      return (
        <AddUsersManager
          table={tableinfo.dbname}
          tableid={tableIndex}
          blockid={blockid}
          color={color}
          close={this.closeModal}
          closeModalandUpdate={this.closeModalandUpdate}
        />
      );
    }
    if (showEditField) {
      return (
        <ColumnConfig
          Rename={this.RenameColumn}
          blockid={blockid}
          Refresh={this.RefreshTable}
          updateHeight={this.updateModalHeight}
          header={tmpHeader}
          tableid={tableIndex}
          field={columnconfigfield}
          duplicateField={duplicateField}
          close={this.closeModal}
          colIndex={selectedColumnIndex}
          color={color}
          ShowError={this.ShowError}
          role={role}
        />
      );
    }
  };

  createMarkup = () => {
    return { __html: this.state.information };
  };

  render() {
    const {
      showExportButton,
      recordCount,
      tablename,
      tableicon,
      filters,
      color,
      tabname,
      tableinfo,
      icon,
      blockid,
      issystemblock,
      statement,
      isExpanded,
      lastBlockEdited,
      sortField,
      sortOrder,
      sortHeader,
      showColumns,
      modalIsOpen,
      modalWidth,
      tableIndex,
      showDetailView,
      showUserProfile,
      showNotifications,
      showDuplicateBlock,
      showManageWebForms,
      showColumnManager,
      block,
      showBlockEditor,
      ShowError,
      selectedRowIndex,
      showFilters,
      role,
      viewMode,
      hasMessages,
      userTabSettings,
      showEditField,
      showAddUsers,
      forms,
      screenLoading,
      hiddenColumnCount,
      useSecurity,
      isLoading,
      rowHeight,
      isPublicZoneBlock,
      mathNumbers,
      showBookmarkBar,
      bookmarks,
      selected_id,
      nextPrevBool,
      currentBookMarkId,
      showConfetti,
      isSimpleView
    } = this.state;

    const { userInfo } = this.context;
    const userid = userInfo.id;

    return (
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        {!screenLoading ? (
          <>
            <Helmet>
              <title>{tabname}</title>
              <meta name="ABC" content="ABC" />
              {/*2-16-23 removed Link element here as favicon stopped working on this date. removing this solved issue.
              I also changed zonedashboard favicon in <helmet> to point to Graceblocks favicon, which solved it keeping
              what was changed here.
              */}
            </Helmet>
            <div>
              <TopNavBar
                statement={statement}
                role={role}
                name={tabname}
                icon={icon}
                color={color}
                RELOAD={this.ReloadTable}
                blockid={blockid === 0 ? parseInt(this.props.blockID) : parseInt(blockid)}
                showBlockEditorHandler={this.showBlockEditorHandler}
                toggleUserProfile={this.toggleUserProfile}
                showNotifications={showNotifications}
                toggleNotifications={this.toggleNotifications}
                toggleDuplicateBlock={this.toggleDuplicateBlock}
                ShowConfettiModal={this.ShowConfettiModal}
                isMaxRowsExceeded={block && block.isMaxRowsExceeded}
                isSystemBlock = {block && block.issystemblock}
                isPublicZoneBlock={isPublicZoneBlock}
                userid={userid}
              />
            </div>

            {showBlockEditor ? (
              <BlockEditor
                show={showBlockEditor}
                ShowError={this.ShowError}
                block={this.state.block}
                onCancel={this.showBlockEditorHandler}
                updateBlockInfo={this.updateBlockInfo}
              />
            ) : null}

            <div
              id="hot-app"
              style={{ display: 'flex', height: '100%', flexDirection: 'row', marginTop: '0px' }}
            >
              <div>
                <TableControls
                  RELOAD={this.ReloadTable}
                  NextPage={this.Paging}
                  blockid={blockid === 0 ? parseInt(this.props.blockID) : parseInt(blockid)}
                  isExpanded={isExpanded}
                  toggleExpand={this.ToggleExpandView}
                  color={this.state.color}
                  role={role}
                  ShowError={this.ShowError}
                  lastBlockEdited={lastBlockEdited} // updated 3-2-201. If use edits block info, we want tabs list to refresh and show new home page content.
                  issystemblock={issystemblock} // added 9-25, For "system blocks" like User Admin, we remove the "add tab" option.
                />
              </div>

              <div style={{ width: '100%', height: '100vh' }}>
                {!this.state.showHomePage ? (
                  <>
                    {showBookmarkBar ? (
                      <BookmarkBar
                        bookmarks={bookmarks}
                        color={color}
                        hide={this.toggleShowBookmarkBar}
                        loadBookmark={this.loadBookmark}
                        currentBookMarkId={currentBookMarkId}
                      />
                    ) : null}

                    <div style={{ height: '50px' }}>
                      <ActionBar
                        recordCount={recordCount}
                        name={tablename}
                        isSystemTable={tableinfo.isSystemTable}
                        icon={tableicon}
                        updateRowHeight={this.UpdateRowHeight}
                        defaultRowHeight={
                          userTabSettings.rowHeight !== undefined
                            ? userTabSettings.rowHeight
                            : viewMode === 'grid'
                            ? 1
                            : 2
                        }
                        showFilter={this.ShowFilterModal}
                        showError={this.ShowError}
                        filters={filters}
                        exportData={this.ExportTableData}
                        showExport={showExportButton}
                        Refresh={this.Refresh}
                        color={color}
                        viewMode={viewMode}
                        updateViewMode={this.updateViewMode}
                        hasMessages={hasMessages}
                        toggleBookMarks={this.toggleBookMarks}
                        toggleColumnManager={this.toggleColumnManager}
                        toggleShareManager={this.toggleShareManager}
                        toggleAddUsers={this.toggleAddUsers}
                        hiddenColumnCount={hiddenColumnCount}
                        lastCache ={tableinfo.lastCache}
                        cacheResults ={tableinfo.cacheResults}
                        role={role}
                        showDownloadButton={
                          (tableinfo.security === undefined ||
                            tableinfo.security.downloadRecords <= role) &&
                          !isPublicZoneBlock
                        }
                        showShareButton={
                          (tableinfo.security === undefined ||
                            tableinfo.security.shareRecords <= role) &&
                          !isPublicZoneBlock
                        }
                        showPrintButton={
                          (tableinfo.security === undefined ||
                            tableinfo.security.printRecords <= role) &&
                          !isPublicZoneBlock
                        }
                        showSpreadSheet={
                          (tableinfo.security === undefined ||
                            tableinfo.security.viewSpreadSheet === undefined ||
                            tableinfo.security.viewSpreadSheet <= role) &&
                          !isPublicZoneBlock
                        }
                        
                      />
                    </div>
                    <div
                      style={{
                        height:'50px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                      }}
                    >
                      <FilterTags
                        sortHeader={sortHeader}
                        sortField={sortField}
                        sortOrder={sortOrder}
                        filters={filters}
                        removeFilter={this.RemoveFilter}
                        removeSort={this.RemoveSort}
                        sortMultipleFields={userTabSettings.sortMultipleFields}
                        callingComponent="grid"
                      />
                      {/* Add new Record , search and Oldest first / Newest First */}
                      <div
                        style={{width:'500px', 
                        display:'flex', 
                        flexDirection:'row', 
                        alignItems:'center',
                         marginBottom: '5px', 
                         marginTop: '10px',
                         marginRight:'50px', 
                        //  border:'1px solid red',
                         justifyContent:'flex-end'}}
                      >
                        {(!tableinfo.isSystemTable || tableinfo.isSystemTable === undefined) &&
                        (tableinfo.security === undefined ||
                          (tableinfo.security !== undefined &&
                            role >= tableinfo.security.addRecord)) &&
                        !isPublicZoneBlock ? (
                          <div style={{ marginBottom: '1px', cursor: 'pointer',marginRight:'10px' }}>
                            <GBAddNewRecordButton
                              ActionTwo={this.ShowManageWebFormsModal}
                              color={color}
                              options={forms.filter((el) => !el.isdefault)}
                              Action={this.ShowNewRecordModal}
                              ActionValue="addition"
                              isMaxRowsExceeded={this.state.block?.isMaxRowsExceeded}
                              role={role}
                            />
                          </div>
                        ) : null}
                        <div style={{ width: '200px' }}>
                      <GBInput
                        actions={this.searchActionOptions}
                        currentValue={''}
                        executeAction={this.searchActions}
                        clearInput={this.Refresh}
                        recordSet={`${tableIndex}-${filters.length}`}
                      />

                        </div>
                
                        {/* <RecordIdentifierSearch FilterData={this.RunFilterQuery} color={color} /> */}
                      
                      

                        <div style={{ width: '25px' }}></div>
                        {/* <div style={{width:'150px',backgroundColor:'blue',color:'white'}}>
                          Testing of div
                          </div> */}
                        <TimeSortButton
                          updateSortOrder={this.updateSortOrder}
                          color={color}
                          sortField={sortField}
                          sortOrder={sortOrder}
                        />
                       
                      </div>
                    </div>
                  </>
                ) : null}

                {!this.state.showHomePage ? (
                  <div>
                    {showColumns && viewMode === 'grid' ? (
                      <div>
                        <HotTable
                          style={{ zIndex: '0' }}
                          ref={this.hotTableComponent}
                          id={this.id}
                          settings={this.state.settings}
                        >
                          {this.state.columns != null ? this.createColumns() : null}
                        </HotTable>

                        {/* </div> */}
                        <div>
                          <BottomBar
                            canAddRow={tableinfo.security===undefined || tableinfo.security.addRecord <= role}
                            color={color}
                            currentPage="1"
                            isLoading={isLoading}
                            isSimpleView={isSimpleView}
                            recordCount={this.state.recordCount}
                            viewingCount={this.state.viewingCount}
                            filterCount={this.state.filterCount}
                            showSecurityMessage={useSecurity && viewMode !== 'quick'}
                            mathNumbers={mathNumbers}
                            insertNewTableRow={
                              tableinfo.tablename !== 'BlockUsers' &&
                              tableinfo.tablename !== 'Users'
                                ? this.insertNewTableRow
                                : null
                            }
                          />{' '}
                        </div>
                      </div>
                    ) : null}
                    {viewMode === 'chat' &&
                    this.state.settings.data &&
                    this.state.settings.data.length > 0 &&
                    !isLoading ? (
                      <div style={{ position: 'relative', zIndex: 0 }}>
                        <MessagesView
                          data={this.state.settings.data}
                          tableinfo={this.state.tableinfo}
                          color={color}
                          tableid={tableIndex}
                          role={role}
                          useSecurity={useSecurity}
                          blockid={blockid}
                          showBookmarkBar={showBookmarkBar}
                          loadData={this.CallGraphQuery}
                          recordCount={this.state.filterCount}
                          isExpanded={isExpanded}
                        />

                        <div>
                          <BottomBar
                            canAddRow={tableinfo.security===undefined || tableinfo.security.addRecord <= role}
                            currentPage="1"
                            isLoading={isLoading}
                            recordCount={this.state.recordCount}
                            isSimpleView={isSimpleView}
                            viewingCount={this.state.viewingCount}
                            filterCount={this.state.filterCount}
                            showSecurityMessage={useSecurity && viewMode !== 'quick'}
                          />{' '}
                        </div>
                      </div>
                    ) : null}

                    {viewMode === 'quick' &&
                    this.state.settings.data &&
                    showColumns &&
                    !isLoading ? (
                      <div style={{ position: 'relative', zIndex: 0 }}>
                        {userTabSettings.groups !== undefined ? (
                          <GroupView
                            color={block.color}
                            rowHeight={rowHeight}
                            tableid={tableIndex}
                            tableinfo={tableinfo}
                            data={this.state.settings.data}
                            showDetailView={this.showDetailView}
                            activeRow={selected_id}
                            setSortField={this.setSortField}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            UpdateTableColumnWidth={this.UpdateTableColumnWidth}
                            showBookmarkBar={showBookmarkBar} //added so we can properly calculate height of grid.
                            isExpanded={isExpanded}
                            loadData={this.CallGraphQuery}
                            recordCount={this.state.filterCount}
                            groups={userTabSettings.groups}
                            calcFields={userTabSettings.calcFields ?? []}
                          />
                        ) : (
                          <QuickViewVirtual
                            color={block.color}
                            rowHeight={rowHeight}
                            tableid={tableIndex}
                            tableinfo={tableinfo}
                            data={this.state.settings.data}
                            showDetailView={this.showDetailView}
                            activeRow={selected_id}
                            setSortField={this.setSortField}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            UpdateTableColumnWidth={this.UpdateTableColumnWidth}
                            showBookmarkBar={showBookmarkBar} //added so we can properly calculate height of grid.
                            isExpanded={isExpanded}
                            loadData={this.CallGraphQuery}
                            recordCount={this.state.filterCount}
                            showForm={this.ShowManageWebFormsModal}
                          />
                        )}
                        <div>
                          <BottomBar
                            canAddRow={tableinfo.security===undefined || tableinfo.security.addRecord <= role}
                            currentPage="1"
                            isLoading={isLoading}
                            recordCount={this.state.recordCount}
                            isSimpleView={isSimpleView}
                            viewingCount={this.state.viewingCount}
                            filterCount={this.state.filterCount}
                            showSecurityMessage={useSecurity && viewMode !== 'quick'}
                          />{' '}
                        </div>
                      </div>
                    ) : null}

                    {viewMode === 'chat' && !this.state.settings.data ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          fontSize: '20px',
                        }}
                      >
                        No records match the filter criteria applied
                      </div>
                    ) : null}

                    {(!showColumns && (!modalIsOpen || showFilters)) || isLoading ? (
                      <Spinner isLoading={true} isDone={false} color={color} />
                    ) : null}
                  </div>
                ) : (
                  <div
                    style={{
                      width: '95%',
                      marginTop: '20px',
                      marginLeft: '20px',
                      overflow: 'auto',
                      height: '90%',
                    }}
                    dangerouslySetInnerHTML={this.createMarkup()}
                  />
                )}
              </div>
              {showDetailView ? (
                <DetailView
                  role={role}
                  tableid={tableIndex}
                  blockid={blockid}
                  color={color}
                  NextPrev={this.NextPrevHandler}
                  hide={this.hideDetailView}
                  id={this.state.selectedItem.id}
                  userTabSettings={this.state.userTabSettings}
                  updateSettingsFromDetailView={this.updateSettingsFromDetailView}
                  selectedRow={this.state.selectedItem}
                  tableinfo={this.state.tableinfo}
                  updateTableInfo={this.updateTableInfo}
                  RefreshRow={this.RefreshRow}
                  refreshRowData={this.refreshRowData}
                  SelectedRowIndex={selectedRowIndex}
                  hasMessages={hasMessages}
                  filterCount={this.state.filterCount}
                  activeRow={this.state.activeRow}
                  nextPrevBool={nextPrevBool}
                />
              ) : null}
              {/* {showFormManager ? (
            <FormContainer />
          ): null} */}
              {showUserProfile ? (
                <UserProfile color={color} toggleUserProfile={this.toggleUserProfile} />
              ) : null}
              {showNotifications ? (
                <ShowNotifications
                  color={color}
                  blockid={blockid}
                  toggleNotifications={this.toggleNotifications}
                />
              ) : null}
              {showDuplicateBlock ? (
                <DuplicateBlock
                  color={color}
                  callingComponent="tab"
                  field="field_1"
                  item={block}
                  close={this.toggleDuplicateBlock}
                  onCopy={copyBlock}
                />
              ) : null}
            </div>

            <div style={{ position: 'fixed', zIndex: 4000 }}>
              <Modal
                shouldCloseOnOverlayClick={
                  showEditField || showColumnManager || showAddUsers ? false : true
                }
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                // onRequestClose={(event) => {
                //   if (event.type === 'keydown' && event.keyCode === 27) return
                //   this.closeModal()
                // }}
                style={{
                  overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                  },
                  content: showConfetti ? {
                    top: 0,
                    left: 0,
                    right:0,
                    bottom:0,
                    // backgroundColor:'#fff'
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                  } :
                  {
                    width: modalWidth,
                    height:
                      showManageWebForms === true || showExportButton ? this.state.modalHeight : 'calc(100vh -100px)',
                    top: this.state.y,
                    left: this.state.x,
                    border: `.3px solid ${color}`,
                    background: '#fff',
                    overflow: 'auto',
                    zIndex: 5010,
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '10px',
                    outline: 'none',
                    padding: '0px',
                  },
                }}
                contentLabel="Example Modal"
              >
                {this.ModalRenderControl()}
              </Modal>
            </div>
          </>
        ) : (
          <Spinner color={color} />
        )}
        {isLoading ? (
          <Spinner color={color} />
        ): null}
      </div>
    );
  }
}

export default DataGrid;
