/* eslint-disable */
import React, { useEffect, useState,useRef } from 'react';
import { Dropdown, Input } from 'semantic-ui-react';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp, mdiChevronLeft,mdiChevronRight } from '@mdi/js';
import GBButton from 'src/components/GBButton/GBButton';
import TextButton from 'src/components/TextButton/TextButton';
import IconButton from '../FilterData/IconButton';


const SectionFields = ({section, sections, fields, color, close,updateFields}) =>{

    const [activeFields, setActiveFields] = useState([]);
    const [otherFields,setOtherFields]= useState([]);
    const [inputValue, setInputValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(otherFields);
    const [lastSelectedIndex2, setLastSelectedIndex2] = useState(-1);

    const selectRef1 = useRef(null);
    const selectRef2 = useRef(null);

    useEffect(()=>{
        getActiveFields()
    },[])

    // Update filteredOptions whenever activeFields or inputValue changes
    useEffect(() => {
      const filtered = otherFields.filter(option =>
        option.text.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredOptions(filtered);
    }, [otherFields, inputValue]);


    const getActiveFields = () => {
        fields.sort((a, b) => (a.detailviewsort > b.detailviewsort) ? 1 : -1)
        const aFields=[];
        section.fields?.map(fld=>{
          const idx = fields.findIndex(f=>f.data===fld)
          if(idx !==-1) {
            aFields.push({key:fields[idx].data,value:fields[idx].data,text:fields[idx].header})
          }
        })
      
        setActiveFields(aFields);

        const oFields = [];
        const otherSections =sections.filter(sec=>sec.name !==section.name);
        fields.filter(fld=> !section.fields.includes(fld.data)).map(fld =>{
          const sectionIdx = otherSections.findIndex(sec=>sec.fields.includes(fld.data));
          if(sectionIdx !==-1) {
            oFields.push({key:fld.data,value:fld.data,text:`${fld.header} (${otherSections[sectionIdx].name})`})
          } else {
            oFields.push({key:fld.data,value:fld.data,text:`${fld.header} (unmapped)`})
          }
        })
      
        setOtherFields(oFields);
    };

    const handleMoveUp = () => {
      if (selectRef2.current) {
        setLastSelectedIndex2(selectRef2.current.selectedIndex-1);
        const selectedOptions = Array.from(selectRef2.current.selectedOptions).map(
          (option) => option.value
        );
  
        setActiveFields((prevActiveFields) => {
          let updatedFields = [...prevActiveFields]; // Create a copy
  
          // Move each selected item up individually
          for (let i = 0; i < selectedOptions.length; i++) {
            const selectedValue = selectedOptions[i];
            const selectedIndex = updatedFields.findIndex(
              (field) => field.value === selectedValue
            );
  
            if (selectedIndex > 0) {
              // Swap with the item above
              [updatedFields[selectedIndex], updatedFields[selectedIndex - 1]] = [
                updatedFields[selectedIndex - 1],
                updatedFields[selectedIndex],
              ];
            }
          }
  
          return updatedFields; // Update activeFields state
        });
      }
    };

    const handleMoveDown = () => {
      if (selectRef2.current) {
        setLastSelectedIndex2(selectRef2.current.selectedIndex+1);
        const selectedOptions = Array.from(selectRef2.current.selectedOptions).map(
          (option) => option.value
        );

        setActiveFields((prevActiveFields) => {
          let updatedFields = [...prevActiveFields]; // Create a copy
  
          // Move each selected item down individually (in reverse order)
          for (let i = selectedOptions.length - 1; i >= 0; i--) {
            const selectedValue = selectedOptions[i];
            const selectedIndex = updatedFields.findIndex(
              (field) => field.value === selectedValue
            );
  
            if (selectedIndex < updatedFields.length - 1) {
              // Swap with the item below
              [updatedFields[selectedIndex], updatedFields[selectedIndex + 1]] = [
                updatedFields[selectedIndex + 1],
                updatedFields[selectedIndex],
              ];
            }
          }
  
          return updatedFields; // Update activeFields state
        });
      }
    };

    const handleSelectionChange2 = () => {
      if (selectRef2.current) {
          setLastSelectedIndex2(selectRef2.current.selectedIndex);
      }
  };

  const handleMoveRight = () => {
    if (selectRef1.current) {
        const selectedOptions = Array.from(selectRef1.current.selectedOptions).map(
            (option) => option.value
        );

        setActiveFields((prevActiveFields) => {
            const newActiveFields = [...prevActiveFields];
            let insertIndex = prevActiveFields.length; // Default: append

            // Use lastSelectedIndex2, *not* selectedIndex
            if (selectRef2.current && lastSelectedIndex2 !== -1) {
                //Find object from value at index.
                const selectedValue2 = selectRef2.current.options[lastSelectedIndex2]?.value;
                insertIndex = newActiveFields.findIndex(field => field.value === selectedValue2);
                 if(insertIndex != -1){
                  insertIndex = insertIndex + 1;
                } else {
                  insertIndex = newActiveFields.length;
                }
            }

            selectedOptions.forEach((selectedValue) => {
                const fieldToAdd = otherFields.find((field) => field.value === selectedValue);
                if (fieldToAdd) {
                    newActiveFields.splice(insertIndex, 0, fieldToAdd);
                    insertIndex++;
                }
            });
            return newActiveFields;
        });

        setOtherFields((prevOtherFields) =>
            prevOtherFields.filter((field) => !selectedOptions.includes(field.value))
        );
    }
};

    // const handleMoveRight = () => {
    //   if (selectRef1.current) { // Corrected: selectRef1 is used here
    //     const selectedOptions = Array.from(selectRef1.current.selectedOptions).map( // Corrected: selectRef1 is used here
    //       (option) => option.value
    //     );
  
    //     // 1. Add selected fields to activeFields
    //     setActiveFields((prevActiveFields) => {
    //       const newActiveFields = [...prevActiveFields];
    //       selectedOptions.forEach((selectedValue) => {
    //         const fieldToAdd = otherFields.find((field) => field.value === selectedValue);
    //         if (fieldToAdd) {
    //           newActiveFields.push(fieldToAdd);
    //         }
    //       });
    //       return newActiveFields;
    //     });
  
    //     // 2. Remove selected fields from otherFields
    //     setOtherFields((prevOtherFields) =>
    //       prevOtherFields.filter((field) => !selectedOptions.includes(field.value))
    //     );
    //   }
    // };


    const handleMoveLeft = () => {
      if (selectRef2.current) {
        const selectedOptions = Array.from(selectRef2.current.selectedOptions).map(
          (option) => option.value
        );

        // 1. Add selected fields to otherFields
        setOtherFields((prevOtherFields) => {
          const newOtherFields = [...prevOtherFields];
          selectedOptions.forEach((selectedValue) => {
            const fieldToAdd = activeFields.find((field) => field.value === selectedValue);
            if (fieldToAdd) {
              newOtherFields.push(fieldToAdd);
            }
          });
          return newOtherFields;
        });
  
        // 2. Remove selected fields from activeFields
        setActiveFields((prevActiveFields) =>
          prevActiveFields.filter((field) => !selectedOptions.includes(field.value))
        );
      }
    };
  

    return (
        <div
          style={{
            position:'absolute',
            top:80,
            zIndex:101,
            width:'90%',
            marginLeft: '50px',
            backgroundColor: 'white',
          }}
        >
          <div
            style={{
              backgroundColor: color,
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              //   border:'px solid blue',
              color: 'white',
              height: '50px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '10px',
            }}
          >
            <div style={{ fontSize: '20px' }}>Organize section: {section.name}</div>
          </div>
          <div
            style={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-between',
              height: '450px',
              border: `1px solid ${color}`
            }}
          >
        <div style={{ margin:'20px', display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:'center' }}>
            <div style={{height:'350px',display:'flex',flexDirection:'column'}}>
              <div>Avilable fields</div>
              <Input placeholder="filter fields" value={inputValue} onChange={(e,data)=>setInputValue(data.value)}  />
               {/* <Dropdown selection open options={otherFields} /> */}
               <select ref={selectRef1} id="mySelect" multiple style={{height:'350px',width:'250px',overflow:'auto',backgroundColor:'#FBF7F7'}}>
              {filteredOptions.map((option) => (
                <option style={{padding:'5px'}} key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
            </div>
            <div style={{display:'flex',paddingTop:'50px', height:'200px', alignSelf:'center',flexDirection:'column',alignItems:'center'}}>
              <div><IconButton color="black" hoverColor={"#00000080"} icon={mdiChevronRight} size="50px" Action={handleMoveRight} /> </div>
              <div style={{height:'50px'}} />
              <div><IconButton color="black" hoverColor={"#00000080"} icon={mdiChevronLeft} size='50px' Action={handleMoveLeft} /> </div>
            </div>
            <div style={{height:'350px',display:'flex',flexDirection:'column'}}>
            {/* <Dropdown  selection open options={activeFields} /> */}
            <div style={{marginTop:'32px'}}>Selected fields</div>
            <select  onChange={handleSelectionChange2} ref={selectRef2} id="mySelect" multiple style={{height:'350px',width:'250px',overflow:'auto', backgroundColor:'#FBF7F7'}}>
              {activeFields.map((option) => (
                <option style={{padding:'5px'}} key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
            </div>
            <div style={{display:'flex',paddingTop:'50px', height:'200px', alignSelf:'center',flexDirection:'column',alignItems:'center'}}>
              <div><IconButton color="black" hoverColor={"#00000080"} icon={mdiChevronUp} size="50px" Action={handleMoveUp} /> </div>
              <div style={{height:'30px'}} />
              <div><IconButton color="black" hoverColor={"#00000080"} icon={mdiChevronDown} size='50px' Action={handleMoveDown} /> </div>
            </div>

          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-end',marginRight:'20px',marginBottom:'10px'}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <TextButton textColor="black" text="Cancel" Action={close} />
            <div style={{width:'20px'}}></div>
            <GBButton width='75px' text='Save' color={color} Action={()=>updateFields(section,activeFields.map(fld=>fld.value))} />
            </div>
          </div>
          
          </div>
        </div>
    )
}

export default SectionFields