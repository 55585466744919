/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';

import Icon from '@mdi/react';
import {
  mdiNumeric1Circle,
  mdiChevronDown,
  mdiChevronUp,
  mdiNumeric2Circle,
  mdiPencilOutline,
  mdiClose,
} from '@mdi/js';
import onClickOutside from 'react-onclickoutside';

class Divider extends Component {
  state = {
    edit: this.props.isEdit ===undefined  ? false : this.props.isEdit,
  };

  handleChange = () => {
    this.setState({ edit: !this.state.edit });
  };

  handleDelete = async () => {
    const { deleteSection, sectionNumber } = this.props;
    await deleteSection(sectionNumber);
    this.setState({ edit: !this.state.edit });
  };

  // handleClickOutside = (evt) => {
  //   const { sectionNumber } = this.props;
  //   if (this.state.edit) {
  //     this.props.submitSectionName(sectionNumber);
  //     this.setState({ edit: false });
  //   }
  // };

  //9-27-24 I saw over 1000+ errors of this not being a function. Following code will only
  //try it if it;'s not function.

  handleClickOutside = (evt) => {
    const { sectionNumber } = this.props;
    if (this.state.edit) {
      // Check if submitSectionName is a function before calling it
      if (typeof this.props.submitSectionName === 'function') { 
        this.props.submitSectionName(sectionNumber);
      } else {
        console.warn('submitSectionName is not a function. Skipping...'); 
        // Or handle the absence of the function in a more appropriate way for your app
      }
      this.setState({ edit: false });
    }
  }

  onKeyHandler =(e) =>{
    if(e.key==='Enter') {
      this.handleClickOutside();
    }
  }

  handleFocus = (event) => event.target.select();

  render() {
    const {
      setSectionExpanded,
      isSectionExpanded,
      color,
      fieldCount,
      sectionNumber,
      sectionName,
      role,
      updateSectionName,
      submitSectionName,
      deleteSection,
      editMode
    } = this.props;
    const { edit } = this.state;
    return (
      <div
        style={{
          backgroundColor: '#F1EFEF',
          margin: '20px',
          cursor:'default',
          borderRadius: '20px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          whiteSpace: 'noWrap',
          justifyContent: 'space-between',
        }}
      >
        <div
          onClick={() => !edit ? setSectionExpanded(!isSectionExpanded, sectionName) :null}
          css={{
            display: 'flex',
            // border: '1px solid red',
            flexDirection: 'row',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
              transition: 'all .3s ease',
              color: `${color}80`,
              '& .dividerSectionNumber': {
                transition: 'all .3s ease',
                backgroundColor: `${color}80`,
              },
              svg: {
                color: 'white',
                backgroundColor: `${color}80`,
                borderRadius: '30px',
                transition: 'all .3s ease',
              },
            },
          }}
        >
          <div
            css={{
              marginLeft: '20px',
              marginRight: '5px',
             
            }}
          >
            <Icon path={isSectionExpanded ? mdiChevronUp : mdiChevronDown} size="20px" />
          </div>
          <div
            className="dividerSectionNumber"
            css={{
              borderRadius: '50px',
              fontWeight: 'bold',
              fontSize: '12px',
              marginRight: '5px',
              paddingLeft: '5px',
              paddingRight: '5px',
              color: 'white',
              backgroundColor: 'black',
            }}
          >
            {sectionNumber}
          </div>
          {!edit ? (
            sectionName
          ) : (
            <input
              autoFocus
              onFocus={this.handleFocus}
              defaultValue={sectionName}
              onChange={(e) => updateSectionName(sectionNumber, e.target.value)}
              onKeyPress={this.onKeyHandler}
            />
          )}

         {/* 2-4-25 moved to fields array, too hard to keep track of accurate count */}
          {/* <div style={{ marginLeft: '5px' }}>({fieldCount})</div> */}
        </div>
        {role === 3 && editMode ? (
          <div
            onClick={edit ? () => this.handleDelete() : () => this.handleChange()}
            css={{
              marginRight: '25px',
              color: !edit ? '#757575' : '#F44336',
              display: 'flex',
              border: edit ? '1px solid #757575' : null,
              borderRadius: '10px',
              paddingTop: '5px',
              paddingBottom: '5px',
              paddingLeft: '10px',
              paddingRight: '10px',
              alignItems: 'center',
              flexDirection: 'row',
              '&:hover': {
                color: !edit ? color : `#F4433680`,
                borderRadius: '10px',
                marginRight: '24px',
                border: `1px solid #757575`,
                boxShadow: '0px 3px 6px #757575',
              },
            }}
          >
            <Icon path={edit ? mdiClose : mdiPencilOutline} size="15px" />
            <div style={{ marginLeft: '5px' }}>{!edit ? 'Rename' : 'Delete section'}</div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default onClickOutside(Divider);
